import React, { useState, useEffect } from 'react';
import Title from '../../components/title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';
import './Egresos.css';
import { Spinner } from 'react-bootstrap';
import { formatNumber, formatDateMin } from '../../misc/index';
import Card from '../../components/Card';

const DischargeCard = ({ document }) => {
    return(
        <Card>
            <div className='card-fields-container egresos-cards-container'>
                <div className='card-field-container'>
                    <div className='card-field-title'>DOCUMENTO:</div>
                    <div className='card-field-data'>{ document.tipoDoc + '-' + document.numDoc }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>FECHA DEL DOCUMENTO:</div>
                    <div>{ formatDateMin(new Date(document.fechaDoc)) }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>VALOR DEL DOCUMENTO</div>
                    <div>${ formatNumber(document.valor) }</div>
                </div>
            </div>
        </Card>
    );
}

const EgresosView = ({ egresos }) => {
    
    return(
        <div className='page-container'>
            <Title>Comprobantes de egreso</Title>
            {egresos.loading ?
                <div className='loading-spinner'>
                    <Spinner animation="border" variant="primary" />
                </div>
            :
                <div className='page-body'>
                    <div className='desktop-view'>
                        <table className='info-table'>
                            <thead>
                                <tr>
                                    <th>DOCUMENTO</th>
                                    <th>FECHA DEL DOCUMENTO</th>
                                    <th>VALOR DEL DOCUMENTO</th>
                                    <th>VER</th>
                                    {/*<th>DESCARGAR</th>*/}
                                </tr>
                            </thead>
                            <tbody>
                                { 
                                    egresos.data.map(
                                        egreso => {
                                            return(
                                                <tr key={egreso.id}>
                                                    <td>{ egreso.tipoDoc + '-' + egreso.numDoc }</td>
                                                    <td>{ formatDateMin(new Date(egreso.fechaDoc)) }</td>
                                                    <td>${ formatNumber(egreso.valor) }</td>
                                                    <td><div className='view-icon-container'><Link to={{ pathname: "/portal/egreso-detalle", state: { egreso: egreso } }}><FontAwesomeIcon className='view-icon' icon={faEye} /></Link></div></td>
                                                    {/*<td><div className='pdf-icon-container' ><Link style={{ color: 'red' }} onClick={(e) => { generarEgresoDetallePdf(e, egreso) }}><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></Link></div></td>*/}
                                                </tr>
                                            );
                                        }
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='mobile-view'>
                        <div className='cards-container'>
                            {
                            egresos.data.map(
                                (discharge, index) => {
                                    return(
                                        <>
                                            <Link key={index} to={{ pathname: "/portal/egreso-detalle", state: { egreso: discharge } }} ><DischargeCard key={index} document={discharge} /></Link>
                                        </>
                                    );
                                }
                            )
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default EgresosView;
