import React, { useState, useEffect } from 'react';
import Title from '../../components/title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import SubTitle from '../../components/subTitle/SubTitle';
import './DocumentoComercialDetalle.css';
import { Spinner } from 'react-bootstrap';
import { formatNumber, formatDateMin } from '../../misc/index';
import { Link } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';
import DocumentoFIDetallePDFView from '../DocumentoFIDetallePDF/DocumentoFIDetallePDFView';
import DocumentoCMDetallePDFView from '../DocumentoCMDetallePDF/DocumentoCMDetallePDFView';

const DocumentoComercialDetalleView = ({ AppReducer, documento }) => {

    return(
        <div className='page-container'>
            <Title>Documento de proveedor - Detalle</Title>
            <div className='page-body'>
                { documento.loading ?
                    <div className='loading-spinner'>
                        <Spinner animation="border" variant="primary" />
                    </div>
                :
                <>
                    <div className='desktop-view'>
                        <SubTitle>Empresa: { AppReducer.company.name }</SubTitle>
                        <SubTitle>Proveedor:</SubTitle>
                        <div>
                            <div><span className='info-title'>N.I.T: </span><span>{ AppReducer.supplier.nit }</span></div>
                            <div><span className='info-title'>Razón social: </span><span>{ AppReducer.supplier.razonSocial }</span></div>
                        </div>
                        <br />
                        <br />
                        <SubTitle>Detalle del documento</SubTitle>
                        <table className='info-table doc-table'>
                            <thead>
                                <tr>
                                    <th>DOCUMENTO</th>
                                    <th>FECHA DEL DOCUMENTO</th>
                                    <th>DETALLE</th>
                                    <th>PROVEEDOR</th>            
                                    <th>DOC. CRUCE</th>
                                    <th>VALOR BRUTO</th>
                                    <th>DESCUENTOS</th>
                                    <th>IMPUESTOS</th>
                                    <th>TOTAL</th>
                                    <th>DESCARGAR</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{ documento.encabezado.co + " - " + documento.encabezado.tipoDoc + '-' + documento.encabezado.numDoc }</td>
                                    <td>{ formatDateMin( new Date(documento.encabezado.fechaDoc)) }</td>
                                    <td>{ documento.encabezado.detalle }</td>
                                    <td>{ documento.encabezado.terceroDescripcion }</td>
                                    <td>{ documento.encabezado.numDocProv }</td>
                                    <td>${ formatNumber(documento.encabezado.totalBruto) }</td>
                                    <td>${ formatNumber(documento.encabezado.descuentos) }</td>
                                    <td>${ formatNumber(documento.encabezado.impuestos) }</td>
                                    <td>${ formatNumber(documento.encabezado.totalNeto) }</td>
                                    <td><div className='pdf-icon-container'><Link style={{ color: 'red' }} to={{ pathname: '/portal/documento-cm-detalle-pdf', state: { documento: { empresa: AppReducer.company, proveedor: AppReducer.supplier, encabezado: documento.encabezado, detalles: documento.detalles } } } } ><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></Link></div></td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <br />
                        <SubTitle>Detalle</SubTitle>
                        <table className='info-table doc-table'>
                            <thead>
                                <tr>
                                    <th>ITEM</th>
                                    <th>DESCRIPCION</th>
                                    <th>CANTIDAD</th>
                                    <th>UNIDAD DE MEDIDA</th>
                                    <th>VALOR BRUTO</th>
                                    <th>DESCUENTOS</th>
                                    <th>IMPUESTOS</th>
                                    <th>TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    documento.detalles.map(
                                        detalle => {
                                            return(
                                                <tr key={detalle.id}>
                                                    <td>{ detalle.idItem }</td>
                                                    <td>{ detalle.descripcionItem }</td>
                                                    <td>{ detalle.cantidad }</td>
                                                    <td>{ detalle.unidadMedida }</td>
                                                    <td>${ formatNumber(detalle.totalBruto) }</td>
                                                    <td>${ formatNumber(detalle.descuentos) }</td>
                                                    <td>${ formatNumber(detalle.impuestos) }</td>
                                                    <td>${ formatNumber(detalle.totalNeto) }</td>
                                                </tr>
                                            );
                                        }
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='mobile-view'>
                        <br />
                        <SubTitle>Documento</SubTitle>
                        <div className='document-head-container'>
                            <div className='card-fields-container'>
                                <div className='card-field-container'>
                                    <div className='card-field-title'>{ AppReducer.company.name }</div>
                                </div>
                                <br />
                                <div className='card-field-container'>
                                    <div className='card-field-title'>PROVEEDOR:</div>
                                </div>
                                <div className='card-field-container'>
                                    <div className='card-field-title'>N.I.T:</div>
                                    <div>{ AppReducer.supplier.nit }</div>
                                </div>
                                <div className='card-field-container'>
                                    <div>{ AppReducer.supplier.razonSocial }</div>
                                </div>
                                <br />
                                <div className='card-field-container'>
                                    <div className='card-field-title'>CENTRO DE OPERACIÓN:</div>
                                    <div>{ documento.encabezado.co }</div>
                                </div>
                                <div className='card-field-container'>
                                    <div className='card-field-title'>DOCUMENTO:</div>
                                    <div>{ documento.encabezado.tipoDoc + '-' + documento.encabezado.numDoc }</div>
                                </div>
                                <div className='card-field-container'>
                                    <div className='card-field-title'>FECHA:</div>
                                    <div>{ formatDateMin( new Date(documento.encabezado.fechaDoc)) }</div>
                                </div>
                                <div className='card-field-container'>
                                    <div className='card-field-title'>DETALLE:</div>
                                    <div>{ documento.encabezado.detalle }</div>
                                </div>
                                <div className='card-field-container'>
                                    <div className='card-field-title'>DOCUMENTO CRUCE:</div>
                                    <div>{ documento.encabezado.numDocProv }</div>
                                </div>
                                <div className='card-field-container'>
                                    <div className='card-field-title'>VALOR BRUTO:</div>
                                    <div>${ formatNumber(documento.encabezado.totalBruto) }</div>
                                </div>
                                <div className='card-field-container'>
                                    <div className='card-field-title'>DESCUENTOS:</div>
                                    <div>${ formatNumber(documento.encabezado.descuentos) }</div>
                                </div>
                                <div className='card-field-container'>
                                    <div className='card-field-title'>IMPUESTOS:</div>
                                    <div>${ formatNumber(documento.encabezado.impuestos) }</div>
                                </div>
                                <div className='card-field-container'>
                                    <div className='card-field-title'>TOTAL:</div>
                                    <div>${ formatNumber(documento.encabezado.totalNeto) }</div>
                                </div>
                            </div>
                        </div>
                        <br />
                        {
                        <PDFDownloadLink document={<DocumentoCMDetallePDFView documento={documento} />} fileName={ 'documento-' + documento.encabezado.tipoDoc + '-' + documento.encabezado.numDoc + '.pdf' }>
                            {
                                ({ blob, url, loading, error }) => {
                                    return(loading ? 'Cargando informe...' : <div className='download-link pdf-icon-container'><FontAwesomeIcon className='view-icon' icon={faFilePdf} /> Descargar</div>);
                                }
                            }
                        </PDFDownloadLink>}
                    </div>
                </>
                }
            </div>
        </div>
    );
}

export default DocumentoComercialDetalleView;
