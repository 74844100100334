import React, { useState, useEffect } from 'react';
import WelcomeLayoutView from './WelcomeLayoutView';

import { connect } from 'react-redux';
import { getCompanyDataAction } from '../../redux/actions/AppActions';
import AppReducer from '../../redux/reducers/AppReducer';


const WelcomeLayout = ({ AppReducer, getCompanyDataAction, history }) => {
    
    useEffect(() => {
        /*if(AppReducer.supplier.nit.length > 0){
            history.push('/portal');
            return 0;
        }*/

        if(localStorage.getItem('nitProveedor')){
            localStorage.removeItem("nitProveedor");
        }

        getCompanyDataAction();
    }, []);

    const goToVisual = () => {
        window.open('https://www.visualretail.co/', '_blank');
    }

    return(
        <WelcomeLayoutView onVisualClick={goToVisual} />
    );
}

const mapStateToProps = ({ AppReducer }) => {
    return {
        AppReducer
    };
};

const mapDispatchToProps = {
    getCompanyDataAction
}

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeLayout);