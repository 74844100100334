import React, { useEffect } from 'react';
import Button from '../../components/button/Button';
import { PDFViewer } from '@react-pdf/renderer'
import CertificadoPDFView from './CertificadoPDFView';

const CertificadoPDF = ({ history }) => {

    useEffect(() => {

        if(!history.location.state.retencion){
            history.push('/portal');
            return 0;
        }

    }, []);

    return(
        <>
            <div>
                <Button onClick={ () => { history.goBack() } } >Volver</Button>
            </div>
            <br />
            <PDFViewer style={{ width: '100%', height: '100vh' }}>
                <CertificadoPDFView retencion={ history.location.state.retencion } />
            </PDFViewer>
        </>
    );
}

export default CertificadoPDF;