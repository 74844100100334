import React, { useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer'
import Button from '../../components/button/Button';
import { formatNumber, formatDateMin } from '../../misc/index';


const Documento = (props) => {

    const { documento } = props;

    return(
        <Document>
            <Page size="LETTER" orientation="landscape" style={styles.pagina} wrap>
                <View style={[styles.block, { marginBottom: 10 }]}>
                    <Text style={styles.title}>{ documento.encabezado.tipoDocDescripcion }</Text>
                    <View style={styles.fieldRow}>
                        <Text style={styles.fieldName}>INVERSIONES EURO S.A.</Text>
                        
                        <View style={styles.field}>
                            <Text style={styles.fieldName}>Número: { documento.encabezado.idCo + '-' + documento.encabezado.tipoDoc + '-' + documento.encabezado.numeroDoc }</Text>
                            <Text style={styles.fieldName}></Text>
                        </View>
                    </View>
                    <View style={styles.fieldRow}>
                        <View style={styles.field}>
                            <Text style={styles.fieldName}>NIT: </Text>
                            <Text style={styles.fieldName}>811045607-6</Text>
                        </View>
                        <View style={styles.field}>
                            <Text style={styles.fieldName}>Fecha: </Text>
                            <Text style={styles.fieldName}>{ formatDateMin(new Date(documento.encabezado.fechaDoc)) }</Text>
                        </View>
                    </View>
                </View>

                <View style={[styles.block, { marginBottom: 10 }]}>
                    <View style={styles.fieldRow}>
                        <View style={styles.field}>
                            <Text style={styles.fieldName}>Tercero: </Text>
                            <Text style={styles.fieldName}>{ documento.proveedor.tercero.razonSocial }</Text>
                        </View>
                        <View style={styles.field}>
                            <Text style={styles.fieldName}>Identificación: </Text>
                            <Text style={styles.fieldName}>{ documento.proveedor.nit }</Text>
                        </View>
                    </View>
                </View>

                <View style={[styles.block, { marginBottom: 10 }]}>
                    <View style={styles.fieldRow}>
                        <View style={styles.field}>
                            <Text style={styles.fieldName}>Notas: </Text>
                            <Text style={styles.fieldName}>{ documento.encabezado.notas }</Text>
                        </View>
                    </View>
                </View>

                <View style={{ marginBottom: 10 }}>
                    <View style={{ flexDirection: 'row', borderTop: 2, borderWidth: 2 }}>
                        <View style={{ width: '10%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text>Cod. Cuenta</Text>
                        </View>
                        <View style={{ width: '20%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text>Descripcion Cuenta</Text>
                        </View>
                        <View style={{ width: '5%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text>C. O.</Text>
                        </View>
                        <View style={{ width: '10%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text>Tercero</Text>
                        </View>
                        <View style={{ width: '25%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text>Razon Social</Text>
                        </View>
                        <View style={{ width: '15%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text>Debitos</Text>
                        </View>
                        <View style={{ width: '15%', fontSize: 10, textAlign: 'center', justifyContent: 'center', height: 20 }}>
                            <Text>Creditos</Text>
                        </View>
                    </View>

                    {
                        documento.movimiento.detalles.map(
                            (detalle, index) => {
                                console.log(detalle);
                                return(
                                    <View style={{ flexDirection: 'row', borderLeftWidth: 2, borderRightWidth: 2, marginTop: -2 }}>
                                        <View style={{ width: '10%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>{ detalle.cuentaId }</Text>
                                        </View>
                                        <View style={{ width: '20%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>{ detalle.cuentaDescripcion }</Text>
                                        </View>
                                        <View style={{ width: '5%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>{ detalle.idCo }</Text>
                                        </View>
                                        <View style={{ width: '10%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>{ detalle.nit }</Text>
                                        </View>
                                        <View style={{ width: '25%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>{ detalle.razonSocial }</Text>
                                        </View>
                                        <View style={{ width: '15%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>${ formatNumber(detalle.valorDb) }</Text>
                                        </View>
                                        <View style={{ width: '15%', fontSize: 10, textAlign: 'left', justifyContent: 'center', paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>${ formatNumber(detalle.valorCr) }</Text>
                                        </View>
                                    </View>
                                );
                            }
                        )
                    }

                    <View style={{ flexDirection: 'row', borderTop: 2, borderWidth: 2 }}>
                        <View style={{ width: '70%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text>Sumas Iguales:</Text>
                        </View>
                        <View style={{ width: '15%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, height: 20, paddingLeft: 2 }}>
                            <Text>${ formatNumber(documento.movimiento.totalDebito) }</Text>
                        </View>
                        <View style={{ width: '15%', fontSize: 10, textAlign: 'left', justifyContent: 'center', height: 20, paddingLeft: 2 }}>
                            <Text>${ formatNumber(documento.movimiento.totalCredito) }</Text>
                        </View>
                    </View>
                </View>

                <View style={{ marginBottom: 10 }}>
                    <View style={{ flexDirection: 'row', borderTop: 2, borderWidth: 2 }} fixed>
                        <View style={{ width: '5%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text>Reg.</Text>
                        </View>
                        <View style={{ width: '6%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text>C. O.</Text>
                        </View>
                        <View style={{ width: '12%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text>Doc. Cruce</Text>
                        </View>
                        <View style={{ width: '28%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text>Detalle</Text>
                        </View>
                        <View style={{ width: '12%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text>Descuentos</Text>
                        </View>
                        <View style={{ width: '12%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text>Retenciones</Text>
                        </View>
                        <View style={{ width: '12%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text>Valor Pago</Text>
                        </View>
                        <View style={{ width: '13%', fontSize: 10, textAlign: 'center', justifyContent: 'center', height: 20 }}>
                            <Text>Valor Factura</Text>
                        </View>
                    </View>

                    {
                        documento.documentos.map(
                            (documento, index) => {
                                return(
                                    <View style={{ flexDirection: 'row', borderLeftWidth: 2, borderRightWidth: 2, marginTop: -2 }}>
                                        <View style={{ width: '5%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>{ (index + 1) }</Text>
                                        </View>
                                        <View style={{ width: '6%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>{ documento.docIdCoMov }</Text>
                                        </View>
                                        <View style={{ width: '12%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>{ documento.docCruTipoDoc + ' ' + documento.docCruNumeroDoc }</Text>
                                        </View>
                                        <View style={{ width: '28%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>{ documento.notas }</Text>
                                        </View>
                                        <View style={{ width: '12%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>${ formatNumber(documento.factDscto) }</Text>
                                        </View>
                                        <View style={{ width: '12%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>${ formatNumber(documento.factValorRetencion) }</Text>
                                        </View>
                                        <View style={{ width: '12%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>${ formatNumber(documento.valorPago) }</Text>
                                        </View>
                                        <View style={{ width: '13%', fontSize: 10, textAlign: 'left', justifyContent: 'center', paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>${ documento.valorDocumento > 0 ? formatNumber(documento.valorDocumento) : formatNumber(documento.docValorNeto)}</Text>
                                        </View>
                                    </View>
                                );
                            }
                        )
                    }
                </View>

                <View style={styles.pageNumber}>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
                </View>
            </Page>
        </Document>
    );
}

const DocumentoPDF = (props) => {

    useEffect(() => {

        if(!props.history.location.state.documento){
            props.history.push('/portal');
            return 0;
        }

    }, []);

    return(
        <>
            <div>
                <Button onClick={ () => { props.history.goBack() } } >Volver</Button>
            </div>
            <br />
            <PDFViewer style={{ width: '100%', height: '100vh' }}>
                <Documento documento={ props.history.location.state.documento } />
            </PDFViewer>
        </>
    );
}

const styles = StyleSheet.create({
    block: {
        borderWidth: 2,
        padding: 5
    },
    field: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    fieldRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 5
    },
    header: {
        alignItems: 'center',
        marginBottom: 50
    },
    title: {
        fontSize: 15,
        textAlign: 'center'
    },
    fieldName: {
        fontSize: 10,
        fontWeight: 'ultrabold'
    },
    subTitle: {
        fontSize: 20,    
    },
    proveedorContainer: {
        fontSize: 10,
        marginBottom: 10
    },
    bodyContainer: {
        
    },
    encabezadoContainer: {
        fontSize: 10,
        marginBottom: 10
    },
    campoContainer: {
        flexDirection: 'row',
    },
    campoTitulo: {
        
    },
    tabla: {
        borderWidth: 1,
        borderRadius: 5,
        fontSize: 10
    },
    th: {
        flexDirection: 'row',
    },
    tr: {
        flexDirection: 'row',
    },
    tablaCampoTitulo: {
        backgroundColor: 'black',
        color: 'white',
        /*width: '100%',*/
        borderRightWidth: 1,
        borderRightColor: 'white',
        paddingLeft: 5
        
    },
    tablaCampo: {
        /*width: '100%',*/
        color: 'black',
        borderRightWidth: 1,
        borderRightColor: 'black',
        paddingLeft: 5,
        borderBottomWidth: 1
    },
    tablaCampoTotal: {
        width: 102,
        color: 'black',
        paddingLeft: 5,
        borderBottomWidth: 1
    },
    pagina: {
        padding: 50
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 1,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    }
});

export default DocumentoPDF;