import React, { useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer'
import Button from '../../components/button/Button';
import { formatNumber, formatDateMin } from '../../misc/index';


const DocumentoCruzado = (props) => {

    const { factura } = props;

    return(
        <Document>
            <Page size="LETTER" orientation="landscape" style={styles.pagina}>
                <View style={styles.header}>
                    <Text style={styles.title}>{ factura.empresa.razonSocial }</Text>
                    <Text style={styles.subTitle}>{ factura.encabezado.tipoDocDescripcion }</Text>
                </View>
                <View style={styles.proveedorContainer}>
                    <Text>N.I.T.: { factura.proveedor.nit }</Text>
                    <Text>PROVEEDOR: { factura.proveedor.tercero.razonSocial }</Text>
                </View>
                <View style={styles.bodyContainer}>
                    <View style={styles.encabezadoContainer}>
                        <View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>Centro de operacion: </Text>
                            <Text>{ factura.encabezado.idCo }</Text>
                        </View>
                        <View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>Documento: </Text>
                            <Text>{ factura.encabezado.tipoDoc + '-' + factura.encabezado.numeroDoc }</Text>
                        </View>
                        <View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>Fecha: </Text>
                            <Text>{ formatDateMin(new Date(factura.encabezado.fechaDoc)) }</Text>
                        </View>
                        <View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>Fecha de cancelacion: </Text>
                            <Text>{ factura.encabezado.fechaCancelacion.length > 0 ? formatDateMin(new Date(factura.encabezado.fechaCancelacion)) : '' }</Text>
                        </View>
                        <View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>Fecha de vencimiento: </Text>
                            <Text>{ factura.encabezado.fechaVcto.length > 0 ? formatDateMin(new Date(factura.encabezado.fechaVcto)) : '' }</Text>
                        </View>
                        <View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>Valor: </Text>
                            <Text>${ formatNumber(factura.encabezado.debito) }</Text>
                        </View>
                    </View>
                    <View style={styles.detalleContainer}>
                        <Text style={styles.subTitle}>Movimiento contable</Text>
                        <View style={styles.tabla}>
                            <View style={styles.th}>
                                <Text style={[styles.tablaCampoTitulo, { width: 40 }]}>REG.</Text>
                                <Text style={[styles.tablaCampoTitulo, { width: 80 }]}>COD. CUENTA</Text>
                                <Text style={[styles.tablaCampoTitulo, { width: 300 }]}>DESCRIPCION CUENTA</Text>
                                <Text style={[styles.tablaCampoTitulo, { width: 90 }]}>C.O.</Text>
                                <Text style={[styles.tablaCampoTitulo, { width: 90 }]}>DEBITOS</Text>
                                <Text style={[styles.tablaCampoTitulo, { width: 90 }]}>CREDITOS</Text>
                            </View>
                            {
                                factura.movimiento.detalles.map(
                                    (detalle, index) => {
                                        console.log(detalle);
                                        return(
                                            <View key={index} style={styles.tr}>
                                                <Text style={[styles.tablaCampo, { width: 40}]}>{ (index + 1) }</Text>
                                                <Text style={[styles.tablaCampo, { width: 80 }]}>{ detalle.cuentaId }</Text>
                                                <Text style={[styles.tablaCampo, { width: 300 }]}>{ detalle.cuentaDescripcion }</Text>
                                                <Text style={[styles.tablaCampo, { width: 90 }]}>{ detalle.idCoMov }</Text>
                                                <Text style={[styles.tablaCampo, { width: 90 }]}>${ formatNumber(detalle.valorDb) }</Text>
                                                <Text style={[styles.tablaCampo, { width: 90 }]}>${ formatNumber(detalle.valorCr) }</Text>
                                            </View>
                                        );
                                    }
                                )
                            }
                            <View style={styles.tr}>
                                <Text style={[styles.tablaCampoTotal, { width: 40 }]}>TOTAL:</Text>
                                <Text style={[styles.tablaCampoTotal, { width: 80 }]}></Text>
                                <Text style={[styles.tablaCampo, { width: 300 }]}></Text>
                                <Text style={[styles.tablaCampo, { width: 90 }]}></Text>
                                <Text style={[styles.tablaCampo, { width: 90 }]}>${ formatNumber(factura.movimiento.totalDebito) }</Text>
                                <Text style={[styles.tablaCampo, { width: 90 }]}>${ formatNumber(factura.movimiento.totalCredito) }</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.pageNumber}>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
                </View>
            </Page>
        </Document>
    );
}

const DocumentoCruzadoPDF = (props) => {

    useEffect(() => {

        if(!props.history.location.state.factura){
            props.history.push('/portal');
            return 0;
        }

    }, []);

    return(
        <>
            <div>
                <Button onClick={ () => { props.history.goBack() } } >Volver</Button>
            </div>
            <br />
            <PDFViewer style={{ width: '100%', height: '100vh' }}>
                <DocumentoCruzado factura={ props.history.location.state.factura } />
            </PDFViewer>
        </>
    );
}

const styles = StyleSheet.create({
    header: {
        alignItems: 'center',
        marginBottom: 50
    },
    title: {
        fontSize: 20,
        marginBottom: 10    
    },
    subTitle: {
        fontSize: 20,    
    },
    proveedorContainer: {
        fontSize: 10,
        marginBottom: 10
    },
    bodyContainer: {
        
    },
    encabezadoContainer: {
        fontSize: 10,
        marginBottom: 10
    },
    campoContainer: {
        flexDirection: 'row',
    },
    campoTitulo: {
        
    },
    tabla: {
        borderWidth: 1,
        borderRadius: 5,
        fontSize: 10
    },
    th: {
        flexDirection: 'row',
    },
    tr: {
        flexDirection: 'row',
    },
    tablaCampoTitulo: {
        backgroundColor: 'black',
        color: 'white',
        /*width: '100%',*/
        borderRightWidth: 1,
        borderRightColor: 'white',
        paddingLeft: 5
        
    },
    tablaCampo: {
        /*width: '100%',*/
        color: 'black',
        borderRightWidth: 1,
        borderRightColor: 'black',
        paddingLeft: 5,
        borderBottomWidth: 1
    },
    tablaCampoTotal: {
        width: 102,
        color: 'black',
        paddingLeft: 5,
        borderBottomWidth: 1
    },
    pagina: {
        padding: 50
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    }
});

export default DocumentoCruzadoPDF;