import React, { useState, useEffect } from 'react';
import InfoBox from '../../components/infoBox/InfoBox';
import Button from '../../components/button/Button';
import { Alert, Spinner } from 'react-bootstrap';
import './Signup.css';

const TextBox = (props) => {
    return(
        <input className='signup-text-box' type={props.type} name={props.name} value={props.value} onChange={props.onChange} required />
    );
}

const Field = (props) => {
    return(
        <div className='signup-field'>
            <div className='signup-field-name'>{ props.label }</div>
            <TextBox type={props.type} name={props.name} value={props.value} onChange={props.onChange} />
            <div className='signup-field-hint'>{ props.hint }</div>
        </div>
    );
}


const SignupView = ({ form, onSubmit, onChange }) => {

    return(
        <div className='signup-content'>
            <div className='signup-body'>
                <div>
                    <h2 className='signup-h2'>Registro de proveedor</h2>
                </div>
                <div>
                    <p className='signup-p'>Por favor llene los campos con asterisco(*)</p>
                    <InfoBox>Digite los campos obligatorios(*) para crear un nuevo proveedor</InfoBox>
                    { form.error &&
                        <>
                            <br />
                            <Alert variant='danger'>{ form.errorMsg }</Alert>
                        </>
                    }
                </div>
                <form className='signup-form' onSubmit={onSubmit} >
                    <Field type='text' label='Razón social(*)' hint='Razón social del proveedor' name='razonSocial' value={form.razonSocial} onChange={onChange} />
                    <Field type='text' label='N.I.T.(*)' hint='NIT sin dijito de verificación ni puntos' name='nit' value={form.nit} onChange={onChange} />
                    <Field type='email' label='Correo electrónico(*)' hint='Dijite su correo electrónico' name='email' value={form.email} onChange={onChange} />
                    <Field type='email' label='Repita su Correo electrónico(*)' hint='Dijite de nuevo su correo electrónico' name='email2' value={form.email2} onChange={onChange} />
                    <Field type='text' label='Numero celular(*)' hint='Dijite su numero celular' name='cel' value={form.cel} onChange={onChange} />
                    <Field type='password' label='Contraseña(*)' hint='Dijite una contraseña' name='password1' value={form.password1} onChange={onChange} />
                    <Field type='password' label='Repita la contraseña(*)' hint='Dijite de nuevo la contraseña' name='password2' value={form.password2} onChange={onChange} />
                    {/*<div className="signup-tyc-field">
                        <input type="checkbox" value={tyc} onChange={() => { setTyc(!tyc); }} />
                        <div className="signup-tyc-label">Ha leído y acepta nuestra política de tratamiento y procedimientos en materia de protección de datos personales <button className="signup-tyc-link" onClick={handleTycLink} >(Ver nuestras politicas)</button></div>
                    </div>*/}
                    { form.loading &&
                        <>
                            <br />
                            <Spinner animation="border" variant="primary" />
                        </>
                    }
                    { !form.loading &&
                        <>
                            <br />
                            <div className='signup-button-container'>
                                <Button type='submit'>Registrarse</Button>
                            </div>
                        </>
                    }
                </form>
            </div>
        </div>
    );
}

export default SignupView;