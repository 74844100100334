import React, { useState, useEffect } from 'react';
import AdminLayoutView from './AdminLayoutView';

import { connect } from 'react-redux';
import AdminReducer from '../../../redux/reducers/AdminReducer';
import { cleanAdminDataAction } from '../../../redux/actions/AdminActions';

const AdminLayout = ({ AdminReducer, history, cleanAdminDataAction }) => {

    useEffect(() => {
        if(AdminReducer.admin._id.length < 1){
            history.push('/admin-login');
            return 0;
        }

    }, []);

    const [ menu, setMenu ] = useState({
        menuClass: 'menu',
        menuOptionText: 'menu-option-text'
    });

    const handleMouseOver = () => {
        setMenu({
            ...menu,
            menuClass: 'menu-active',
            menuOptionText: 'menu-option-text-active'
        });
    }

    const handleMouseLeave = () => {
        setMenu({
            ...menu,
            menuClass: 'menu',
            menuOptionText: 'menu-option-text'
        });
    }

    const exit = async () => {
        await cleanAdminDataAction();
        history.push('/');
    }

    return(
        <AdminLayoutView AdminReducer={AdminReducer} menu={menu} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} onExit={exit} history={history} />
    );
}

const mapStateToProps = ({ AppReducer, AdminReducer }) => {
    return {
        AppReducer,
        AdminReducer
    };
};

const mapDispatchToProps = {
    cleanAdminDataAction
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);