import React, { useState, useEffect } from 'react';
import Title from '../../components/title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import SubTitle from '../../components/subTitle/SubTitle';
import { httpGet } from '../../http';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatNumber, formatDateMin } from '../../misc/index';
import Card from '../../components/Card';
import './NotasComercial.css';

const DevCard = ({ document }) => {
    return(
        <Card>
            <div className='card-fields-container egresos-cards-container'>
                <div className='card-field-container'>
                    <div className='card-field-title'>DOCUMENTO:</div>
                    <div className='card-field-data'>{ document.tipoDoc + '-' + document.numDoc }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>DOCUMENTO CRUCE</div>
                    <div className='card-field-data'>{ document.numDocProv }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>FECHA:</div>
                    <div>{ formatDateMin(new Date(document.fechaDoc)) }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>TOTAL</div>
                    <div>${ formatNumber(document.totalNeto) }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>DETALLE</div>
                    <div className='card-field-data'>{ document.detalle }</div>
                </div>
            </div>
        </Card>
    );
}

const NotasComercialView = ( { AppReducer, notas }) => {
    
    return(
        <div className='page-container'>
            <Title>Notas de proveedor</Title>
            <br />
            <div className='page-body'>
                <div className='desktop-view'>
                    <SubTitle>Empresa: { AppReducer.company.name } </SubTitle>
                    <SubTitle>Proveedor:</SubTitle>
                </div>
                <div>
                    <div><span className='info-title'>N.I.T: </span><span>{ AppReducer.supplier.nit }</span></div>
                    <div><span className='info-title'>Razón social: </span><span>{ AppReducer.supplier.razonSocial }</span></div>
                    <div><span className='info-title'>Centro de operación </span><span>{ notas.co.codigo } - { notas.co.nombre }</span></div>
                </div>
                <br />
                <br />
                <SubTitle>Notas de proveedor</SubTitle>
                { notas.loading ?
                <div className='loading-spinner'>
                        <Spinner animation="border" variant="primary" />
                </div>
                :
                <>
                <div className='desktop-view'>
                    <table className='info-table'>
                        <thead>
                            <tr>
                                <th>DOCUMENTO</th>
                                <th>DOCUMENTO CRUCE</th>
                                <th>FECHA</th>
                                <th>TOTAL</th>
                                <th>DETALLE</th>
                                <th>VER</th>
                                {/*<th>DESCARGAR</th>*/}
                            </tr>
                        </thead>
                        <tbody>
                            { 
                                notas.data.map(
                                    documento => {
                                        return(
                                            <tr key={documento.key}>
                                                <td>{ documento.tipoDoc + '-' + documento.numDoc }</td>
                                                <td>{ documento.numDocProv }</td>
                                                <td>{ formatDateMin(new Date(documento.fechaDoc) ) }</td>
                                                <td>${ formatNumber(documento.totalNeto) }</td>
                                                <td>{ documento.detalle }</td>
                                                <td><div className='view-icon-container'><Link to={{ pathname: '/portal/documento-comercial-detalle', state: { documento: documento } }}><FontAwesomeIcon className='view-icon' icon={faEye} /></Link></div></td>
                                            </tr>
                                        );
                                    }
                                )
                            }
                        </tbody>
                    </table>
                </div>
                <div className='mobile-view'>
                    <div className='cards-container'>
                        {
                        notas.data.map(
                            (documento, index) => {
                                return(
                                    <>
                                        <Link key={index} to={{ pathname: "/portal/documento-comercial-detalle", state: { documento: documento } }} ><DevCard key={index} document={documento} /></Link>
                                    </>
                                );
                            }
                        )
                        }
                    </div>
                </div>
                </>
                }
            </div>
        </div>
    );
}

export default NotasComercialView;
