import React, { useState, useEffect } from 'react';
import { httpGet } from '../../http';
import EgresosView from './EgresosView';
import { discharges } from '../../demo';

import { connect } from 'react-redux';
import AppReducer from '../../redux/reducers/AppReducer';

const Egresos = ({ AppReducer, history }) => {

    const [egresos, setEgresos ] = useState(
        {
            data: [],
            loading: true,
            error: {}
        }
    );

    useEffect(() => {
        getEgresos(AppReducer.supplier.nit);
    }, []);

    const getEgresos = (tercero) => {
        setEgresos({
            ...egresos,
            loading: true
        });
        httpGet('/api/v1/egresos/nit/' + tercero + "?pagina=0&cant=100").then(
            resp => {
                setEgresos({
                    ...egresos,
                    data: resp.data,
                    loading: false
                });
            }
        ).catch(
            error => {
                console.log(error);
                setEgresos({
                    ...egresos,
                    loading: false
                });
            }
        );
    }

    /*const generarEgresoDetallePdf = async (e, egreso2) => {
        e.preventDefault();

        console.log(egreso2);
        
        const encabezado = await httpGet('/ProveedoresWSNEW/api/Egresos/encabezado?co=' + egreso2.co + '&tipoDocumento=' + egreso2.tipoDoc + '&numeroDocumento=' + egreso2.numeroDoc);
        const detalles = await httpGet('/ProveedoresWSNEW/api/Egresos/detalle?co=' + egreso2.co + '&tipoDocumento=' + egreso2.tipoDoc + '&numeroDocumento=' + egreso2.numeroDoc);

        if((encabezado !== null) && (encabezado !== undefined)){
            let egreso = {
                proveedor: estado.proveedor,
                encabezado: encabezado.data,
                detalles: detalles.data
            }

            console.log(egreso);

            props.history.push({ pathname: '/portal/egreso-pdf', state: { egreso: egreso } });
        }
        
    }*/

    return(
        <EgresosView AppReducer={AppReducer} egresos={egresos} />
    );
}

const mapStateToProps = ({ AppReducer }) => {
    return {
        AppReducer
    };
};

export default connect(mapStateToProps)(Egresos);
