import React, { useState, useEffect} from 'react';
import { httpGet } from '../../http';
import { pdf } from '@react-pdf/renderer';
import PDFMerger from 'pdf-merger-js';
import DocumentoFinancieroDetalleView from './DocumentoFinancieroDetalleView';

import { connect } from 'react-redux';
import AppReducer from '../../redux/reducers/AppReducer';

const DocumentoFinancieroDetalle = ({ history, AppReducer }) => {

    const [documento, setDocumento ] = useState(
        {
            empresa: AppReducer.company,
            proveedor: AppReducer.supplier,
            encabezado: {
                tipoDoc: '',
                numeroDoc: '',
                fechaDoc: new Date(),
                fechaVcto: '',
                valor: '',
                debitos: '',
                creditos: '',
                detalle: '',
            },
            detalles: [],
            retenciones: [],
            totalRetenciones: 0,
            loading: false,
            error: {},
            reg: 0
        }
    );

    useEffect(() => {

        if(!history.location.state.documento){
            history.push('/portal');
            return 0;
        }
        
        getdocumentoDetalle(history.location.state.documento);

    }, []);

    const getdocumentoDetalle = async (doc) => {
        setDocumento({
            ...documento,
            loading: true
        });
        
        const encabezado = await httpGet('/api/v1/notas/cg/encabezado/' + doc.idCo + '/' + doc.tipoDoc + '/' + doc.numDoc);
        const detalles = await httpGet('/api/v1/egresos/documentos/' + doc.idCo + '/' + doc.tipoDoc + '/' + doc.numDoc);

        setDocumento({
            ...documento,
            encabezado: encabezado.data[0],
            detalles: detalles.data,
            loading: false
        });
    }

    const getFile = async () => {
        const res = await fetch('/soporte.pdf');
        const blob = res.blob();
        return blob;
    }

    const mergePDF = async () => {
        const docPDF = <documentoPDFView documento={ documento } />;
        const asPdf = pdf();
        asPdf.updateContainer(docPDF);

        let appendixBlob = await getFile();
        let initialBlob = await asPdf.toBlob();

        let merger = new PDFMerger();

        await merger.add(initialBlob);
        await merger.add(appendixBlob);

        const mergedPdf = await merger.saveAsBlob();
        console.log('merge', mergedPdf);

        //setPDFDocument(URL.createObjectURL(mergedPdf));
        await merger.save('documento-' + documento.encabezado.tipoDoc + '-' + documento.encabezado.numeroDoc + '.pdf');
    }

    const generarDocumentoDetallePdf = async () => {
        let newDocumento = {
            empresa: AppReducer.company,
            proveedor: AppReducer.supplier,
            encabezado: documento.encabezado,
            detalles: documento.detalles
        }

        console.log(newDocumento);

        history.push({ pathname: '/portal/documento-fi-detalle-pdf', state: { documento: newDocumento } });
    }

    return(
        <>
        <DocumentoFinancieroDetalleView AppReducer={AppReducer} documento={documento} generarDocumentoDetallePdf={generarDocumentoDetallePdf} downloadPDFFile={mergePDF} />
        </>
    );
}

const mapStateToProps = ({ AppReducer }) => {
    return {
        AppReducer
    };
};

export default connect(mapStateToProps)(DocumentoFinancieroDetalle);
