import React from "react";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileInvoiceDollar, faFileInvoice } from '@fortawesome/free-solid-svg-icons'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { faAward } from '@fortawesome/free-solid-svg-icons'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { faTable } from "@fortawesome/free-solid-svg-icons";
import activLogo from '../../img/activ-logo.png';

const MenuOption = (props) =>  {
    return(
        <div className='menu-option-wrapper'>
            <div className='menu-icon-container' onMouseOver={props.onMouseOver} onMouseLeave={ props.onMouseLeave }>
                <Link style={{ color: 'white' }} className='menu-option-link' to={{ pathname: "" }} onClick={() => { props.onMenuOptionClick(props.to, props.path) }}>
                    <div className='menu-option-link-container'>
                        <div><FontAwesomeIcon className='menu-icon' icon={props.icon} /></div>
                        <div className={props.className}>{props.children}</div>
                    </div>
                </Link>
            </div>
        </div>
    );
}

const MenuView = ({ menu, onMouseOver, onMouseLeave, onMenuOptionClick }) => {

    return(
        <div className={menu.menuClass} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
            <div>
                <div className='menu-logo-wrapper'>
                </div>
                <MenuOption className={menu.menuOptionText} icon={faFileInvoice} to='/portal/facturas' onMenuOptionClick={onMenuOptionClick}>Estado de facturas</MenuOption>
                <MenuOption className={menu.menuOptionText} icon={faFileInvoiceDollar} to='/portal/egresos' onMenuOptionClick={onMenuOptionClick}>Comprobantes de egreso</MenuOption>
                {/*<MenuOption className={menu.menuOptionText} icon={faFileInvoiceDollar} to='/portal/otros-documentos' onMenuOptionClick={onMenuOptionClick}>Otros documentos</MenuOption>*/}
                <MenuOption className={menu.menuOptionText} icon={faFileAlt} to='/portal/centros-operacion' path='/portal/notas-cm' onMenuOptionClick={onMenuOptionClick}>Notas (Comercial)</MenuOption>
                <MenuOption className={menu.menuOptionText} icon={faFileAlt} to='/portal/notas-fi' onMenuOptionClick={onMenuOptionClick}>Notas (Financiero)</MenuOption>
                {/*<MenuOption className={menu.menuOptionText} icon={faFileInvoiceDollar} to='/portal/documentos-cruzados' onMenuOptionClick={onMenuOptionClick}>Documentos cruzados</MenuOption>*/}
                <MenuOption className={menu.menuOptionText} icon={faAward} to='/portal/certificados' onMenuOptionClick={onMenuOptionClick}>Certificados</MenuOption>
                {/*<MenuOption className={menu.menuOptionText} icon={faTable} to='/portal/reportes' onMenuOptionClick={onMenuOptionClick}>Reportes</MenuOption>*/}
                <MenuOption className={menu.menuOptionText} icon={faUserCircle} to='/portal/profile' onMenuOptionClick={onMenuOptionClick}>Perfil</MenuOption>
            </div>
            <div className="home-layout-active-logo-container"> 
                <img src={activLogo} className="home-layout-active-logo" />
            </div>
        </div>
    );
}

export default MenuView;