import React from "react";
import { Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faMinusCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import './StateIcon.css';

const StateIcon = ({estado}) => {
    return(
        <>
            { 
                (estado) ?
                    <OverlayTrigger placement='bottom' overlay={
                        <Tooltip id='error'>
                        Pago efectuado
                        </Tooltip>
                    }>
                        <div className='state-icon-container'>
                            <FontAwesomeIcon className='view-icon' icon={faCheckCircle} />
                        </div>
                    </OverlayTrigger>
                :
                    <OverlayTrigger placement='bottom' overlay={
                        <Tooltip id='error'>
                        Sin pago
                        </Tooltip>
                    }>
                        <div className='state-icon-container-uncheck'>
                            <FontAwesomeIcon className='view-icon' icon={faMinusCircle} />
                        </div>
                    </OverlayTrigger>
            }
        </>
    );
}

export default StateIcon;