import React, { useState, useEffect } from 'react';
import './PasswordRecovery.css';
import { httpGet } from '../../http';
import PasswordRecoveryView from './PasswordRecoveryView';

import { connect } from 'react-redux';
import AppReducer from '../../redux/reducers/AppReducer';


const PasswordRecovery = ({history, AppReducer}) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        loading: true,
        error: {}
    });

    const [ recovery, setRecovery ] = useState({
        nit: '',
        loading: false,
        error: false,
        errorMsg: ''
    });

    useEffect(() => {
        setEstado(
            {
                ...estado,
                empresa: JSON.parse(sessionStorage.getItem('estado'))
            });
    }, []);

    const handleChange = (e) => {
        setRecovery({
            ...recovery,
            [e.target.name]: e.target.value
        });
    }

    const recoveryPassword = () => {
        if(recovery.nit.length > 1){
            setRecovery({
                ...recovery,
                loading: true
            });
            httpGet('/api/v1/proveedor/recovery/' + recovery.nit).then(
                resp => {
                    setRecovery({
                        ...recovery,
                        loading: false
                    });
                    history.push({ pathname: '/login', state: { recovery: true } });
                }
            ).catch(
                error => {
                    console.log(error);
                    if(error.response.status){
                        if(error.response.status === 404 ){
                            setRecovery({
                                ...recovery,
                                error: true,
                                errorMsg: 'El NIT ingresado no esta registrado.'
                            })
                        }

                        if(error.response.status === 500 ){
                            setRecovery({
                                ...recovery,
                                error: true,
                                errorMsg: 'Ocurrio un error al recuperar la contraseña.'
                            })
                        }
                    }
                }
            );
        }
    }

    return(
        <PasswordRecoveryView
            AppReducer={AppReducer}
            recovery={recovery}
            onChange={handleChange}
            recoveryPassword={recoveryPassword}
        />
    );
}

const mapStateToProps = ({ AppReducer }) => {
    return {
        AppReducer
    };
};

export default connect(mapStateToProps)(PasswordRecovery);