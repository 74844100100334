import React, { useState, useEffect} from 'react';
import { httpGet } from '../../http';

import OtrosDocumentosDetalleView from './OtrosDocumentosDetalleView';

const OtrosDocumentos = (props) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        proveedor: {
            nit: '',
            razonSocial: '',
            tel: '',
            email: '',
            tercero: {
                razonSocial: ''
            }
        },
        loading: true,
        error: {}
    });

    const [documento, setDocumento ] = useState(
        {
            encabezado: {
                rowid: '',
                idCo: '',
                tipoDoc: '',
                numeroDoc: '',
                fechaDoc: new Date(),
                valor: 0
            },
            movimiento: {
                detalles: [],
                totalDebito: 0,
                totalCredito: 0
            },
            documentos: [],
            loading: false,
            error: {},
            reg: 0
        }
    );

    useEffect(() => {

        console.log(JSON.parse(sessionStorage.getItem('estado')));

        let newEstado = JSON.parse(sessionStorage.getItem('estado'));
        newEstado.proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        console.log(newEstado);
        
        setEstado(newEstado);

        if(!props.history.location.state.documento){
            props.history.push('/portal');
            return 0;
        }
        
        getDocumentoDetalle(props.history.location.state.documento, newEstado.proveedor);

    }, []);

    const getDocumentoDetalle = async (doc, proveedor) => {
        setDocumento({
            ...doc,
            loading: true
        });
        
        const encabezado = await httpGet('/api/v1/egresos/documento-fi/' + doc.rowid);
        const movimiento = await httpGet('/api/v1/egresos/detalle/' + doc.rowid);
        const documentos = await httpGet('/api/v1/egresos/documentos/' + proveedor.nit + '/' + doc.rowid);

        if((movimiento !== null) && (movimiento !== undefined)){
            setDocumento({
                ...documento,
                encabezado: encabezado.data,
                movimiento: movimiento.data,
                documentos: documentos.data,
                loading: false
            });
        }
        
    }
    
    const generarDocumentoDetallePdf = async () => {

        let data = {
            empresa: estado.empresa,
            proveedor: estado.proveedor,
            encabezado: documento.encabezado,
            movimiento: documento.movimiento,
            documentos: documento.documentos
        }

        console.log(data);

        props.history.push({ pathname: '/portal/documento-pdf', state: { documento: data } });
    }

    return(
        <OtrosDocumentosDetalleView
            estado={estado}
            documento={documento}
            generarDocumentoDetallePdf={generarDocumentoDetallePdf}
        />
    );
}

export default OtrosDocumentos;