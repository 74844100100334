import React, { useState, useEffect } from 'react';
import './PasswordRecovery.css';
import InfoBox from '../../components/infoBox/InfoBox';
import Button from '../../components/button/Button';
import { httpGet } from '../../http';
import { Alert, Spinner } from 'react-bootstrap';

import { connect } from 'react-redux';
import AppReducer from '../../redux/reducers/AppReducer';

const TextBox = (props) => {
    return(
        <input className='password-recovery-text-box' type={props.type} name={props.name} value={props.value} onChange={props.onChange} />
    );
}

const PasswordRecoveryView = ({ AppReducer, recovery, onChange, recoveryPassword }) => {

    return(
        <div className='passwordRecovery-container'>
            <div className='passwordRecovery-content'>
                <div className='passwordRecovery-body'>
                    { recovery.error &&
                        <Alert variant='danger'>{ recovery.errorMsg }</Alert>
                    }
                    <div>
                        <h2 className='password-recovery-h2'>Recuperación de contraseña</h2>
                    </div>
                    <div>
                        <p className='password-recovery-p'>Se creara una nueva contraseña la cual sera enviada a su correo electronico, si no tiene acceso a su correo electronico comuniquese con { AppReducer.company.alterName }</p>
                    </div>
                    { recovery.loading ?
                        <div className='loading-spinner'>
                            <Spinner animation="border" variant="primary" />
                        </div>
                    :
                        <div className='passwordRecovery-form'>
                            <div className='passwordRecovery-field'>
                                <div className='passwordRecovery-field-name'>Por favor ingrese su NIT:</div>
                                <TextBox type='text' name='nit' value={recovery.nit} onChange={onChange}  />
                            </div>
                            <Button onClick={recoveryPassword}>Generar nueva contraseña</Button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default PasswordRecoveryView;