const INITIAL_STATE = {
    company: {
        id: '',
        name: '',
        alterName: '',
        vd: '',
        city: ''
    },
    supplier: {
        nit: '',
        razonSocial: '',
        tel: '',
        email: '',
    },
    loading: false,
    error: {
        state: false,
        msg: ''
    }
};

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case "SET_LOADING_ON":
            return {...state, loading: true}
        case "SET_LOADING_OFF":
            return {...state, loading: false}
        case "SET_COMPANY":
            return {...state, company: action.payload}
        case "SET_SUPPLIER":
            return {...state, supplier: action.payload}
        case "CLEAN_SUPPLIER":
            return {...state, supplier: {nit: '', razonSocial: '', tel: '', email: ''}}
        case "SET_ERROR":
            return {...state, loading: false, error: action.payload}
                                
            default: return state;
    }
}