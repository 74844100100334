import React, { useState, useEffect } from 'react';
import './Welcome.css';
import { httpGet } from '../../http';
import { Link } from 'react-router-dom';
import Button from '../../components/button/Button';
import Loading from '../../components/loading/Loading';

import { connect } from 'react-redux';
import AppReducer from '../../redux/reducers/AppReducer';

const WelcomeView = ({ AppReducer, onButtonClick }) => {

    return(
        <div>
            { AppReducer.loading &&
                <Loading />
            }
            { !AppReducer.loading &&            
                <div>
                    <div className='welcome-content-full'>
                        <div>
                            <h2 className='welcome-h2'>Bienvenido al portal de Proveedores</h2>
                        </div>
                        <br />
                        <div>
                            <p className='welcome-p'>Este es el portal de { AppReducer.company.name } para Proveedores</p>
                            <br />
                            <p className='welcome-p'>Aquí usted prodrá:</p>
                            <ul>
                                <li className='welcome-li'>Ver sus comprobantes de pago</li>
                                <li className='welcome-li'>Ver sus notas de proveedor</li>
                                <li className='welcome-li'>Obtener certificados</li>
                            </ul>
                        </div>
                        <div>
                            <p className='welcome-h2'>Seleccione que desea hacer:</p>
                            <ul>
                                <li className='welcome-li'><Link to={{ pathname: '/login' }}>Ya tiene una cuenta de proveedor? Ingrese aqui</Link></li>
                                <li className='welcome-li'><Link to={{ pathname: '/signup' }}>Registrese como proveedor</Link></li>
                            </ul>
                        </div>
                    </div>
                    
                    <div className='welcome-content-mobile'>
                        <p className='welcome-p'>Portal Para Proveedores</p>
                        <p className='welcome-p'>{ AppReducer.company.name }</p>
                        <br/>
                        <div>
                            <p className='welcome-h2'>Seleccione que desea hacer:</p>
                            <div className='welcome-buttons-container'>
                                <Button type='button' onClick={ () => {onButtonClick(1);} }>Ingrese aqui</Button>
                                <br />
                                <br />
                                <Button type='button' onClick={ () => {onButtonClick(2);} }>Registrese como proveedor</Button>
                            </div>
                        </div>
                        
                    </div>

                </div>
            }
        </div>
    );
}

const mapStateToProps = ({ AppReducer }) => {
    return {
        AppReducer
    };
};

export default connect(mapStateToProps)(WelcomeView);