import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Title from '../../../components/title/Title';
import { Spinner, Modal, Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-regular-svg-icons';
import ConfirmModal from '../../../components/modal/confirmModal/ConfirmModal';
import { httpGet, httpPost, httpDelete } from '../../../http';
import './Admins.css';

const TextBox = (props) => {
    return(
        <input className={ props.error ? 'admins-text-box-error' : 'admins-text-box'} type={props.type} name={props.name} value={props.value} onChange={props.onChange} required />
    );
}

const Field = (props) => {
    return(
        <div className='admins-field'>
            <div className='admins-field-row'>
                <div className='admins-field-name'>{ props.label }</div>
                <div><TextBox type={props.type} name={props.name} value={props.value} onChange={props.onChange} error={props.error} /></div>
                <div className='admins-field-hint'>{ props.hint }</div>
            </div>
            <div className="admins-field-msg">{props.msg}</div>
        </div>
    );
}

const EditModal = (props) => {
    if(props.show){
        return(
            ReactDOM.createPortal(
                <Modal size="xl" show={props.show} onHide={props.onClose} onShow={() => {}}>
                    <Modal.Header closeButton>
                        <h3>{props.title}</h3>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <form className='signup-form' >
                            <Field type='text' label='Nombre(*)' hint='Nombre del usuario' name='nombre' value={props.admin.nombre} onChange={props.onChange} error={props.admin.error.nombre} msg={props.admin.msg.nombre} />
                            <Field type='email' label='Correo electrónico(*)' hint='Dijite su correo electrónico' name='email' value={props.admin.email} onChange={props.onChange} error={props.admin.error.email} msg={props.admin.msg.email}/>
                            <Field type='password' label='Contraseña(*)' hint='Dijite una contraseña' name='password1' value={props.admin.password1} onChange={props.onChange} error={props.admin.error.password1} msg={props.admin.msg.password1}/>
                            <Field type='password' label='Repita la contraseña(*)' hint='Dijite de nuevo la contraseña' name='password2' value={props.admin.password2} onChange={props.onChange} error={props.admin.error.password2} msg={props.admin.msg.password2}/>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Row>
                            <Col>
                                <Button onClick={props.onAccept}>Aceptar</Button>
                            </Col>
                            <Col>
                                <Button onClick={props.onClose}>Cancelar</Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>
                , document.getElementById('modal'))            
        );
    } else {
        return (
            null
        );
    }
}

const Admins = (props) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        admin: {
            email: '',
            nombre: '',
        },
        loading: true,
        error: {}
    });

    const [ admins, setAdmins ] = useState({
        data: [],
        loading: false,
        error: {},
        showModal: false,
        showEditModal: false,
        showNewModal: false
    });

    const [ admin, setAdmin ] = useState({
        _id: '',
        nombre: '',
        email: '',
        password1: '',
        password2: '',
        msg:{
            nombre: '',
            email: '',
            password1: '',
            password2: ''
        },
        error:{
            nombre: false,
            email: false,
            password1: false,
            password2: false
        }
    });

    const [ showEditModal, setShowEditModal ] = useState(false);
    const [ showNewModal, setShowNewModal ] = useState(false);
    const [ showRemoveModal, setShowRemoveModal ] = useState(false);

    useEffect(() => {
        setEstado(JSON.parse(sessionStorage.getItem('estado')));
        setEstado({
            ...estado,
            admin: JSON.parse(sessionStorage.getItem('adminUser'))
        });

        getAdmins();

    }, []);

    const getAdmins = () => {
        setAdmins({
            ...admins,
            loading: true
        });
        httpGet('/api/v1/admin-user').then(
            resp => {
                console.log(resp.data);
                setAdmins({
                    ...admins,
                    data: resp.data,
                    loading: false
                })
            }
        ).catch(
            error => {
                console.log(error);
                setAdmins({
                    ...admins,
                    loading: false
                });
            }
        );

    }

    const handleRemove = (e, admin) => {
        setAdmin({
            ...admin,
            _id: admin._id,
            nombre: admin.nombre,
            email: admin.email
        });
        setShowRemoveModal(true);
    }

    const closeRemoveModal = () => {
        setShowRemoveModal(false);
    }
    
    const closeEditModal = () => {
        setShowEditModal(false);
    }

    const closeNewModal = () => {
        setShowNewModal(false);
    }

    const handleShowEdit = (e, admin) => {
        setAdmin({
            ...admin,
            
        });
        setAdmin({
            _id: admin._id,
            nombre: admin.nombre,
            email: admin.email,
            password1: '',
            password2: '',
            msg:{
                nombre: '',
                email: '',
                password1: '',
                password2: ''
            },
            error:{
                nombre: false,
                email: false,
                password1: false,
                password2: false
            }
        });
        setShowEditModal(true);
    }

    const handleNew = (e, id) => {
        setAdmin({
            _id: '',
            nombre: '',
            email: '',
            password1: '',
            password2: '',
            msg:{
                nombre: '',
                email: '',
                password1: '',
                password2: ''
            },
            error:{
                nombre: false,
                email: false,
                password1: false,
                password2: false
            }
        });
        setShowNewModal(true);
    }

    const handleChange = (e) => {
        setAdmin({
            ...admin,
            [e.target.name]: e.target.value
        })
    }

    const handleSave = () => {

        let error = false;
        let newAdmin = {
            ...admin,
            msg:{
                nombre: '',
                email: '',
                password1: '',
                password2: ''
            },
            error:{
                nombre: false,
                email: false,
                password1: false,
                password2: false
            }
        }

        if(admin.nombre.length < 1) {
            newAdmin.error.nombre = true;
            newAdmin.msg.nombre = 'Debe ingresar un nombre.';
            error = true;
        }

        if(admin.email.length < 1) {
            newAdmin.error.email = true;
            newAdmin.msg.email = 'Debe ingresar un correo electronico.';
            error = true;
        }

        if(admin.password1.length < 1) {
            newAdmin.error.password1 = true;
            newAdmin.msg.password1 = 'Debe ingresar una contraseña.';
            error = true;
        }

        if(admin.password2.length < 1) {
            newAdmin.error.password2 = true;
            newAdmin.msg.password2 = 'Debe repetir una contraseña.';
            error = true;
        }

        if(admin.password1 !== admin.password2){
            newAdmin.error.password1 = true;
            newAdmin.msg.password1 = 'Las contraseñas no coinciden';
            newAdmin.error.password2 = true;
            newAdmin.msg.password2 = 'Las contraseñas no coinciden';
            error = true;
        }

        setAdmin(newAdmin);

        if(!error) {
            const data = {
                _id: admin._id,
                nombre: admin.nombre,
                email: admin.email,
                password: admin.password1
            }
            httpPost('/api/v1/admin-user', data).then(
                resp => {
                    setShowEditModal(false);
                    setShowNewModal(false);
                    getAdmins();
                }
            ).catch(
                error => {
                    console.log(error);
                    setShowEditModal(false);
                    setShowNewModal(false);
                }
            );
        }
    }

    const handleEdit = () => {

        let error = false;
        let newAdmin = {
            ...admin,
            msg:{
                nombre: '',
                email: '',
                password1: '',
                password2: ''
            },
            error:{
                nombre: false,
                email: false,
                password1: false,
                password2: false
            }
        }

        if(admin.nombre.length < 1) {
            newAdmin.error.nombre = true;
            newAdmin.msg.nombre = 'Debe ingresar un nombre.';
            error = true;
        }

        if(admin.email.length < 1) {
            newAdmin.error.email = true;
            newAdmin.msg.email = 'Debe ingresar un correo electronico.';
            error = true;
        }

        if(admin.password1 !== admin.password2){
            newAdmin.error.password1 = true;
            newAdmin.msg.password1 = 'Las contraseñas no coinciden';
            newAdmin.error.password2 = true;
            newAdmin.msg.password2 = 'Las contraseñas no coinciden';
            error = true;
        }

        setAdmin(newAdmin);

        if(!error) {
            const data = {
                _id: admin._id,
                nombre: admin.nombre,
                email: admin.email,
                password: admin.password1
            }
            httpPost('/api/v1/admin-user', data).then(
                resp => {
                    setShowEditModal(false);
                    setShowNewModal(false);
                    getAdmins();
                }
            ).catch(
                error => {
                    console.log(error);
                    setShowEditModal(false);
                    setShowNewModal(false);
                }
            );
        }
    }

    const handleRemoveAdmin = () => {
        httpDelete('/api/v1/admin-user/' + admin._id).then(
            resp => {
                setShowRemoveModal(false);
                getAdmins();
            }
        ).catch(
            error => {
                console.log(error);
                setShowRemoveModal(false);
            }
        );
    }

    return(
        <div className='page-container'>
            <Title>Administracion de usuarios administradores</Title>
            <div className='page-body'>
            </div>
            <div>
                { admins.loading ?
                <div className='loading-spinner'>
                        <Spinner animation="border" variant="primary" />
                </div>
                :
                <div>
                    <div style={{ width: '100%', textAlign: 'right' }}>
                        <Button type="button" onClick={handleNew} >Nuevo...</Button>
                    </div>
                    <table className='info-table'>
                        <thead>
                            <tr>
                                <th>NOMBRE</th>
                                <th>CORREO ELECTRÓNICO</th>
                                <th>FECHA DE CREACION</th>
                                <th>MODIFICAR</th>
                                <th>ELIMINAR</th>
                            </tr>
                        </thead>
                        <tbody>
                            { 
                                admins.data.map(
                                    admin => {
                                        return(
                                            <tr key={admin.id}>
                                                <td>{ admin.nombre }</td>
                                                <td>{ admin.email }</td>
                                                <td>{ admin.createAt }</td>
                                                <td><div className='visible-icon-container'><Link style={{ color: 'black' }} onClick={(e) => { handleShowEdit(e, admin) }}><FontAwesomeIcon className='view-icon' icon={faEdit} /></Link></div></td>
                                                <td><div className='pdf-icon-container'><Link style={{ color: 'red' }} onClick={(e) => { handleRemove(e, admin) }} ><FontAwesomeIcon className='view-icon' icon={faTrashAlt} /></Link></div></td>
                                            </tr>
                                        );
                                    }
                                )
                            }
                        </tbody>
                    </table>
                </div>
                }
            </div>
            <ConfirmModal title='Eliminar usuario admin' show={ showRemoveModal } onAccept={ handleRemoveAdmin } onClose={ closeRemoveModal }>¿Desea eliminar el usuario: { admin.nombre }?</ConfirmModal>
            <EditModal title='Modificar usuario administrador' show={ showEditModal } onAccept={ handleEdit } onClose={ closeEditModal } admin={admin} onChange={handleChange} />
            <EditModal title='Crear usuario administrador' show={ showNewModal } onAccept={ handleSave } onClose={ closeNewModal } admin={admin} onChange={handleChange} />
        </div>
    );
}

export default Admins;