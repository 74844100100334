import React, { useState, useEffect } from 'react';
import './AdminLogin.css';
import { httpPost } from '../../http';
import AdminLoginView from './AdminLogin';

import { connect } from 'react-redux';
import AppReducer from '../../redux/reducers/AppReducer';
import AdminReducer from '../../redux/reducers/AdminReducer';
import { doAdminLoginAction, cleanAdminDataAction } from '../../redux/actions/AdminActions';

const AdminLogin = ({ history, doAdminLoginAction, cleanAdminDataAction, AdminReducer }) => {

    const [ login, setLogin ] = useState({
        email: '',
        password: '',
        error: false,
        errorMsg: ''
    });

    useEffect(() => {
        cleanAdminDataAction();
    }, []);

    const handleChange = (e) => {
        setLogin({
            ...login,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await doAdminLoginAction({ email: login.email, password: login.password });
        if (res){
            history.push('/admin');
        }
    }

    return(
        <AdminLoginView login={login} onSubmit={handleSubmit} onChange={handleChange} AdminReducer={AdminReducer}/>
    );
}

const mapStateToProps = ({ AppReducer, AdminReducer }) => {
    return {
        AppReducer,
        AdminReducer
    };
};

const mapDispatchToProps = {
    doAdminLoginAction,
    cleanAdminDataAction
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogin);