import React, { useState, useEffect } from 'react';
import './AdminLogin.css';
import InfoBox from '../../components/infoBox/InfoBox';
import Button from '../../components/button/Button';
import { Alert, Spinner } from 'react-bootstrap';
import { httpPost } from '../../http';

const TextBox = (props) => {
    return(
        <input className='admin-login-text-box' type={props.type} name={props.name} value={props.value} onChange={props.onChange} required />
    );
}

const AdminLoginView = ({ login, onSubmit, onChange, AdminReducer }) => {

    return(
        <div>
            <div className='admin-login-content'>
                <div className='admin-login-body'>
                    { AdminReducer.error.state &&
                        <Alert variant='danger'>{ AdminReducer.error.msg }</Alert>
                    }
                    <div>
                        <h2 className='admin-login-h2'>Ingreso a la administración de la plataforma</h2>
                    </div>
                    <div>
                        <p className='admin-login-p'>Ingrese su Correo electrónico y su contraseña para acceder</p>
                    </div>
                    <form className='admin-login-form' onSubmit={ onSubmit }>
                        <div className='admin-login-field'>
                            <div className='admin-login-field-name'>Correo electrónico:</div>
                            <TextBox type='text' name='email' value={login.email} onChange={onChange} />
                        </div>
                        <div className='admin-login-field'>
                            <div className='admin-login-field-name'>Contraseña:</div>
                            <TextBox type='password' name='password' value={login.password} onChange={onChange} />
                        </div>
                        <br />
                        <Button type='submit'>Ingresar</Button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AdminLoginView;