import React, { useState, useEffect } from 'react';
import FacturasView from './FacturasView';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { formatDateMin } from '../../misc/index';
import { httpGet } from '../../http';
import { formatDateMin2 } from '../../misc';

import { connect } from 'react-redux';
import AppReducer from '../../redux/reducers/AppReducer';
import InvoicesReducer from '../../redux/reducers/InvoicesReducer';
import { getInvoicesDataAction } from '../../redux/actions/InvoicesActions';

const Facturas = ({ history,  AppReducer, InvoicesReducer, getInvoicesDataAction }) => {

    const [ request, setRequest ] = useState({
        loading: false,
        error: false,
        errorMsg: ''
    });

    const [ dates, setDates ] = useState({
        date1: InvoicesReducer.date1,
        date2: InvoicesReducer.date2
    });

    const [ facturas, setFacturas ] = useState([]);

    useEffect(() => {
        //getFacturas(newEstado.proveedor.nit);
    }, []);    

    const getFacturas = async () => {
        getInvoicesDataAction(dates);
    }

    const handleViewClick = async (e, factura) => {
        e.preventDefault();
        const egreso = {
            idCo: factura.docFCCo,
            tipoDoc: factura.docFCTipo,
            numDoc: factura.docFCNum
        };

        console.log(egreso);

        history.push({ pathname: '/portal/egreso-detalle', state: { egreso: egreso } });
        
    }

    const handleChange = (e) => {
        setDates({
            ...dates,
            [e.target.name]: e.target.value
        });
    }

    const handleClick = () => {
        if((dates.date1.length > 0) && (dates.date2.length > 0)){
            getFacturas();
        } else {
            alert("Por favor ingrese las fechas correctamente.")
        }
    }

    const formatData = (data) => {
        let newData = [];
        let no = 1;
        for (const reg of data){
            let newReg = {
                fecha_documento: formatDateMin(new Date(reg.fechaDoc)),
                centro_operacion: reg.co,
                documento: reg.docProv,
                documento_causacion: reg.tipoDoc + '-' + reg.numDoc,
                valor_bruto: reg.totalBruto,
                valor_neto: reg.totalNeto,
                estado: reg.estado > 0 ? 'PAGO EFECTUADO' : 'SIN PAGO'
            }
            newData.push(newReg)
        }

        return newData;
    }

    const exportToExcel = () => {
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(formatData(InvoicesReducer.invoices));
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'estado' + fileExtension);
    };

    return (
        <>
        {
        <FacturasView 
            request={request} 
            InvoicesReducer={InvoicesReducer} 
            onViewClick={handleViewClick} 
            dates={dates} 
            onChange={handleChange} 
            onClick={handleClick} 
            onExportClick={exportToExcel}
        />
        }
        </>
    )
}

const mapStateToProps = ({ AppReducer, InvoicesReducer }) => {
    return {
        AppReducer,
        InvoicesReducer
    };
};

const mapDispatchToProps = {
    getInvoicesDataAction
}

export default connect(mapStateToProps, mapDispatchToProps)(Facturas);