import React, { useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer'
import Button from '../../components/button/Button';
import { formatNumber, formatDateMin } from '../../misc/index';
import logo from '../../img/logo.png';


const EgresoPDFView = ({ egreso }) => {    
    return(
        <Document>
            <Page size="LETTER" style={styles.pagina}>
                <View style={styles.header}>
                    <Image src={logo} style={{ width: 100, marginBottom: 10 }}/>
                    <Text style={styles.title}>{ egreso.empresa.name }</Text>
                    <Text style={styles.title}>{ egreso.empresa.id }</Text>
                    <Text style={styles.title}>Calle 19 Oeste # 4a - 11</Text>
                </View>
                <View style={styles.proveedorContainer}>
                    <Text style={{ marginBottom: 10 }}>COMPROBANTE DE EGRESO</Text>
                    <Text>N.I.T.: { egreso.proveedor.nit }</Text>
                    <Text>PROVEEDOR: { egreso.proveedor.razonSocial }</Text>
                </View>
                <View style={styles.bodyContainer}>
                    <View style={styles.encabezadoContainer}>
                        <View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>Documento: </Text>
                            <Text>{ egreso.encabezado.idCo + "-" + egreso.encabezado.tipoDoc + "-" + egreso.encabezado.numDoc }</Text>
                        </View>
                        <View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>Fecha: </Text>
                            <Text>{ formatDateMin( new Date(egreso.encabezado.fechaDoc)) }</Text>
                        </View>
                        <View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>Valor: </Text>
                            <Text>${ formatNumber(egreso.encabezado.valor) }</Text>
                        </View>
                        {/*<View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>Fecha de vencimiento: </Text>
                            <Text>{ egreso.encabezado.fechaVcto }</Text>
                        </View>*/}
                        <View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>Detalle: </Text>
                            <Text>{ egreso.encabezado.notas }</Text>
                        </View>
                    </View>
                    { egreso.retenciones.length > 0 &&
                    <View style={styles.sectionContainer}>
                        <Text style={styles.subTitle2}>RETENCIONES:</Text>
                        <View style={styles.tabla}>
                            <View style={styles.th}>
                                <Text style={styles.tablaCampoTitulo}>CUENTA</Text>
                                <Text style={styles.tablaCampoTitulo}>DESCRIPCION</Text>
                                <Text style={styles.tablaCampoTitulo}>TASA</Text>
                                <Text style={styles.tablaCampoTitulo}>BASE</Text>
                                <Text style={styles.tablaCampoTitulo}>VALOR</Text>
                            </View>
                            {
                                egreso.retenciones.map(
                                    (retencion, index) => {
                                        return(
                                            <View key={index} style={styles.tr}>
                                                <Text style={styles.tablaCampo}>{ retencion.idCuenta }</Text>
                                                <Text style={styles.tablaCampo}>{ retencion.descripcion }</Text>
                                                <Text style={styles.tablaCampo}>{ formatNumber(retencion.tasa) }%</Text>
                                                <Text style={styles.tablaCampo}>${ formatNumber(retencion.valorBase) }</Text>
                                                <Text style={styles.tablaCampo}>${ formatNumber(retencion.valor) }</Text>
                                            </View>
                                        );
                                    }
                                )
                            }
                        </View>
                    </View>
                    }
                    <View style={styles.sectionContainer}>
                        <Text style={styles.subTitle2}>DETALLE:</Text>
                        <View style={styles.tabla}>
                            <View style={styles.th}>
                                <Text style={styles.tablaCampoTitulo}>C.O.</Text>
                                <Text style={styles.tablaCampoTitulo}>CUENTA</Text>
                                <Text style={styles.tablaCampoTitulo}>DOC. CRUCE</Text>
                                <Text style={styles.tablaCampoTitulo}>DEBITOS</Text>
                                <Text style={styles.tablaCampoTitulo}>CREDITOS</Text>
                            </View>
                            {
                                egreso.detalles.map(
                                    detalle => {
                                        console.log(detalle);
                                        return(
                                            <View key={detalle.id} style={styles.tr}>
                                                <Text style={styles.tablaCampo}>{ detalle.coMov }</Text>
                                                <Text style={styles.tablaCampo}>{ detalle.idCuenta }</Text>
                                                <Text style={styles.tablaCampo}>{ detalle.tipoDocProv + "-" + detalle.numDocProv }</Text>
                                                <Text style={styles.tablaCampo}>${ formatNumber(detalle.debitos) }</Text>
                                                <Text style={styles.tablaCampo}>${ formatNumber(detalle.creditos) }</Text>
                                            </View>
                                        );
                                    }
                                )
                            }
                            <View style={styles.tr}>
                                <Text style={styles.tablaCampoTotal}>TOTAL:</Text>
                                <Text style={styles.tablaCampoTotal}></Text>
                                <Text style={styles.tablaCampo}></Text>
                                <Text style={styles.tablaCampo}>${ formatNumber(egreso.encabezado.debitos) }</Text>
                                <Text style={styles.tablaCampo}>${ formatNumber(egreso.encabezado.creditos) }</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.pageNumber}>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
                </View>
            </Page>
        </Document>
    );
}



const styles = StyleSheet.create({
    block: {
        borderWidth: 2,
        padding: 5
    },
    field: {
        flexDirection: 'row'
    },
    fieldRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 5
    },
    header: {
        alignItems: 'flex-start',
        marginBottom: 10
    },
    title: {
        fontSize: 8,
        //marginBottom: 20   
    },
    fieldName: {
        fontSize: 10,
        fontWeight: 'ultrabold'
    },
    subTitle: {
        fontSize: 10,    
    },
    proveedorContainer: {
        fontSize: 10,
        marginBottom: 10
    },
    bodyContainer: {
        
    },
    encabezadoContainer: {
        fontSize: 10,
        marginBottom: 10
    },
    campoContainer: {
        flexDirection: 'row',
    },
    campoTitulo: {
        
    },
    tabla: {
        borderWidth: 1,
        borderRadius: 5,
        fontSize: 10
    },
    th: {
        flexDirection: 'row',
    },
    tr: {
        flexDirection: 'row',
    },
    tablaCampoTitulo: {
        backgroundColor: 'black',
        color: 'white',
        width: '100%',
        borderRightWidth: 1,
        borderRightColor: 'white',
        paddingLeft: 5
        
    },
    tablaCampo: {
        width: '100%',
        color: 'black',
        borderRightWidth: 1,
        borderRightColor: 'black',
        paddingLeft: 5,
        borderBottomWidth: 1
    },
    tablaCampoTotal: {
        width: '100%',
        color: 'black',
        paddingLeft: 5,
        borderBottomWidth: 1
    },
    pagina: {
        padding: 50
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        top: 1,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    subTitle2: {
        fontSize: 10
    },
    sectionContainer: {
        marginBottom: 10
    }
});

export default EgresoPDFView;