import React, { useState, useEffect } from 'react';
import './Login.css';
import { httpPost, httpGet } from '../../http';
import LoginView from './LoginView';
import { supplier } from '../../demo';

import { connect } from 'react-redux';
import { getSupplierDataAction } from '../../redux/actions/AppActions';
import AppReducer from '../../redux/reducers/AppReducer';

const Login = ({ history, getSupplierDataAction, AppReducer }) => {

    const { reg } = history.location.state ?? { reg: false };
    const { recovery } = history.location.state ?? { reg: false };

    const [ login, setLogin ] = useState({
        nit: '',
        password: '',
        error: false,
        errorMsg: ''
    });

    useEffect(() => {
        
        if(AppReducer.company.id.length < 1){
            history.push('/');
            return 0;
        }

    }, []);

    const handleChange = (e) => {
        setLogin({
            ...login,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLogin({
            ...login,
            error: false,
            errorMsg: ''
        });

        try{
            const proveedor = await httpPost('/api/v1/proveedor/login', login);
            if(proveedor.data){
                await getSupplierDataAction(proveedor.data);
                history.push('/portal');
                return 0;
            }
        } catch(error){
            console.log(error);
            if(error.response.status){
                if(error.response.status === 403 ){
                    setLogin({
                        ...login,
                        error: true,
                        errorMsg: 'N.I.T. o contraseña incorrectos.'
                    });
                    return 0;
                }
            }
            setLogin({
                ...login,
                error: true,
                errorMsg: 'Ocurrio un error al ingresar.'
            });
        }
    }

    return(
        <LoginView
            reg={reg} recovery={recovery} login={login} onSubmit={handleSubmit} onChange={handleChange}
        />
    );
}

const mapStateToProps = ({ AppReducer }) => {
    return {
        AppReducer
    };
};

const mapDispatchToProps = {
    getSupplierDataAction
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);