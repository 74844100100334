import React, { useState, useEffect } from 'react';
import Title from '../../../components/title/Title';
import Button from '../../../components/button/Button';
import { formatNumber } from '../../../misc';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';
import './UploadDocs.css';

const TextField = ({ label, name, value, onChange }) => 
    <div className='textfield-container'>
        <div>{label}</div>
        <input type="text" name={name} value={value} onChange={onChange} />
    </div>

const UploadDocsView = ({ loading, form, document, showFiles, onClick, onChange, onAddClick }) => {
    return(
        <div className='page-container'>
            <Title>Subir soportes de documentos</Title>
            <div className='page-body'>
                <div className='uploaddocs-datefields-container'>
                    <TextField label="CO:" name='idCo' value={form.idCo} onChange={onChange} /> 
                    <TextField label="Tipo de documento:" value={form.tipoDoc} name='tipoDoc' onChange={onChange} />
                    <TextField label="Numero de documento:" value={form.numeroDoc} name='numeroDoc' onChange={onChange} />
                    <div className='facturas-button-container'><Button onClick={onClick}>Consultar</Button></div>
                </div>
                <br />
                { loading ? 
                    <div className='loading-spinner'>
                        <Spinner animation="border" variant="primary" />
                    </div>
                :
                    document.idCo.length > 0 &&
                    <>
                        <table className='info-table'>
                            <thead>
                                <tr>
                                    <th>CO</th>
                                    <th>DOCUMENTO</th>
                                    <th>VALOR DEL DOCUMENTO</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{ document.idCo }</td>
                                    <td>{ document.tipoDoc + '-' + document.numeroDoc }</td>
                                    <td>${ formatNumber(document.valor) }</td>
                                </tr>
                            </tbody>
                        </table>                        
                        <br />
                        <div>
                            <input type='file' />
                        </div>
                        <br />
                        <div className='' style={{ width: '300px' }}><Button onClick={onAddClick}>Agregar</Button></div>
                        { showFiles &&
                        <>
                            <br />
                            <br />
                            <table className='info-table'>
                                <thead>
                                    <tr>
                                        <th>NOMBRE DEL ARCHIVO</th>
                                        <th>TAMAÑO</th>
                                        <th>ELIMINAR</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>soporte.pdf</td>
                                        <td>150 Kb</td>
                                        <td><div className='pdf-icon-container'><Link to={{ path: '' }}style={{ color: 'red' }} onClick={() => {}} ><FontAwesomeIcon className='view-icon' icon={faTrashAlt} /></Link></div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </>
                        }
                    </>
                }
            </div>
        </div>
    );
}

export default UploadDocsView;