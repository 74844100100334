import React, { useState, useEffect } from 'react';
import Title from '../../components/title/Title';
import './Home.css';

const PageTitle = (props) => {
    return(
        <div className='page-title'>
            <h2>{ props.children }</h2>
        </div>
    );
}

const HomeView = ({ AppReducer }) => {

    return(
        <div className='page-container'>
            <Title>Bienvenido al portal para proveedores</Title>
            <div className='page-body'>
                <br/>
                <div className='text-desktop'>
                    Navegue a través de las opciones de la izquierda.
                </div>
                <div className='text-mobile'>
                    Active el menu con el bontón de la parte superior derecha
                </div>
                <br />
                <table className='info-table'>
                    <thead>
                        <tr>
                            <th colSpan='2'>Información del proveedor</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><div className='table-title'>N.I.T.:</div></td>
                            <td>{ AppReducer.supplier.nit }</td>
                        </tr>
                        <tr>
                            <td><div className='table-title'>Razón social:</div></td>
                            <td>{ AppReducer.supplier.razonSocial }</td>
                        </tr>
                        <tr>
                            <td><div className='table-title'>Celular:</div></td>
                            <td>{ AppReducer.supplier.tel }</td>
                        </tr>
                        <tr>
                            <td><div className='table-title'>Correo eléctronico:</div></td>
                            <td>{ AppReducer.supplier.email }</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default HomeView;