import React from 'react';
import Title from '../../components/title/Title';
import Button from '../../components/button/Button';
import { Alert, Spinner } from 'react-bootstrap';
import './Profile.css';

const TextBox = (props) => {
    return(
        <input className='profile-text-box' type={props.type} name={props.name} value={props.value} onChange={props.onChange} required={ props.required } disabled={props.disabled} />
    );
}

const Field = (props) => {
    return(
        <div className='profile-field'>
            <div className='profile-field-name'>{ props.label }</div>
            <div><TextBox type={props.type} name={props.name} value={props.value} onChange={props.onChange} required={ props.required } disabled={ props.disabled } /></div>            
            <div className='profile-field-hint'>{ props.hint }</div>
        </div>
    );
}

const ProfileView = ({ form, onChange, onSubmit }) => {

    return(
        <div className='page-container profile-container'>
            <Title>Perfil de usuario</Title>
            { form.loading ?
            <div className='loading-spinner'>
                    <Spinner animation="border" variant="primary" />
            </div>
            :
            <div className='page-body'>
                { form.error &&
                    <>
                        <br />
                        <Alert variant='danger'>{ form.errorMsg }</Alert>
                    </>
                }
                { form.success &&
                    <>
                        <br />
                        <Alert variant='success'>El perfil fue modificado correctamente.</Alert>
                    </>
                }
                <form className='signup-form' onSubmit={onSubmit} >
                    {/*<Field type='text' label='N.I.T.' hint='NIT sin dijito de verificación' name='nit' value={form.nit} onChange={onChange} disabled={ true } />*/}
                    <Field type='text' label='N.I.T.' hint='NIT sin dijito de verificación' name='nit' value="999999999" onChange={onChange} disabled={ true } />
                    <Field type='text' label='API Key' hint='Llave para acceso para la API de reportes' name='nit' value='e10adc3949ba59abbe56e057f20f883e' onChange={onChange} disabled={ true } />
                    {/*<Field type='text' label='Razón social(*)' hint='Razón social del proveedor' name='razonSocial' value={form.razonSocial} onChange={onChange} required={ true } />*/}
                    <Field type='text' label='Razón social(*)' hint='Razón social del proveedor' name='razonSocial' value="PROVEEDOR DEMO" onChange={onChange} required={ true } />
                    <Field type='email' label='Correo electrónico(*)' hint='Dijite su correo electrónico' name='email' value={form.email} onChange={onChange} required={ true } />
                    <Field type='email' label='Repita su Correo electrónico(*)' hint='Dijite de nuevo su correo electrónico' name='email2' value={form.email2} onChange={onChange} required={ true } />
                    <Field type='text' label='Numero celular(*)' hint='Dijite su numero celular' name='cel' value={form.cel} onChange={onChange} required={ true } />
                    <Field type='password' label='Contraseña(*)' hint='Dijite una contraseña' name='password1' value={form.password1} onChange={onChange} />
                    <Field type='password' label='Repita la contraseña(*)' hint='Dijite de nuevo la contraseña' name='password2' value={form.password2} onChange={onChange} />
                    <br />
                    <div style={{ width: '150px' }}><Button type='submit'>Aceptar</Button></div>
                </form>
            </div>
            }
        </div>
    );
}

export default ProfileView;