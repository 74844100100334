import logo from './logo.svg';
import './App.css';
import WelcomeLayout from './pages/WelcomeLayout';
import HomeLayout from './pages/HomeLayout';
import AdminLayout from './pages/admin/AdminLayout';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';

function App() {
  console.log('ENV', process.env.REACT_APP_ENV);
  return (
    <div>
      { process.env.REACT_APP_ENV !== 'prod' &&
        <div className="watermark-container">
          <div className="watermark">{process.env.REACT_APP_ENV}</div>
        </div>            
      }
      <BrowserRouter basename={ process.env.REACT_APP_ENV !== 'prod' ? (process.env.REACT_APP_ENV === 'test') ? "/galerias-plaza" : "/" : "/" }>
          <Switch>
              <Route path="/admin" component={AdminLayout} />
              <Route path="/portal" component={HomeLayout} />
              <Route path="/" component={WelcomeLayout} />
          </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
