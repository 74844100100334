import React, { useState, useEffect } from 'react';
import { httpGet, httpPut, httpPost } from '../../http';
import ProfileView from './ProfileView';
import './Profile.css';

import { connect } from 'react-redux';
import AppReducer from '../../redux/reducers/AppReducer';

const Profile = ({ AppReducer }) => {

    const [ form, setForm ] = useState({
        razonSocial: '',
        nit: '',
        email: '',
        email2: '',
        cel: '',
        password1: '',
        password2: '',
        error: false,
        errorMsg: '',
        loading: false
    });

    useEffect(() => {
        setForm({
            ...form,
            loading: true
        });

        setForm({
            razonSocial: AppReducer.supplier.name,
            nit: AppReducer.supplier.id,
            email: AppReducer.supplier.email,
            email2: AppReducer.supplier.email,
            cel: AppReducer.supplier.tel,
            password1: '',
            password2: '',
            loading: false,
            success: false
        });

    }, []);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    }

    const valNit = async (nit) => {
        const val = await httpGet('/api/v1/proveedor/nit/' + nit);
        return val;
    }

    const valEmail = async (email) => {
        const val = await httpGet('/api/v1/proveedor/email/' + email);
        return val;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const oldProveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        console.log(oldProveedor);
        try{

            setForm({
                ...form,
                error: false,
                errorMsg: '',
                loading: true,
                success: false
            });
    
            if (form.email !== oldProveedor.email){
                let vEmail = await valEmail(form.email);
                if (vEmail.data.resp) {
                    setForm({
                        ...form,
                        error: true,
                        errorMsg: 'El Correo electrónico ya se encuentra registrado.',
                        loading: false
                    });
                    return 0;
                }
            }
    
            if (form.email !== form.email2){
                setForm({
                    ...form,
                    error: true,
                    errorMsg: 'Los correos electrónicos no coinciden.',
                    loading: false
                });
                return 0;
            }
    
            if (form.password1 !== form.password2){
                setForm({
                    ...form,
                    error: true,
                    errorMsg: 'Las contraseñas no coinciden.',
                    loading: false
                });
                return 0;
            }
    
            const proveedor = {
                _id: oldProveedor._id,
                nit: form.nit,
                razonSocial: form.razonSocial,
                email: form.email,
                tel: form.cel,
                password: form.password1
            }
    
            const newProveedor = await httpPost('/api/v1/proveedor', proveedor);
            if(newProveedor.data){
                console.log('PROVEEDOR CREADO');
                setForm({
                    ...form,
                    error: false,
                    errorMsg: '',
                    loading: false,
                    success: true
                });

                return 0;
            }
    
            
            
            console.log('FORMULARIO VALIDADO');
            setForm({
                ...form,
                error: false,
                errorMsg: '',
                loading: false
            });
        } catch(error){
            console.log(error);
            setForm({
                ...form,
                error: true,
                errorMsg: 'Ocurrio un error al registrarse.'
            });
        };

    }

    return(
        <ProfileView form={form} onChange={handleChange} onSubmit={handleSubmit} />
    );
}

const mapStateToProps = ({ AppReducer }) => {
    return {
        AppReducer
    };
};

export default connect(mapStateToProps)(Profile);