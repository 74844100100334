import React, { useState, useEffect } from 'react';
import { httpGet, httpPost } from '../../http';
import SignupView from './SignupView';
import './Signup.css';

const Signup = (props) => {

    const [ estado, setEstado ] = useState({
        
    });

    const [ form, setForm ] = useState({
        razonSocial: '',
        nit: '',
        email: '',
        email2: '',
        cel: '',
        password1: '',
        password2: '',
        error: false,
        errorMsg: '',
        loading: false
    });

    const [ tyc, setTyc ] = useState(false);

    useEffect(() => {
        /*if(!sessionStorage.getItem('estado')){
            props.history.push('/');
            return 0;
        }*/

        setEstado(JSON.parse(sessionStorage.getItem('estado')));

    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try{

            setForm({
                ...form,
                error: false,
                errorMsg: '',
                loading: true
            });
    
            if (await valNit(form.nit)) {
                setForm({
                    ...form,
                    error: true,
                    errorMsg: 'El N.I.T. ya se encuentra registrado.',
                    loading: false
                });
                return 0;
            }

            if (await valEmail(form.email)) {
                setForm({
                    ...form,
                    error: true,
                    errorMsg: 'El Correo electrónico ya se encuentra registrado.',
                    loading: false
                });
                return 0;
            }

            const r = await valTercero(form.nit);
            console.log("VAL TERCERO");
            console.log(r);
            if(!r){
                setForm({
                    ...form,
                    error: true,
                    errorMsg: 'El N.I.T. no se encuentra registrado en nuestro sistema.',
                    loading: false
                });
                return 0;
            }

            if (form.password1 == form.nit){
                setForm({
                    ...form,
                    error: true,
                    errorMsg: 'La contraseña no puede ser igual al NIT.',
                    loading: false
                });
                return 0;
            }

            if (form.password1 == form.email){
                setForm({
                    ...form,
                    error: true,
                    errorMsg: 'La contraseña no puede ser igual al correo eléctronico.',
                    loading: false
                });
                return 0;
            }

            if (form.password1.length < 6){
                setForm({
                    ...form,
                    error: true,
                    errorMsg: 'La contraseña debe tener minimo 6 caracteres',
                    loading: false
                });
                return 0;
            }
                
            if (form.email !== form.email2){
                setForm({
                    ...form,
                    error: true,
                    errorMsg: 'Los correos electrónicos no coinciden.',
                    loading: false
                });
                return 0;
            }
    
            if (form.password1 !== form.password2){
                setForm({
                    ...form,
                    error: true,
                    errorMsg: 'Las contraseñas no coinciden.',
                    loading: false
                });
                return 0;
            }

            const proveedor = {
                nit: form.nit,
                razonSocial: form.razonSocial,
                email: form.email,
                tel: form.cel,
                password: form.password1,
                state: true
            }
    
            const newProveedor = await httpPost('/api/v1/proveedor', proveedor);
            if(newProveedor.data){
                console.log('PROVEEDOR CREADO');
                props.history.push({ pathname: '/login', state: { reg: true } });
                return 0;
            }
    
            
            
            console.log('FORMULARIO VALIDADO');
            setForm({
                ...form,
                error: false,
                errorMsg: '',
                loading: false
            });
        } catch(error){
            setForm({
                ...form,
                error: true,
                errorMsg: 'Ocurrio un error al registrarse.'
            });
        };

    }

    const handleChange = (e) => {

        if(e.target.name === 'nit'){
            setForm({
                ...form,
                [e.target.name]: e.target.value.replace(/[^0-9]/g,'')
            });    
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value
            });
        }
    }

    const valNit = async (nit) => {
        try{
            const val = await httpGet('/api/v1/proveedor/nit/' + nit);
        } catch(error){
            if(error.response.status){
                if(error.response.status === 404 ){
                    return false;        
                }
            }
        }
        
        return true;
    }

    
    const valEmail = async (email) => {
        try{
            const val = await httpGet('/api/v1/proveedor/email/' + email);
        } catch(error){
            if(error.response.status){
                if(error.response.status === 404 ){
                    return false;        
                }
            }
        }
        return true;
    }

    const valTercero = async (nit) => {
        try{
            const tercero = await httpGet('/api/v1/terceros/nit/' + nit);
            console.log("TERCERO DATA");
            console.log(tercero.data);
            if(tercero.data){
                return true;
            }
        } catch(error){
            return false;
        }

        return false;
    }

    const handleTycLink = () => {
        window.open('http://downloads.aldetec.co/politica-proteccion-de-datos.pdf', "_blank");
    }

    return(
        <SignupView
            form={form}
            onChange={handleChange}
            onSubmit={handleSubmit}
        />
    );
}

export default Signup;