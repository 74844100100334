import React, { useState, useEffect } from 'react';
import { httpGet } from '../../http';
import HomeLayoutView from './HomeLayoutView';
import './HomeLayout.css';

import { connect } from 'react-redux';
import AppReducer from '../../redux/reducers/AppReducer';
import { getCompanyDataAction, getSupplierDataAction, cleanSupplierDataAction } from '../../redux/actions/AppActions';
import { setMenuActiveAction } from '../../redux/actions/MenuActions';

const HomeLayout = ({ AppReducer, getCompanyDataAction, getSupplierDataAction, cleanSupplierDataAction, history, setMenuActiveAction }) => {

    const [ showLegend, setShowLegend ] = useState(false);
    const [ tiposDocumento, setTiposDocumento ] = useState([]);
   
    useEffect(() => {

        if(localStorage.getItem('nitProveedor')){
            console.log('exporer')
            explore();
        } else {
            if(AppReducer.company.id.length < 1){
                history.push('/');
                return 0;
            }
    
            if(AppReducer.supplier.nit.length < 1){
                history.push('/login');
                return 0;
            }
        }
    }, []);

    const getTiposDocumento = async () => {
        try{
            const resp = await httpGet("/api/v1/tipo-documento");
            if(resp.data) {
                setTiposDocumento(resp.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    

    const explore = async () => {
        try{
            await getCompanyDataAction();
            console.log(AppReducer.company.id.lenght);
            //if(AppReducer.company.id.lenght > 0){
            await getSupplierDataAction({ nit: localStorage.getItem("nitProveedor") });
            localStorage.removeItem("nitProveedor");
            //}
        } catch(error){
            console.log(error);
        }
    }

    

    const exit = async () => {
        await cleanSupplierDataAction();
        history.push('/');
    }

    const handleClickLegend = () => {
        setShowLegend(!showLegend);
    }

    const handleBurgerButtonClick = () => {
        setMenuActiveAction();
    }

    const goTo = (to, path) => {
        setMenuActiveAction();
        console.log("PATH", path);
        history.push({ pathname: to, state: { path: path } });
    }

    return(
        <>
            <HomeLayoutView
                AppReducer = { AppReducer }
                onClickLegend = {handleClickLegend}
                tiposDocumento = {tiposDocumento}
                showLegend={showLegend}
                exit={exit}
                onBurgerButtonClick={handleBurgerButtonClick}
                history={history}
                onMenuOptionClick={goTo}
            />
        </>
    );
}

const mapStateToProps = ({ AppReducer }) => {
    return {
        AppReducer
    };
};

const mapDispatchToProps = {
    getCompanyDataAction,
    getSupplierDataAction,
    cleanSupplierDataAction,
    setMenuActiveAction
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeLayout);
