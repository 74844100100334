import React, { useState, useEffect } from 'react';
import { PDFViewer, Document, Page } from '@react-pdf/renderer'
import Button from '../../components/button/Button';
import DocumentoFIDetallePDFView from './DocumentoFIDetallePDFView';

const DocumentoFIDetallePDF = ({ history }) => {

    useEffect(() => {

        if(!history.location.state.documento){
            history.push('/portal');
            return 0;
        }

    }, []);

    return(
        <>
            <div>
                <Button onClick={ () => { history.goBack() } } >Volver</Button>
            </div>
            <br />
            <PDFViewer style={{ width: '100%', height: '100vh' }}>
                <DocumentoFIDetallePDFView documento={ history.location.state.documento } />
            </PDFViewer>
        </>
    );
}

export default DocumentoFIDetallePDF;