
export const documents = JSON.parse(`[
    {
      "rowid": "22662223",
      "idCo": "ADM",
      "tipoDoc": "PEL",
      "tipoDocDescripcion": "PAGOS ELECTRONICOS",
      "numeroDoc": "23740",
      "fechaDoc": "2023-04-25T05:00:00.000Z",
      "notas": "BRINKS FRUVER 25 ABRIL",
      "cuenta": "",
      "cuentaBancaria": "",
      "valor": 133085091
    },
    {
      "rowid": "22512440",
      "idCo": "ADM",
      "tipoDoc": "PEL",
      "tipoDocDescripcion": "PAGOS ELECTRONICOS",
      "numeroDoc": "23460",
      "fechaDoc": "2023-04-18T05:00:00.000Z",
      "notas": "BRINKS FRUVER 18 ABRIL",
      "cuenta": "",
      "cuentaBancaria": "",
      "valor": 101697281
    },
    {
      "rowid": "22201814",
      "idCo": "ADM",
      "tipoDoc": "PEL",
      "tipoDocDescripcion": "PAGOS ELECTRONICOS",
      "numeroDoc": "22958",
      "fechaDoc": "2023-04-11T05:00:00.000Z",
      "notas": "BRINKS FRUVER 11 ABRIL",
      "cuenta": "",
      "cuentaBancaria": "",
      "valor": 107457249
    },
    {
      "rowid": "21798863",
      "idCo": "ADM",
      "tipoDoc": "PEL",
      "tipoDocDescripcion": "PAGOS ELECTRONICOS",
      "numeroDoc": "22763",
      "fechaDoc": "2023-04-04T05:00:00.000Z",
      "notas": "BRINKS FRUVER 04 ABRIL",
      "cuenta": "",
      "cuentaBancaria": "",
      "valor": 115708674
    },
    {
      "rowid": "21354651",
      "idCo": "ADM",
      "tipoDoc": "PEL",
      "tipoDocDescripcion": "PAGOS ELECTRONICOS",
      "numeroDoc": "22533",
      "fechaDoc": "2023-03-28T05:00:00.000Z",
      "notas": "BRINKS FRUVER 28 MARZO",
      "cuenta": "",
      "cuentaBancaria": "",
      "valor": 119972335
    },
    {
      "rowid": "20949500",
      "idCo": "ADM",
      "tipoDoc": "PEL",
      "tipoDocDescripcion": "PAGOS ELECTRONICOS",
      "numeroDoc": "22284",
      "fechaDoc": "2023-03-21T05:00:00.000Z",
      "notas": "BRINKS FRUVER 21 MARZO",
      "cuenta": "",
      "cuentaBancaria": "",
      "valor": 110737201
    },
    {
      "rowid": "20539954",
      "idCo": "ADM",
      "tipoDoc": "PEL",
      "tipoDocDescripcion": "PAGOS ELECTRONICOS",
      "numeroDoc": "22051",
      "fechaDoc": "2023-03-14T05:00:00.000Z",
      "notas": "BRINKS FRUVER 14 MARZO",
      "cuenta": "",
      "cuentaBancaria": "",
      "valor": 114745821
    },
    {
      "rowid": "20102020",
      "idCo": "ADM",
      "tipoDoc": "PEL",
      "tipoDocDescripcion": "PAGOS ELECTRONICOS",
      "numeroDoc": "21680",
      "fechaDoc": "2023-03-07T05:00:00.000Z",
      "notas": "BRINKS FRUVER 07 MARZO",
      "cuenta": "",
      "cuentaBancaria": "",
      "valor": 108705784
    },
    {
      "rowid": "19648229",
      "idCo": "ADM",
      "tipoDoc": "PEL",
      "tipoDocDescripcion": "PAGOS ELECTRONICOS",
      "numeroDoc": "21418",
      "fechaDoc": "2023-02-28T05:00:00.000Z",
      "notas": "BRINKS FRUVER 28 FEBRERO",
      "cuenta": "",
      "cuentaBancaria": "",
      "valor": 87190398
    },
    {
      "rowid": "19257913",
      "idCo": "ADM",
      "tipoDoc": "PEL",
      "tipoDocDescripcion": "PAGOS ELECTRONICOS",
      "numeroDoc": "21107",
      "fechaDoc": "2023-02-21T05:00:00.000Z",
      "notas": "BRINKS FRUVER 21 FEBRERO",
      "cuenta": "",
      "cuentaBancaria": "",
      "valor": 101222886
    }
  ]`);

export const inventories = JSON.parse(`[
    {
      "year": "2023",
      "month": "1",
      "data": [
        {
          "codBar": "1111111111111",
          "idItem": "111111",
          "um": "UNIDAD",
          "stockQty": 10,
          "stockCost": 90000
        },
        {
          "codBar": "2222222222222",
          "idItem": "222222",
          "um": "UNIDAD",
          "stockQty": 20,
          "stockCost": 80000
        },
        {
          "codBar": "3333333333333",
          "idItem": "333333",
          "um": "UNIDAD",
          "stockQty": 30,
          "stockCost": 70000
        },
        {
          "codBar": "4444444444444",
          "idItem": "444444",
          "um": "UNIDAD",
          "stockQty": 40,
          "stockCost": 60000
        },
        {
          "codBar": "5555555555555",
          "idItem": "555555",
          "um": "UNIDAD",
          "stockQty": 50,
          "stockCost": 50000
        },
        {
          "codBar": "6666666666666",
          "idItem": "666666",
          "um": "UNIDAD",
          "stockQty": 60,
          "stockCost": 40000
        },
        {
          "codBar": "7777777777777",
          "idItem": "777777",
          "um": "UNIDAD",
          "stockQty": 70,
          "stockCost": 30000
        },
        {
          "codBar": "8888888888888",
          "idItem": "888888",
          "um": "UNIDAD",
          "stockQty": 80,
          "stockCost": 20000
        },
        {
          "codBar": "9999999999999",
          "idItem": "999999",
          "um": "UNIDAD",
          "stockQty": 90,
          "stockCost": 10000
        }
      ]
    },
    {
      "year": "2023",
      "month": "2",
      "data": [
        {
          "codBar": "1111111111111",
          "idItem": "111111",
          "um": "UNIDAD",
          "stockQty": 90,
          "stockCost": 10000
        },
        {
          "codBar": "2222222222222",
          "idItem": "222222",
          "um": "UNIDAD",
          "stockQty": 80,
          "stockCost": 20000
        },
        {
          "codBar": "3333333333333",
          "idItem": "333333",
          "um": "UNIDAD",
          "stockQty": 70,
          "stockCost": 30000
        },
        {
          "codBar": "4444444444444",
          "idItem": "444444",
          "um": "UNIDAD",
          "stockQty":  60,
          "stockCost": 40000
        },
        {
          "codBar": "5555555555555",
          "idItem": "555555",
          "um": "UNIDAD",
          "stockQty": 50,
          "stockCost": 50000
        },
        {
          "codBar": "6666666666666",
          "idItem": "666666",
          "um": "UNIDAD",
          "stockQty": 40,
          "stockCost": 60000
        },
        {
          "codBar": "7777777777777",
          "idItem": "777777",
          "um": "UNIDAD",
          "stockQty": 30,
          "stockCost": 70000
        },
        {
          "codBar": "8888888888888",
          "idItem": "888888",
          "um": "UNIDAD",
          "stockQty": 20,
          "stockCost": 80000
        },
        {
          "codBar": "9999999999999",
          "idItem": "999999",
          "um": "UNIDAD",
          "stockQty": 10,
          "stockCost": 90000
        }
      ]
    },
    {
      "year": "2023",
      "month": "3",
      "data": [
        {
          "codBar": "1111111111111",
          "idItem": "111111",
          "um": "UNIDAD",
          "stockQty": 10,
          "stockCost": 90000
        },
        {
          "codBar": "2222222222222",
          "idItem": "222222",
          "um": "UNIDAD",
          "stockQty": 20,
          "stockCost": 80000
        },
        {
          "codBar": "3333333333333",
          "idItem": "333333",
          "um": "UNIDAD",
          "stockQty": 30,
          "stockCost": 70000
        },
        {
          "codBar": "4444444444444",
          "idItem": "444444",
          "um": "UNIDAD",
          "stockQty": 40,
          "stockCost": 60000
        },
        {
          "codBar": "5555555555555",
          "idItem": "555555",
          "um": "UNIDAD",
          "stockQty": 50,
          "stockCost": 50000
        },
        {
          "codBar": "6666666666666",
          "idItem": "666666",
          "um": "UNIDAD",
          "stockQty": 60,
          "stockCost": 40000
        },
        {
          "codBar": "7777777777777",
          "idItem": "777777",
          "um": "UNIDAD",
          "stockQty": 70,
          "stockCost": 30000
        },
        {
          "codBar": "8888888888888",
          "idItem": "888888",
          "um": "UNIDAD",
          "stockQty": 80,
          "stockCost": 20000
        },
        {
          "codBar": "9999999999999",
          "idItem": "999999",
          "um": "UNIDAD",
          "stockQty": 90,
          "stockCost": 10000
        }
      ]
    },
    {
      "year": "2023",
      "month": "4",
      "data": [
        {
          "codBar": "1111111111111",
          "idItem": "111111",
          "um": "UNIDAD",
          "stockQty": 90,
          "stockCost": 10000
        },
        {
          "codBar": "2222222222222",
          "idItem": "222222",
          "um": "UNIDAD",
          "stockQty": 80,
          "stockCost": 20000
        },
        {
          "codBar": "3333333333333",
          "idItem": "333333",
          "um": "UNIDAD",
          "stockQty": 70,
          "stockCost": 30000
        },
        {
          "codBar": "4444444444444",
          "idItem": "444444",
          "um": "UNIDAD",
          "stockQty":  60,
          "stockCost": 40000
        },
        {
          "codBar": "5555555555555",
          "idItem": "555555",
          "um": "UNIDAD",
          "stockQty": 50,
          "stockCost": 50000
        },
        {
          "codBar": "6666666666666",
          "idItem": "666666",
          "um": "UNIDAD",
          "stockQty": 40,
          "stockCost": 60000
        },
        {
          "codBar": "7777777777777",
          "idItem": "777777",
          "um": "UNIDAD",
          "stockQty": 30,
          "stockCost": 70000
        },
        {
          "codBar": "8888888888888",
          "idItem": "888888",
          "um": "UNIDAD",
          "stockQty": 20,
          "stockCost": 80000
        },
        {
          "codBar": "9999999999999",
          "idItem": "999999",
          "um": "UNIDAD",
          "stockQty": 10,
          "stockCost": 90000
        }
      ]
    },
    {
      "year": "2023",
      "month": "5",
      "data": [
        {
          "codBar": "1111111111111",
          "idItem": "111111",
          "um": "UNIDAD",
          "stockQty": 10,
          "stockCost": 90000
        },
        {
          "codBar": "2222222222222",
          "idItem": "222222",
          "um": "UNIDAD",
          "stockQty": 20,
          "stockCost": 80000
        },
        {
          "codBar": "3333333333333",
          "idItem": "333333",
          "um": "UNIDAD",
          "stockQty": 30,
          "stockCost": 70000
        },
        {
          "codBar": "4444444444444",
          "idItem": "444444",
          "um": "UNIDAD",
          "stockQty": 40,
          "stockCost": 60000
        },
        {
          "codBar": "5555555555555",
          "idItem": "555555",
          "um": "UNIDAD",
          "stockQty": 50,
          "stockCost": 50000
        },
        {
          "codBar": "6666666666666",
          "idItem": "666666",
          "um": "UNIDAD",
          "stockQty": 60,
          "stockCost": 40000
        },
        {
          "codBar": "7777777777777",
          "idItem": "777777",
          "um": "UNIDAD",
          "stockQty": 70,
          "stockCost": 30000
        },
        {
          "codBar": "8888888888888",
          "idItem": "888888",
          "um": "UNIDAD",
          "stockQty": 80,
          "stockCost": 20000
        },
        {
          "codBar": "9999999999999",
          "idItem": "999999",
          "um": "UNIDAD",
          "stockQty": 90,
          "stockCost": 10000
        }
      ]
    },
    {
      "year": "2023",
      "month": "6",
      "data": [
        {
          "codBar": "1111111111111",
          "idItem": "111111",
          "um": "UNIDAD",
          "stockQty": 90,
          "stockCost": 10000
        },
        {
          "codBar": "2222222222222",
          "idItem": "222222",
          "um": "UNIDAD",
          "stockQty": 80,
          "stockCost": 20000
        },
        {
          "codBar": "3333333333333",
          "idItem": "333333",
          "um": "UNIDAD",
          "stockQty": 70,
          "stockCost": 30000
        },
        {
          "codBar": "4444444444444",
          "idItem": "444444",
          "um": "UNIDAD",
          "stockQty":  60,
          "stockCost": 40000
        },
        {
          "codBar": "5555555555555",
          "idItem": "555555",
          "um": "UNIDAD",
          "stockQty": 50,
          "stockCost": 50000
        },
        {
          "codBar": "6666666666666",
          "idItem": "666666",
          "um": "UNIDAD",
          "stockQty": 40,
          "stockCost": 60000
        },
        {
          "codBar": "7777777777777",
          "idItem": "777777",
          "um": "UNIDAD",
          "stockQty": 30,
          "stockCost": 70000
        },
        {
          "codBar": "8888888888888",
          "idItem": "888888",
          "um": "UNIDAD",
          "stockQty": 20,
          "stockCost": 80000
        },
        {
          "codBar": "9999999999999",
          "idItem": "999999",
          "um": "UNIDAD",
          "stockQty": 10,
          "stockCost": 90000
        }
      ]
    },
    {
      "year": "2023",
      "month": "7",
      "data": [
        {
          "codBar": "1111111111111",
          "idItem": "111111",
          "um": "UNIDAD",
          "stockQty": 10,
          "stockCost": 90000
        },
        {
          "codBar": "2222222222222",
          "idItem": "222222",
          "um": "UNIDAD",
          "stockQty": 20,
          "stockCost": 80000
        },
        {
          "codBar": "3333333333333",
          "idItem": "333333",
          "um": "UNIDAD",
          "stockQty": 30,
          "stockCost": 70000
        },
        {
          "codBar": "4444444444444",
          "idItem": "444444",
          "um": "UNIDAD",
          "stockQty": 40,
          "stockCost": 60000
        },
        {
          "codBar": "5555555555555",
          "idItem": "555555",
          "um": "UNIDAD",
          "stockQty": 50,
          "stockCost": 50000
        },
        {
          "codBar": "6666666666666",
          "idItem": "666666",
          "um": "UNIDAD",
          "stockQty": 60,
          "stockCost": 40000
        },
        {
          "codBar": "7777777777777",
          "idItem": "777777",
          "um": "UNIDAD",
          "stockQty": 70,
          "stockCost": 30000
        },
        {
          "codBar": "8888888888888",
          "idItem": "888888",
          "um": "UNIDAD",
          "stockQty": 80,
          "stockCost": 20000
        },
        {
          "codBar": "9999999999999",
          "idItem": "999999",
          "um": "UNIDAD",
          "stockQty": 90,
          "stockCost": 10000
        }
      ]
    },
    {
      "year": "2023",
      "month": "8",
      "data": [
        {
          "codBar": "1111111111111",
          "idItem": "111111",
          "um": "UNIDAD",
          "stockQty": 90,
          "stockCost": 10000
        },
        {
          "codBar": "2222222222222",
          "idItem": "222222",
          "um": "UNIDAD",
          "stockQty": 80,
          "stockCost": 20000
        },
        {
          "codBar": "3333333333333",
          "idItem": "333333",
          "um": "UNIDAD",
          "stockQty": 70,
          "stockCost": 30000
        },
        {
          "codBar": "4444444444444",
          "idItem": "444444",
          "um": "UNIDAD",
          "stockQty":  60,
          "stockCost": 40000
        },
        {
          "codBar": "5555555555555",
          "idItem": "555555",
          "um": "UNIDAD",
          "stockQty": 50,
          "stockCost": 50000
        },
        {
          "codBar": "6666666666666",
          "idItem": "666666",
          "um": "UNIDAD",
          "stockQty": 40,
          "stockCost": 60000
        },
        {
          "codBar": "7777777777777",
          "idItem": "777777",
          "um": "UNIDAD",
          "stockQty": 30,
          "stockCost": 70000
        },
        {
          "codBar": "8888888888888",
          "idItem": "888888",
          "um": "UNIDAD",
          "stockQty": 20,
          "stockCost": 80000
        },
        {
          "codBar": "9999999999999",
          "idItem": "999999",
          "um": "UNIDAD",
          "stockQty": 10,
          "stockCost": 90000
        }
      ]
    },
    {
      "year": "2023",
      "month": "9",
      "data": [
        {
          "codBar": "1111111111111",
          "idItem": "111111",
          "um": "UNIDAD",
          "stockQty": 10,
          "stockCost": 90000
        },
        {
          "codBar": "2222222222222",
          "idItem": "222222",
          "um": "UNIDAD",
          "stockQty": 20,
          "stockCost": 80000
        },
        {
          "codBar": "3333333333333",
          "idItem": "333333",
          "um": "UNIDAD",
          "stockQty": 30,
          "stockCost": 70000
        },
        {
          "codBar": "4444444444444",
          "idItem": "444444",
          "um": "UNIDAD",
          "stockQty": 40,
          "stockCost": 60000
        },
        {
          "codBar": "5555555555555",
          "idItem": "555555",
          "um": "UNIDAD",
          "stockQty": 50,
          "stockCost": 50000
        },
        {
          "codBar": "6666666666666",
          "idItem": "666666",
          "um": "UNIDAD",
          "stockQty": 60,
          "stockCost": 40000
        },
        {
          "codBar": "7777777777777",
          "idItem": "777777",
          "um": "UNIDAD",
          "stockQty": 70,
          "stockCost": 30000
        },
        {
          "codBar": "8888888888888",
          "idItem": "888888",
          "um": "UNIDAD",
          "stockQty": 80,
          "stockCost": 20000
        },
        {
          "codBar": "9999999999999",
          "idItem": "999999",
          "um": "UNIDAD",
          "stockQty": 90,
          "stockCost": 10000
        }
      ]
    },
    {
      "year": "2023",
      "month": "10",
      "data": [
        {
          "codBar": "1111111111111",
          "idItem": "111111",
          "um": "UNIDAD",
          "stockQty": 90,
          "stockCost": 10000
        },
        {
          "codBar": "2222222222222",
          "idItem": "222222",
          "um": "UNIDAD",
          "stockQty": 80,
          "stockCost": 20000
        },
        {
          "codBar": "3333333333333",
          "idItem": "333333",
          "um": "UNIDAD",
          "stockQty": 70,
          "stockCost": 30000
        },
        {
          "codBar": "4444444444444",
          "idItem": "444444",
          "um": "UNIDAD",
          "stockQty":  60,
          "stockCost": 40000
        },
        {
          "codBar": "5555555555555",
          "idItem": "555555",
          "um": "UNIDAD",
          "stockQty": 50,
          "stockCost": 50000
        },
        {
          "codBar": "6666666666666",
          "idItem": "666666",
          "um": "UNIDAD",
          "stockQty": 40,
          "stockCost": 60000
        },
        {
          "codBar": "7777777777777",
          "idItem": "777777",
          "um": "UNIDAD",
          "stockQty": 30,
          "stockCost": 70000
        },
        {
          "codBar": "8888888888888",
          "idItem": "888888",
          "um": "UNIDAD",
          "stockQty": 20,
          "stockCost": 80000
        },
        {
          "codBar": "9999999999999",
          "idItem": "999999",
          "um": "UNIDAD",
          "stockQty": 10,
          "stockCost": 90000
        }
      ]
    },
    {
      "year": "2023",
      "month": "11",
      "data": [
        {
          "codBar": "1111111111111",
          "idItem": "111111",
          "um": "UNIDAD",
          "stockQty": 10,
          "stockCost": 90000
        },
        {
          "codBar": "2222222222222",
          "idItem": "222222",
          "um": "UNIDAD",
          "stockQty": 20,
          "stockCost": 80000
        },
        {
          "codBar": "3333333333333",
          "idItem": "333333",
          "um": "UNIDAD",
          "stockQty": 30,
          "stockCost": 70000
        },
        {
          "codBar": "4444444444444",
          "idItem": "444444",
          "um": "UNIDAD",
          "stockQty": 40,
          "stockCost": 60000
        },
        {
          "codBar": "5555555555555",
          "idItem": "555555",
          "um": "UNIDAD",
          "stockQty": 50,
          "stockCost": 50000
        },
        {
          "codBar": "6666666666666",
          "idItem": "666666",
          "um": "UNIDAD",
          "stockQty": 60,
          "stockCost": 40000
        },
        {
          "codBar": "7777777777777",
          "idItem": "777777",
          "um": "UNIDAD",
          "stockQty": 70,
          "stockCost": 30000
        },
        {
          "codBar": "8888888888888",
          "idItem": "888888",
          "um": "UNIDAD",
          "stockQty": 80,
          "stockCost": 20000
        },
        {
          "codBar": "9999999999999",
          "idItem": "999999",
          "um": "UNIDAD",
          "stockQty": 90,
          "stockCost": 10000
        }
      ]
    },
    {
      "year": "2023",
      "month": "12",
      "data": [
        {
          "codBar": "1111111111111",
          "idItem": "111111",
          "um": "UNIDAD",
          "stockQty": 90,
          "stockCost": 10000
        },
        {
          "codBar": "2222222222222",
          "idItem": "222222",
          "um": "UNIDAD",
          "stockQty": 80,
          "stockCost": 20000
        },
        {
          "codBar": "3333333333333",
          "idItem": "333333",
          "um": "UNIDAD",
          "stockQty": 70,
          "stockCost": 30000
        },
        {
          "codBar": "4444444444444",
          "idItem": "444444",
          "um": "UNIDAD",
          "stockQty":  60,
          "stockCost": 40000
        },
        {
          "codBar": "5555555555555",
          "idItem": "555555",
          "um": "UNIDAD",
          "stockQty": 50,
          "stockCost": 50000
        },
        {
          "codBar": "6666666666666",
          "idItem": "666666",
          "um": "UNIDAD",
          "stockQty": 40,
          "stockCost": 60000
        },
        {
          "codBar": "7777777777777",
          "idItem": "777777",
          "um": "UNIDAD",
          "stockQty": 30,
          "stockCost": 70000
        },
        {
          "codBar": "8888888888888",
          "idItem": "888888",
          "um": "UNIDAD",
          "stockQty": 20,
          "stockCost": 80000
        },
        {
          "codBar": "9999999999999",
          "idItem": "999999",
          "um": "UNIDAD",
          "stockQty": 10,
          "stockCost": 90000
        }
      ]
    }
  ]`);

export const sales = JSON.parse(`[
    {
      "year": "2023",
      "month": "1",
      "data": [
        {
          "co": "001",
          "codBar": "1111111111111",
          "idItem": "111111",
          "um": "UNIDAD",
          "saleQty": 10,
          "saleCost": 90000
        },
        {
          "co": "001",
          "codBar": "2222222222222",
          "idItem": "222222",
          "um": "UNIDAD",
          "saleQty": 20,
          "saleCost": 80000
        },
        {
          "co": "001",
          "codBar": "3333333333333",
          "idItem": "333333",
          "um": "UNIDAD",
          "saleQty": 30,
          "saleCost": 70000
        },
        {
          "co": "001",
          "codBar": "4444444444444",
          "idItem": "444444",
          "um": "UNIDAD",
          "saleQty": 40,
          "saleCost": 60000
        },
        {
          "co": "001",
          "codBar": "5555555555555",
          "idItem": "555555",
          "um": "UNIDAD",
          "saleQty": 50,
          "saleCost": 50000
        },
        {
          "co": "001",
          "codBar": "6666666666666",
          "idItem": "666666",
          "um": "UNIDAD",
          "saleQty": 60,
          "saleCost": 40000
        },
        {
          "co": "001",
          "codBar": "7777777777777",
          "idItem": "777777",
          "um": "UNIDAD",
          "saleQty": 70,
          "saleCost": 30000
        },
        {
          "co": "001",
          "codBar": "8888888888888",
          "idItem": "888888",
          "um": "UNIDAD",
          "saleQty": 80,
          "saleCost": 20000
        },
        {
          "co": "001",
          "codBar": "9999999999999",
          "idItem": "999999",
          "um": "UNIDAD",
          "saleQty": 90,
          "saleCost": 10000
        }
      ]
    },
    {
      "year": "2023",
      "month": "2",
      "data": [
        {
          "co": "001",
          "codBar": "1111111111111",
          "idItem": "111111",
          "um": "UNIDAD",
          "saleQty": 90,
          "saleCost": 10000
        },
        {
          "co": "001",
          "codBar": "2222222222222",
          "idItem": "222222",
          "um": "UNIDAD",
          "saleQty": 80,
          "saleCost": 20000
        },
        {
          "co": "001",
          "codBar": "3333333333333",
          "idItem": "333333",
          "um": "UNIDAD",
          "saleQty": 70,
          "saleCost": 30000
        },
        {
          "co": "001",
          "codBar": "4444444444444",
          "idItem": "444444",
          "um": "UNIDAD",
          "saleQty":  60,
          "saleCost": 40000
        },
        {
          "co": "001",
          "codBar": "5555555555555",
          "idItem": "555555",
          "um": "UNIDAD",
          "saleQty": 50,
          "saleCost": 50000
        },
        {
          "co": "001",
          "codBar": "6666666666666",
          "idItem": "666666",
          "um": "UNIDAD",
          "saleQty": 40,
          "saleCost": 60000
        },
        {
          "co": "001",
          "codBar": "7777777777777",
          "idItem": "777777",
          "um": "UNIDAD",
          "saleQty": 30,
          "saleCost": 70000
        },
        {
          "co": "001",
          "codBar": "8888888888888",
          "idItem": "888888",
          "um": "UNIDAD",
          "saleQty": 20,
          "saleCost": 80000
        },
        {
          "co": "001",
          "codBar": "9999999999999",
          "idItem": "999999",
          "um": "UNIDAD",
          "saleQty": 10,
          "saleCost": 90000
        }
      ]
    },
    {
      "year": "2023",
      "month": "3",
      "data": [
        {
          "co": "001",
          "codBar": "1111111111111",
          "idItem": "111111",
          "um": "UNIDAD",
          "saleQty": 10,
          "saleCost": 90000
        },
        {
          "co": "001",
          "codBar": "2222222222222",
          "idItem": "222222",
          "um": "UNIDAD",
          "saleQty": 20,
          "saleCost": 80000
        },
        {
          "co": "001",
          "codBar": "3333333333333",
          "idItem": "333333",
          "um": "UNIDAD",
          "saleQty": 30,
          "saleCost": 70000
        },
        {
          "co": "001",
          "codBar": "4444444444444",
          "idItem": "444444",
          "um": "UNIDAD",
          "saleQty": 40,
          "saleCost": 60000
        },
        {
          "co": "001",
          "codBar": "5555555555555",
          "idItem": "555555",
          "um": "UNIDAD",
          "saleQty": 50,
          "saleCost": 50000
        },
        {
          "co": "001",
          "codBar": "6666666666666",
          "idItem": "666666",
          "um": "UNIDAD",
          "saleQty": 60,
          "saleCost": 40000
        },
        {
          "co": "001",
          "codBar": "7777777777777",
          "idItem": "777777",
          "um": "UNIDAD",
          "saleQty": 70,
          "saleCost": 30000
        },
        {
          "co": "001",
          "codBar": "8888888888888",
          "idItem": "888888",
          "um": "UNIDAD",
          "saleQty": 80,
          "saleCost": 20000
        },
        {
          "co": "001",
          "codBar": "9999999999999",
          "idItem": "999999",
          "um": "UNIDAD",
          "saleQty": 90,
          "saleCost": 10000
        }
      ]
    },
    {
      "year": "2023",
      "month": "4",
      "data": [
        {
          "co": "001",
          "codBar": "1111111111111",
          "idItem": "111111",
          "um": "UNIDAD",
          "saleQty": 90,
          "saleCost": 10000
        },
        {
          "co": "001",
          "codBar": "2222222222222",
          "idItem": "222222",
          "um": "UNIDAD",
          "saleQty": 80,
          "saleCost": 20000
        },
        {
          "co": "001",
          "codBar": "3333333333333",
          "idItem": "333333",
          "um": "UNIDAD",
          "saleQty": 70,
          "saleCost": 30000
        },
        {
          "co": "001",
          "codBar": "4444444444444",
          "idItem": "444444",
          "um": "UNIDAD",
          "saleQty":  60,
          "saleCost": 40000
        },
        {
          "co": "001",
          "codBar": "5555555555555",
          "idItem": "555555",
          "um": "UNIDAD",
          "saleQty": 50,
          "saleCost": 50000
        },
        {
          "co": "001",
          "codBar": "6666666666666",
          "idItem": "666666",
          "um": "UNIDAD",
          "saleQty": 40,
          "saleCost": 60000
        },
        {
          "co": "001",
          "codBar": "7777777777777",
          "idItem": "777777",
          "um": "UNIDAD",
          "saleQty": 30,
          "saleCost": 70000
        },
        {
          "co": "001",
          "codBar": "8888888888888",
          "idItem": "888888",
          "um": "UNIDAD",
          "saleQty": 20,
          "saleCost": 80000
        },
        {
          "co": "001",
          "codBar": "9999999999999",
          "idItem": "999999",
          "um": "UNIDAD",
          "saleQty": 10,
          "saleCost": 90000
        }
      ]
    },
    {
      "year": "2023",
      "month": "5",
      "data": [
        {
          "co": "001",
          "codBar": "1111111111111",
          "idItem": "111111",
          "um": "UNIDAD",
          "saleQty": 10,
          "saleCost": 90000
        },
        {
          "co": "001",
          "codBar": "2222222222222",
          "idItem": "222222",
          "um": "UNIDAD",
          "saleQty": 20,
          "saleCost": 80000
        },
        {
          "co": "001",
          "codBar": "3333333333333",
          "idItem": "333333",
          "um": "UNIDAD",
          "saleQty": 30,
          "saleCost": 70000
        },
        {
          "co": "001",
          "codBar": "4444444444444",
          "idItem": "444444",
          "um": "UNIDAD",
          "saleQty": 40,
          "saleCost": 60000
        },
        {
          "co": "001",
          "codBar": "5555555555555",
          "idItem": "555555",
          "um": "UNIDAD",
          "saleQty": 50,
          "saleCost": 50000
        },
        {
          "co": "001",
          "codBar": "6666666666666",
          "idItem": "666666",
          "um": "UNIDAD",
          "saleQty": 60,
          "saleCost": 40000
        },
        {
          "co": "001",
          "codBar": "7777777777777",
          "idItem": "777777",
          "um": "UNIDAD",
          "saleQty": 70,
          "saleCost": 30000
        },
        {
          "co": "001",
          "codBar": "8888888888888",
          "idItem": "888888",
          "um": "UNIDAD",
          "saleQty": 80,
          "saleCost": 20000
        },
        {
          "co": "001",
          "codBar": "9999999999999",
          "idItem": "999999",
          "um": "UNIDAD",
          "saleQty": 90,
          "saleCost": 10000
        }
      ]
    },
    {
      "year": "2023",
      "month": "6",
      "data": [
        {
          "co": "001",
          "codBar": "1111111111111",
          "idItem": "111111",
          "um": "UNIDAD",
          "saleQty": 90,
          "saleCost": 10000
        },
        {
          "co": "001",
          "codBar": "2222222222222",
          "idItem": "222222",
          "um": "UNIDAD",
          "saleQty": 80,
          "saleCost": 20000
        },
        {
          "co": "001",
          "codBar": "3333333333333",
          "idItem": "333333",
          "um": "UNIDAD",
          "saleQty": 70,
          "saleCost": 30000
        },
        {
          "co": "001",
          "codBar": "4444444444444",
          "idItem": "444444",
          "um": "UNIDAD",
          "saleQty":  60,
          "saleCost": 40000
        },
        {
          "co": "001",
          "codBar": "5555555555555",
          "idItem": "555555",
          "um": "UNIDAD",
          "saleQty": 50,
          "saleCost": 50000
        },
        {
          "co": "001",
          "codBar": "6666666666666",
          "idItem": "666666",
          "um": "UNIDAD",
          "saleQty": 40,
          "saleCost": 60000
        },
        {
          "co": "001",
          "codBar": "7777777777777",
          "idItem": "777777",
          "um": "UNIDAD",
          "saleQty": 30,
          "saleCost": 70000
        },
        {
          "co": "001",
          "codBar": "8888888888888",
          "idItem": "888888",
          "um": "UNIDAD",
          "saleQty": 20,
          "saleCost": 80000
        },
        {
          "co": "001",
          "codBar": "9999999999999",
          "idItem": "999999",
          "um": "UNIDAD",
          "saleQty": 10,
          "saleCost": 90000
        }
      ]
    },
    {
      "year": "2023",
      "month": "7",
      "data": [
        {
          "co": "001",
          "codBar": "1111111111111",
          "idItem": "111111",
          "um": "UNIDAD",
          "saleQty": 10,
          "saleCost": 90000
        },
        {
          "co": "001",
          "codBar": "2222222222222",
          "idItem": "222222",
          "um": "UNIDAD",
          "saleQty": 20,
          "saleCost": 80000
        },
        {
          "co": "001",
          "codBar": "3333333333333",
          "idItem": "333333",
          "um": "UNIDAD",
          "saleQty": 30,
          "saleCost": 70000
        },
        {
          "co": "001",
          "codBar": "4444444444444",
          "idItem": "444444",
          "um": "UNIDAD",
          "saleQty": 40,
          "saleCost": 60000
        },
        {
          "co": "001",
          "codBar": "5555555555555",
          "idItem": "555555",
          "um": "UNIDAD",
          "saleQty": 50,
          "saleCost": 50000
        },
        {
          "co": "001",
          "codBar": "6666666666666",
          "idItem": "666666",
          "um": "UNIDAD",
          "saleQty": 60,
          "saleCost": 40000
        },
        {
          "co": "001",
          "codBar": "7777777777777",
          "idItem": "777777",
          "um": "UNIDAD",
          "saleQty": 70,
          "saleCost": 30000
        },
        {
          "co": "001",
          "codBar": "8888888888888",
          "idItem": "888888",
          "um": "UNIDAD",
          "saleQty": 80,
          "saleCost": 20000
        },
        {
          "co": "001",
          "codBar": "9999999999999",
          "idItem": "999999",
          "um": "UNIDAD",
          "saleQty": 90,
          "saleCost": 10000
        }
      ]
    },
    {
      "year": "2023",
      "month": "8",
      "data": [
        {
          "co": "001",
          "codBar": "1111111111111",
          "idItem": "111111",
          "um": "UNIDAD",
          "saleQty": 90,
          "saleCost": 10000
        },
        {
          "co": "001",
          "codBar": "2222222222222",
          "idItem": "222222",
          "um": "UNIDAD",
          "saleQty": 80,
          "saleCost": 20000
        },
        {
          "co": "001",
          "codBar": "3333333333333",
          "idItem": "333333",
          "um": "UNIDAD",
          "saleQty": 70,
          "saleCost": 30000
        },
        {
          "co": "001",
          "codBar": "4444444444444",
          "idItem": "444444",
          "um": "UNIDAD",
          "saleQty":  60,
          "saleCost": 40000
        },
        {
          "co": "001",
          "codBar": "5555555555555",
          "idItem": "555555",
          "um": "UNIDAD",
          "saleQty": 50,
          "saleCost": 50000
        },
        {
          "co": "001",
          "codBar": "6666666666666",
          "idItem": "666666",
          "um": "UNIDAD",
          "saleQty": 40,
          "saleCost": 60000
        },
        {
          "co": "001",
          "codBar": "7777777777777",
          "idItem": "777777",
          "um": "UNIDAD",
          "saleQty": 30,
          "saleCost": 70000
        },
        {
          "co": "001",
          "codBar": "8888888888888",
          "idItem": "888888",
          "um": "UNIDAD",
          "saleQty": 20,
          "saleCost": 80000
        },
        {
          "co": "001",
          "codBar": "9999999999999",
          "idItem": "999999",
          "um": "UNIDAD",
          "saleQty": 10,
          "saleCost": 90000
        }
      ]
    },
    {
      "year": "2023",
      "month": "9",
      "data": [
        {
          "co": "001",
          "codBar": "1111111111111",
          "idItem": "111111",
          "um": "UNIDAD",
          "saleQty": 10,
          "saleCost": 90000
        },
        {
          "co": "001",
          "codBar": "2222222222222",
          "idItem": "222222",
          "um": "UNIDAD",
          "saleQty": 20,
          "saleCost": 80000
        },
        {
          "co": "001",
          "codBar": "3333333333333",
          "idItem": "333333",
          "um": "UNIDAD",
          "saleQty": 30,
          "saleCost": 70000
        },
        {
          "co": "001",
          "codBar": "4444444444444",
          "idItem": "444444",
          "um": "UNIDAD",
          "saleQty": 40,
          "saleCost": 60000
        },
        {
          "co": "001",
          "codBar": "5555555555555",
          "idItem": "555555",
          "um": "UNIDAD",
          "saleQty": 50,
          "saleCost": 50000
        },
        {
          "co": "001",
          "codBar": "6666666666666",
          "idItem": "666666",
          "um": "UNIDAD",
          "saleQty": 60,
          "saleCost": 40000
        },
        {
          "co": "001",
          "codBar": "7777777777777",
          "idItem": "777777",
          "um": "UNIDAD",
          "saleQty": 70,
          "saleCost": 30000
        },
        {
          "co": "001",
          "codBar": "8888888888888",
          "idItem": "888888",
          "um": "UNIDAD",
          "saleQty": 80,
          "saleCost": 20000
        },
        {
          "co": "001",
          "codBar": "9999999999999",
          "idItem": "999999",
          "um": "UNIDAD",
          "saleQty": 90,
          "saleCost": 10000
        }
      ]
    },
    {
      "year": "2023",
      "month": "10",
      "data": [
        {
          "co": "001",
          "codBar": "1111111111111",
          "idItem": "111111",
          "um": "UNIDAD",
          "saleQty": 90,
          "saleCost": 10000
        },
        {
          "co": "001",
          "codBar": "2222222222222",
          "idItem": "222222",
          "um": "UNIDAD",
          "saleQty": 80,
          "saleCost": 20000
        },
        {
          "co": "001",
          "codBar": "3333333333333",
          "idItem": "333333",
          "um": "UNIDAD",
          "saleQty": 70,
          "saleCost": 30000
        },
        {
          "co": "001",
          "codBar": "4444444444444",
          "idItem": "444444",
          "um": "UNIDAD",
          "saleQty":  60,
          "saleCost": 40000
        },
        {
          "co": "001",
          "codBar": "5555555555555",
          "idItem": "555555",
          "um": "UNIDAD",
          "saleQty": 50,
          "saleCost": 50000
        },
        {
          "co": "001",
          "codBar": "6666666666666",
          "idItem": "666666",
          "um": "UNIDAD",
          "saleQty": 40,
          "saleCost": 60000
        },
        {
          "co": "001",
          "codBar": "7777777777777",
          "idItem": "777777",
          "um": "UNIDAD",
          "saleQty": 30,
          "saleCost": 70000
        },
        {
          "co": "001",
          "codBar": "8888888888888",
          "idItem": "888888",
          "um": "UNIDAD",
          "saleQty": 20,
          "saleCost": 80000
        },
        {
          "co": "001",
          "codBar": "9999999999999",
          "idItem": "999999",
          "um": "UNIDAD",
          "saleQty": 10,
          "saleCost": 90000
        }
      ]
    },
    {
      "year": "2023",
      "month": "11",
      "data": [
        {
          "co": "001",
          "codBar": "1111111111111",
          "idItem": "111111",
          "um": "UNIDAD",
          "saleQty": 10,
          "saleCost": 90000
        },
        {
          "co": "001",
          "codBar": "2222222222222",
          "idItem": "222222",
          "um": "UNIDAD",
          "saleQty": 20,
          "saleCost": 80000
        },
        {
          "co": "001",
          "codBar": "3333333333333",
          "idItem": "333333",
          "um": "UNIDAD",
          "saleQty": 30,
          "saleCost": 70000
        },
        {
          "co": "001",
          "codBar": "4444444444444",
          "idItem": "444444",
          "um": "UNIDAD",
          "saleQty": 40,
          "saleCost": 60000
        },
        {
          "co": "001",
          "codBar": "5555555555555",
          "idItem": "555555",
          "um": "UNIDAD",
          "saleQty": 50,
          "saleCost": 50000
        },
        {
          "co": "001",
          "codBar": "6666666666666",
          "idItem": "666666",
          "um": "UNIDAD",
          "saleQty": 60,
          "saleCost": 40000
        },
        {
          "co": "001",
          "codBar": "7777777777777",
          "idItem": "777777",
          "um": "UNIDAD",
          "saleQty": 70,
          "saleCost": 30000
        },
        {
          "co": "001",
          "codBar": "8888888888888",
          "idItem": "888888",
          "um": "UNIDAD",
          "saleQty": 80,
          "saleCost": 20000
        },
        {
          "co": "001",
          "codBar": "9999999999999",
          "idItem": "999999",
          "um": "UNIDAD",
          "saleQty": 90,
          "saleCost": 10000
        }
      ]
    },
    {
      "year": "2023",
      "month": "12",
      "data": [
        {
          "co": "001",
          "codBar": "1111111111111",
          "idItem": "111111",
          "um": "UNIDAD",
          "saleQty": 90,
          "saleCost": 10000
        },
        {
          "co": "001",
          "codBar": "2222222222222",
          "idItem": "222222",
          "um": "UNIDAD",
          "saleQty": 80,
          "saleCost": 20000
        },
        {
          "co": "001",
          "codBar": "3333333333333",
          "idItem": "333333",
          "um": "UNIDAD",
          "saleQty": 70,
          "saleCost": 30000
        },
        {
          "co": "001",
          "codBar": "4444444444444",
          "idItem": "444444",
          "um": "UNIDAD",
          "saleQty":  60,
          "saleCost": 40000
        },
        {
          "co": "001",
          "codBar": "5555555555555",
          "idItem": "555555",
          "um": "UNIDAD",
          "saleQty": 50,
          "saleCost": 50000
        },
        {
          "co": "001",
          "codBar": "6666666666666",
          "idItem": "666666",
          "um": "UNIDAD",
          "saleQty": 40,
          "saleCost": 60000
        },
        {
          "co": "001",
          "codBar": "7777777777777",
          "idItem": "777777",
          "um": "UNIDAD",
          "saleQty": 30,
          "saleCost": 70000
        },
        {
          "co": "001",
          "codBar": "8888888888888",
          "idItem": "888888",
          "um": "UNIDAD",
          "saleQty": 20,
          "saleCost": 80000
        },
        {
          "co": "001",
          "codBar": "9999999999999",
          "idItem": "999999",
          "um": "UNIDAD",
          "saleQty": 10,
          "saleCost": 90000
        }
      ]
    }
  ]`);

export const invoices = JSON.parse(`[
    {
      "idCia": "1",
      "rowid": "3970955",
      "rowidDocto": "22804172",
      "fecha": "2023-04-30T00:00:00-05:00",
      "fechaVcto": "2023-05-06T00:00:00-05:00",
      "fechaDocProveedor": "2023-04-28T00:00:00-05:00",
      "idCoCruce": "CED",
      "prefijoCruce": "FE",
      "tipoDocCruce": "",
      "consecutivoDocCruce": "1764",
      "valorDb": 1568879,
      "valorCr": 33507804,
      "valorFactura": 31938925,
      "valorDescuentoPP": 0,
      "valorPago": 1568879,
      "egresoRowid": null,
      "docRowid": "22804172"
    },
    {
      "idCia": "1",
      "rowid": "3968646",
      "rowidDocto": "22724864",
      "fecha": "2023-04-28T00:00:00-05:00",
      "fechaVcto": "2023-05-06T00:00:00-05:00",
      "fechaDocProveedor": "2023-04-28T00:00:00-05:00",
      "idCoCruce": "CED",
      "prefijoCruce": "FE",
      "tipoDocCruce": "",
      "consecutivoDocCruce": "1763",
      "valorDb": 0,
      "valorCr": 12986950,
      "valorFactura": 12986950,
      "valorDescuentoPP": 0,
      "valorPago": 0,
      "egresoRowid": null,
      "docRowid": "22724864"
    },
    {
      "idCia": "1",
      "rowid": "3968186",
      "rowidDocto": "22723706",
      "fecha": "2023-04-28T00:00:00-05:00",
      "fechaVcto": "2023-05-05T00:00:00-05:00",
      "fechaDocProveedor": "2023-04-27T00:00:00-05:00",
      "idCoCruce": "CED",
      "prefijoCruce": "FE",
      "tipoDocCruce": "",
      "consecutivoDocCruce": "1762",
      "valorDb": 68600,
      "valorCr": 20258660,
      "valorFactura": 20190060,
      "valorDescuentoPP": 0,
      "valorPago": 68600,
      "egresoRowid": null,
      "docRowid": "22723706"
    },
    {
      "idCia": "1",
      "rowid": "3964522",
      "rowidDocto": "22702965",
      "fecha": "2023-04-27T00:00:00-05:00",
      "fechaVcto": "2023-05-04T00:00:00-05:00",
      "fechaDocProveedor": "2023-04-26T00:00:00-05:00",
      "idCoCruce": "CED",
      "prefijoCruce": "FE",
      "tipoDocCruce": "",
      "consecutivoDocCruce": "1761",
      "valorDb": 259220,
      "valorCr": 16325750,
      "valorFactura": 16066530,
      "valorDescuentoPP": 0,
      "valorPago": 259220,
      "egresoRowid": null,
      "docRowid": "22702965"
    },
    {
      "idCia": "1",
      "rowid": "3962655",
      "rowidDocto": "22681004",
      "fecha": "2023-04-26T00:00:00-05:00",
      "fechaVcto": "2023-05-03T00:00:00-05:00",
      "fechaDocProveedor": "2023-04-25T00:00:00-05:00",
      "idCoCruce": "CED",
      "prefijoCruce": "FE",
      "tipoDocCruce": "",
      "consecutivoDocCruce": "1760",
      "valorDb": 93600,
      "valorCr": 17700550,
      "valorFactura": 17606950,
      "valorDescuentoPP": 0,
      "valorPago": 93600,
      "egresoRowid": null,
      "docRowid": "22681004"
    },
    {
      "idCia": "1",
      "rowid": "3962848",
      "rowidDocto": "22681541",
      "fecha": "2023-04-26T00:00:00-05:00",
      "fechaVcto": "2023-05-02T00:00:00-05:00",
      "fechaDocProveedor": "2023-04-24T00:00:00-05:00",
      "idCoCruce": "CED",
      "prefijoCruce": "FE",
      "tipoDocCruce": "",
      "consecutivoDocCruce": "1759",
      "valorDb": 21815130,
      "valorCr": 21815130,
      "valorFactura": 21815130,
      "valorDescuentoPP": 0,
      "valorPago": 21815130,
      "egresoRowid": null,
      "docRowid": "22681541"
    },
    {
      "idCia": "1",
      "rowid": "3958889",
      "rowidDocto": "22640775",
      "fecha": "2023-04-24T00:00:00-05:00",
      "fechaVcto": "2023-04-30T00:00:00-05:00",
      "fechaDocProveedor": "2023-04-22T00:00:00-05:00",
      "idCoCruce": "CED",
      "prefijoCruce": "FE",
      "tipoDocCruce": "",
      "consecutivoDocCruce": "1758",
      "valorDb": 21775850,
      "valorCr": 21775850,
      "valorFactura": 21775850,
      "valorDescuentoPP": 0,
      "valorPago": 21775850,
      "egresoRowid": null,
      "docRowid": "22640775"
    },
    {
      "idCia": "1",
      "rowid": "3957865",
      "rowidDocto": "22583647",
      "fecha": "2023-04-21T00:00:00-05:00",
      "fechaVcto": "2023-04-29T00:00:00-05:00",
      "fechaDocProveedor": "2023-04-21T00:00:00-05:00",
      "idCoCruce": "CED",
      "prefijoCruce": "FE",
      "tipoDocCruce": "",
      "consecutivoDocCruce": "1757",
      "valorDb": 16595800,
      "valorCr": 16595800,
      "valorFactura": 16595800,
      "valorDescuentoPP": 0,
      "valorPago": 16595800,
      "egresoRowid": null,
      "docRowid": "22583647"
    },
    {
      "idCia": "1",
      "rowid": "3956452",
      "rowidDocto": "22579647",
      "fecha": "2023-04-21T00:00:00-05:00",
      "fechaVcto": "2023-04-28T00:00:00-05:00",
      "fechaDocProveedor": "2023-04-20T00:00:00-05:00",
      "idCoCruce": "CED",
      "prefijoCruce": "FE",
      "tipoDocCruce": "",
      "consecutivoDocCruce": "1756",
      "valorDb": 17766737,
      "valorCr": 17766737,
      "valorFactura": 17766737,
      "valorDescuentoPP": 0,
      "valorPago": 17766737,
      "egresoRowid": null,
      "docRowid": "22579647"
    },
    {
      "idCia": "1",
      "rowid": "3954764",
      "rowidDocto": "22558491",
      "fecha": "2023-04-20T00:00:00-05:00",
      "fechaVcto": "2023-04-27T00:00:00-05:00",
      "fechaDocProveedor": "2023-04-19T00:00:00-05:00",
      "idCoCruce": "CED",
      "prefijoCruce": "FE",
      "tipoDocCruce": "",
      "consecutivoDocCruce": "1755",
      "valorDb": 19491718,
      "valorCr": 19491718,
      "valorFactura": 19491718,
      "valorDescuentoPP": 0,
      "valorPago": 19491718,
      "egresoRowid": null,
      "docRowid": "22558491"
    }
  ]`);

export const balanceDetails = JSON.parse(`[
    {
        "rowidDocto": "22804172",
        "details": [
            {
                "rowid": "22804172",
                "idCo": "CED",
                "idTipoDoc": "FAC",
                "consecutivoDoc": "144666",
                "notas": "",
                "fechaDoctoCruce": "2023-04-28T00:00:00-05:00",
                "idCoCruce": "CED",
                "prefijoCruce": "FE",
                "tipoDocCruce": "",
                "consecutivoDocCruce": "1764",
                "valorDb": 0,
                "valorCr": 33507804,
                "isEgress": 0
            },
            {
                "rowid": "22804175",
                "idCo": "CED",
                "idTipoDoc": "NDC",
                "consecutivoDoc": "91169",
                "notas": "",
                "fechaDoctoCruce": "2023-04-28T00:00:00-05:00",
                "idCoCruce": "CED",
                "prefijoCruce": "FE",
                "tipoDocCruce": "",
                "consecutivoDocCruce": "1764",
                "valorDb": 1568879,
                "valorCr": 0,
                "isEgress": 0,
                "docpro": 1
            }
        ]
    },
    {
        "rowidDocto": "22723706",
        "details": [
            {
                "rowid": "22723706",
                "idCo": "CED",
                "idTipoDoc": "FAC",
                "consecutivoDoc": "144597",
                "notas": "",
                "fechaDoctoCruce": "2023-04-27T00:00:00-05:00",
                "idCoCruce": "CED",
                "prefijoCruce": "FE",
                "tipoDocCruce": "",
                "consecutivoDocCruce": "1762",
                "valorDb": 0,
                "valorCr": 20258660,
                "isEgress": 0
            },
            {
                "rowid": "22723713",
                "idCo": "CED",
                "idTipoDoc": "NDC",
                "consecutivoDoc": "91127",
                "notas": "",
                "fechaDoctoCruce": "2023-04-27T00:00:00-05:00",
                "idCoCruce": "CED",
                "prefijoCruce": "FE",
                "tipoDocCruce": "",
                "consecutivoDocCruce": "1762",
                "valorDb": 68600,
                "valorCr": 0,
                "isEgress": 0
            }
        ]
    },
    {
        "rowidDocto": "22702965",
        "details": [
            {
                "rowid": "22702965",
                "idCo": "CED",
                "idTipoDoc": "FAC",
                "consecutivoDoc": "144570",
                "notas": "",
                "fechaDoctoCruce": "2023-04-26T00:00:00-05:00",
                "idCoCruce": "CED",
                "prefijoCruce": "FE",
                "tipoDocCruce": "",
                "consecutivoDocCruce": "1761",
                "valorDb": 0,
                "valorCr": 16325750,
                "isEgress": 0
            },
            {
                "rowid": "22723050",
                "idCo": "CED",
                "idTipoDoc": "NDC",
                "consecutivoDoc": "91111",
                "notas": "",
                "fechaDoctoCruce": "2023-04-26T00:00:00-05:00",
                "idCoCruce": "CED",
                "prefijoCruce": "FE",
                "tipoDocCruce": "",
                "consecutivoDocCruce": "1761",
                "valorDb": 259220,
                "valorCr": 0,
                "isEgress": 0
            }
        ]
    },
    {
        "rowidDocto": "22681004",
        "details": [
            {
                "rowid": "22681004",
                "idCo": "CED",
                "idTipoDoc": "FAC",
                "consecutivoDoc": "144534",
                "notas": "",
                "fechaDoctoCruce": "2023-04-25T00:00:00-05:00",
                "idCoCruce": "CED",
                "prefijoCruce": "FE",
                "tipoDocCruce": "",
                "consecutivoDocCruce": "1760",
                "valorDb": 0,
                "valorCr": 17700550,
                "isEgress": 0
            },
            {
                "rowid": "22681012",
                "idCo": "CED",
                "idTipoDoc": "NDC",
                "consecutivoDoc": "91089",
                "notas": "",
                "fechaDoctoCruce": "2023-04-25T00:00:00-05:00",
                "idCoCruce": "CED",
                "prefijoCruce": "FE",
                "tipoDocCruce": "",
                "consecutivoDocCruce": "1760",
                "valorDb": 93600,
                "valorCr": 0,
                "isEgress": 0
            }
        ]
    },
    {
        "rowidDocto": "22681541",
        "details": [
            {
                "rowid": "22681541",
                "idCo": "CED",
                "idTipoDoc": "FAC",
                "consecutivoDoc": "144544",
                "notas": "",
                "fechaDoctoCruce": "2023-04-24T00:00:00-05:00",
                "idCoCruce": "CED",
                "prefijoCruce": "FE",
                "tipoDocCruce": "",
                "consecutivoDocCruce": "1759",
                "valorDb": 0,
                "valorCr": 21815130,
                "isEgress": 0
            },
            {
                "rowid": "22681549",
                "idCo": "CED",
                "idTipoDoc": "NDC",
                "consecutivoDoc": "91096",
                "notas": "",
                "fechaDoctoCruce": "2023-04-24T00:00:00-05:00",
                "idCoCruce": "CED",
                "prefijoCruce": "FE",
                "tipoDocCruce": "",
                "consecutivoDocCruce": "1759",
                "valorDb": 413755,
                "valorCr": 0,
                "isEgress": 0
            },
            {
                "rowid": "22804542",
                "idCo": "ADM",
                "idTipoDoc": "PEL",
                "consecutivoDoc": "23942",
                "notas": "BRINKS FRUVER 02 MAYO",
                "fechaDoctoCruce": "2023-04-24T00:00:00-05:00",
                "idCoCruce": "CED",
                "prefijoCruce": "FE",
                "tipoDocCruce": "",
                "consecutivoDocCruce": "1759",
                "valorDb": 21401375,
                "valorCr": 0,
                "isEgress": 1
            }
        ]
    },
    {
        "rowidDocto": "22640775",
        "details": [
            {
                "rowid": "22640775",
                "idCo": "CED",
                "idTipoDoc": "FAC",
                "consecutivoDoc": "144479",
                "notas": "",
                "fechaDoctoCruce": "2023-04-22T00:00:00-05:00",
                "idCoCruce": "CED",
                "prefijoCruce": "FE",
                "tipoDocCruce": "",
                "consecutivoDocCruce": "1758",
                "valorDb": 0,
                "valorCr": 21775850,
                "isEgress": 0
            },
            {
                "rowid": "22640778",
                "idCo": "CED",
                "idTipoDoc": "NDC",
                "consecutivoDoc": "91049",
                "notas": "",
                "fechaDoctoCruce": "2023-04-22T00:00:00-05:00",
                "idCoCruce": "CED",
                "prefijoCruce": "FE",
                "tipoDocCruce": "",
                "consecutivoDocCruce": "1758",
                "valorDb": 670233,
                "valorCr": 0,
                "isEgress": 0
            },
            {
                "rowid": "22804542",
                "idCo": "ADM",
                "idTipoDoc": "PEL",
                "consecutivoDoc": "23942",
                "notas": "BRINKS FRUVER 02 MAYO",
                "fechaDoctoCruce": "2023-04-22T00:00:00-05:00",
                "idCoCruce": "CED",
                "prefijoCruce": "FE",
                "tipoDocCruce": "",
                "consecutivoDocCruce": "1758",
                "valorDb": 21105617,
                "valorCr": 0,
                "isEgress": 1
            }
        ]
    },
    {
        "rowidDocto": "22583647",
        "details": [
            {
                "rowid": "22583647",
                "idCo": "CED",
                "idTipoDoc": "FAC",
                "consecutivoDoc": "144435",
                "notas": "",
                "fechaDoctoCruce": "2023-04-21T00:00:00-05:00",
                "idCoCruce": "CED",
                "prefijoCruce": "FE",
                "tipoDocCruce": "",
                "consecutivoDocCruce": "1757",
                "valorDb": 0,
                "valorCr": 16595800,
                "isEgress": 0
            },
            {
                "rowid": "22583655",
                "idCo": "CED",
                "idTipoDoc": "NDC",
                "consecutivoDoc": "91019",
                "notas": "",
                "fechaDoctoCruce": "2023-04-21T00:00:00-05:00",
                "idCoCruce": "CED",
                "prefijoCruce": "FE",
                "tipoDocCruce": "",
                "consecutivoDocCruce": "1757",
                "valorDb": 88000,
                "valorCr": 0,
                "isEgress": 0
            },
            {
                "rowid": "22804542",
                "idCo": "ADM",
                "idTipoDoc": "PEL",
                "consecutivoDoc": "23942",
                "notas": "BRINKS FRUVER 02 MAYO",
                "fechaDoctoCruce": "2023-04-21T00:00:00-05:00",
                "idCoCruce": "CED",
                "prefijoCruce": "FE",
                "tipoDocCruce": "",
                "consecutivoDocCruce": "1757",
                "valorDb": 16507800,
                "valorCr": 0,
                "isEgress": 1
            }
        ]
    },
    {
        "rowidDocto": "22579647",
        "details": [
            {
                "rowid": "22579647",
                "idCo": "CED",
                "idTipoDoc": "FAC",
                "consecutivoDoc": "144418",
                "notas": "",
                "fechaDoctoCruce": "2023-04-20T00:00:00-05:00",
                "idCoCruce": "CED",
                "prefijoCruce": "FE",
                "tipoDocCruce": "",
                "consecutivoDocCruce": "1756",
                "valorDb": 0,
                "valorCr": 17766737,
                "isEgress": 0
            },
            {
                "rowid": "22804542",
                "idCo": "ADM",
                "idTipoDoc": "PEL",
                "consecutivoDoc": "23942",
                "notas": "BRINKS FRUVER 02 MAYO",
                "fechaDoctoCruce": "2023-04-20T00:00:00-05:00",
                "idCoCruce": "CED",
                "prefijoCruce": "FE",
                "tipoDocCruce": "",
                "consecutivoDocCruce": "1756",
                "valorDb": 17766737,
                "valorCr": 0,
                "isEgress": 1
            }
        ]
    },
    {
        "rowidDocto": "22558491",
        "details": [
            {
                "rowid": "22558491",
                "idCo": "CED",
                "idTipoDoc": "FAC",
                "consecutivoDoc": "144390",
                "notas": "",
                "fechaDoctoCruce": "2023-04-19T00:00:00-05:00",
                "idCoCruce": "CED",
                "prefijoCruce": "FE",
                "tipoDocCruce": "",
                "consecutivoDocCruce": "1755",
                "valorDb": 0,
                "valorCr": 19491718,
                "isEgress": 0
            },
            {
                "rowid": "22558493",
                "idCo": "CED",
                "idTipoDoc": "NDC",
                "consecutivoDoc": "90993",
                "notas": "",
                "fechaDoctoCruce": "2023-04-19T00:00:00-05:00",
                "idCoCruce": "CED",
                "prefijoCruce": "FE",
                "tipoDocCruce": "",
                "consecutivoDocCruce": "1755",
                "valorDb": 156000,
                "valorCr": 0,
                "isEgress": 0
            },
            {
                "rowid": "22804542",
                "idCo": "ADM",
                "idTipoDoc": "PEL",
                "consecutivoDoc": "23942",
                "notas": "BRINKS FRUVER 02 MAYO",
                "fechaDoctoCruce": "2023-04-19T00:00:00-05:00",
                "idCoCruce": "CED",
                "prefijoCruce": "FE",
                "tipoDocCruce": "",
                "consecutivoDocCruce": "1755",
                "valorDb": 19335718,
                "valorCr": 0,
                "isEgress": 1
            }
        ]
    }
]`);

export const discharges = JSON.parse(`[
  {
    "rowid": "22804542",
    "idCo": "ADM",
    "tipoDoc": "PEL",
    "tipoDocDescripcion": "PAGOS ELECTRONICOS",
    "numeroDoc": "23942",
    "fechaDoc": "2023-05-02T05:00:00.000Z",
    "notas": "BRINKS FRUVER 02 MAYO",
    "cuenta": "",
    "cuentaBancaria": "",
    "valor": 118056443
  },
  {
    "rowid": "22662223",
    "idCo": "ADM",
    "tipoDoc": "PEL",
    "tipoDocDescripcion": "PAGOS ELECTRONICOS",
    "numeroDoc": "23740",
    "fechaDoc": "2023-04-25T05:00:00.000Z",
    "notas": "BRINKS FRUVER 25 ABRIL",
    "cuenta": "",
    "cuentaBancaria": "",
    "valor": 133085091
  },
  {
    "rowid": "22512440",
    "idCo": "ADM",
    "tipoDoc": "PEL",
    "tipoDocDescripcion": "PAGOS ELECTRONICOS",
    "numeroDoc": "23460",
    "fechaDoc": "2023-04-18T05:00:00.000Z",
    "notas": "BRINKS FRUVER 18 ABRIL",
    "cuenta": "",
    "cuentaBancaria": "",
    "valor": 101697281
  },
  {
    "rowid": "22201814",
    "idCo": "ADM",
    "tipoDoc": "PEL",
    "tipoDocDescripcion": "PAGOS ELECTRONICOS",
    "numeroDoc": "22958",
    "fechaDoc": "2023-04-11T05:00:00.000Z",
    "notas": "BRINKS FRUVER 11 ABRIL",
    "cuenta": "",
    "cuentaBancaria": "",
    "valor": 107457249
  },
  {
    "rowid": "21798863",
    "idCo": "ADM",
    "tipoDoc": "PEL",
    "tipoDocDescripcion": "PAGOS ELECTRONICOS",
    "numeroDoc": "22763",
    "fechaDoc": "2023-04-04T05:00:00.000Z",
    "notas": "BRINKS FRUVER 04 ABRIL",
    "cuenta": "",
    "cuentaBancaria": "",
    "valor": 115708674
  },
  {
    "rowid": "21354651",
    "idCo": "ADM",
    "tipoDoc": "PEL",
    "tipoDocDescripcion": "PAGOS ELECTRONICOS",
    "numeroDoc": "22533",
    "fechaDoc": "2023-03-28T05:00:00.000Z",
    "notas": "BRINKS FRUVER 28 MARZO",
    "cuenta": "",
    "cuentaBancaria": "",
    "valor": 119972335
  },
  {
    "rowid": "20949500",
    "idCo": "ADM",
    "tipoDoc": "PEL",
    "tipoDocDescripcion": "PAGOS ELECTRONICOS",
    "numeroDoc": "22284",
    "fechaDoc": "2023-03-21T05:00:00.000Z",
    "notas": "BRINKS FRUVER 21 MARZO",
    "cuenta": "",
    "cuentaBancaria": "",
    "valor": 110737201
  },
  {
    "rowid": "20539954",
    "idCo": "ADM",
    "tipoDoc": "PEL",
    "tipoDocDescripcion": "PAGOS ELECTRONICOS",
    "numeroDoc": "22051",
    "fechaDoc": "2023-03-14T05:00:00.000Z",
    "notas": "BRINKS FRUVER 14 MARZO",
    "cuenta": "",
    "cuentaBancaria": "",
    "valor": 114745821
  },
  {
    "rowid": "20102020",
    "idCo": "ADM",
    "tipoDoc": "PEL",
    "tipoDocDescripcion": "PAGOS ELECTRONICOS",
    "numeroDoc": "21680",
    "fechaDoc": "2023-03-07T05:00:00.000Z",
    "notas": "BRINKS FRUVER 07 MARZO",
    "cuenta": "",
    "cuentaBancaria": "",
    "valor": 108705784
  }
]`);

export const devs = JSON.parse(`[
  {
    "factRowid": "22538275",
    "dcpRowid": "22536110",
    "docIdCoMov": "MAY",
    "docCoDescripcion": "EURO MAYORISTA",
    "factFecha": "2023-04-19T05:00:00.000Z",
    "dcpTipoDoc": "DEC",
    "dcpNumeroDoc": "62038",
    "docCruTipoDoc": "DEC",
    "docCruTipoDocDescripcion": "DEVOLUCIÓN DE INVENTARIO",
    "docCruNumeroDoc": "62038",
    "dcpNotas": "",
    "dcpNumeroDocReferencia": "62038",
    "valor": 538328,
    "factValorNeto": 640611,
    "factValorDifer": 0,
    "factDscto": 0,
    "factValorRetencion": 0,
    "docValorNeto": 0
  },
  {
    "factRowid": "22538267",
    "dcpRowid": "22535976",
    "docIdCoMov": "MAY",
    "docCoDescripcion": "EURO MAYORISTA",
    "factFecha": "2023-04-19T05:00:00.000Z",
    "dcpTipoDoc": "DEC",
    "dcpNumeroDoc": "62037",
    "docCruTipoDoc": "DEC",
    "docCruTipoDocDescripcion": "DEVOLUCIÓN DE INVENTARIO",
    "docCruNumeroDoc": "62037",
    "dcpNotas": "",
    "dcpNumeroDocReferencia": "62037",
    "valor": 440347,
    "factValorNeto": 524013,
    "factValorDifer": 0,
    "factDscto": 0,
    "factValorRetencion": 0,
    "docValorNeto": 0
  },
  {
    "factRowid": "22513644",
    "dcpRowid": "22490751",
    "docIdCoMov": "MAY",
    "docCoDescripcion": "EURO MAYORISTA",
    "factFecha": "2023-04-17T05:00:00.000Z",
    "dcpTipoDoc": "DEC",
    "dcpNumeroDoc": "61996",
    "docCruTipoDoc": "DEC",
    "docCruTipoDocDescripcion": "DEVOLUCIÓN DE INVENTARIO",
    "docCruNumeroDoc": "61996",
    "dcpNotas": "",
    "dcpNumeroDocReferencia": "61996",
    "valor": 309916,
    "factValorNeto": 368800,
    "factValorDifer": 0,
    "factDscto": 0,
    "factValorRetencion": 0,
    "docValorNeto": 0
  },
  {
    "factRowid": "20481923",
    "dcpRowid": "20253322",
    "docIdCoMov": "MAY",
    "docCoDescripcion": "EURO MAYORISTA",
    "factFecha": "2023-03-09T05:00:00.000Z",
    "dcpTipoDoc": "DEC",
    "dcpNumeroDoc": "61407",
    "docCruTipoDoc": "DEC",
    "docCruTipoDocDescripcion": "DEVOLUCIÓN DE INVENTARIO",
    "docCruNumeroDoc": "61407",
    "dcpNotas": "",
    "dcpNumeroDocReferencia": "61407",
    "valor": 99114,
    "factValorNeto": 117945,
    "factValorDifer": 0,
    "factDscto": 0,
    "factValorRetencion": 0,
    "docValorNeto": 0
  },
  {
    "factRowid": "20481913",
    "dcpRowid": "20253271",
    "docIdCoMov": "MAY",
    "docCoDescripcion": "EURO MAYORISTA",
    "factFecha": "2023-03-09T05:00:00.000Z",
    "dcpTipoDoc": "DEC",
    "dcpNumeroDoc": "61406",
    "docCruTipoDoc": "DEC",
    "docCruTipoDocDescripcion": "DEVOLUCIÓN DE INVENTARIO",
    "docCruNumeroDoc": "61406",
    "dcpNotas": "",
    "dcpNumeroDocReferencia": "61406",
    "valor": 70874,
    "factValorNeto": 84340,
    "factValorDifer": 0,
    "factDscto": 0,
    "factValorRetencion": 0,
    "docValorNeto": 0
  },
  {
    "factRowid": "18933854",
    "dcpRowid": "18702426",
    "docIdCoMov": "MAY",
    "docCoDescripcion": "EURO MAYORISTA",
    "factFecha": "2023-02-10T05:00:00.000Z",
    "dcpTipoDoc": "DEC",
    "dcpNumeroDoc": "60969",
    "docCruTipoDoc": "DEC",
    "docCruTipoDocDescripcion": "DEVOLUCIÓN DE INVENTARIO",
    "docCruNumeroDoc": "60969",
    "dcpNotas": "",
    "dcpNumeroDocReferencia": "60969",
    "valor": 842919,
    "factValorNeto": 1003073,
    "factValorDifer": 0,
    "factDscto": 0,
    "factValorRetencion": 0,
    "docValorNeto": 0
  },
  {
    "factRowid": "18933880",
    "dcpRowid": "18702394",
    "docIdCoMov": "MAY",
    "docCoDescripcion": "EURO MAYORISTA",
    "factFecha": "2023-02-10T05:00:00.000Z",
    "dcpTipoDoc": "DEC",
    "dcpNumeroDoc": "60968",
    "docCruTipoDoc": "DE",
    "docCruTipoDocDescripcion": "DEVOLUCIÓN DE INVENTARIO",
    "docCruNumeroDoc": "60968",
    "dcpNotas": "",
    "dcpNumeroDocReferencia": "60968",
    "valor": 26900,
    "factValorNeto": 32011,
    "factValorDifer": 0,
    "factDscto": 0,
    "factValorRetencion": 0,
    "docValorNeto": 0
  },
  {
    "factRowid": "18166264",
    "dcpRowid": "18144111",
    "docIdCoMov": "MAY",
    "docCoDescripcion": "EURO MAYORISTA",
    "factFecha": "2023-01-23T05:00:00.000Z",
    "dcpTipoDoc": "DEC",
    "dcpNumeroDoc": "60679",
    "docCruTipoDoc": "DEC",
    "docCruTipoDocDescripcion": "DEVOLUCIÓN DE INVENTARIO",
    "docCruNumeroDoc": "60679",
    "dcpNotas": "",
    "dcpNumeroDocReferencia": "60679",
    "valor": 1551658,
    "factValorNeto": 1846472,
    "factValorDifer": 0,
    "factDscto": 0,
    "factValorRetencion": 0,
    "docValorNeto": 0
  },
  {
    "factRowid": "18166261",
    "dcpRowid": "18144096",
    "docIdCoMov": "MAY",
    "docCoDescripcion": "EURO MAYORISTA",
    "factFecha": "2023-01-23T05:00:00.000Z",
    "dcpTipoDoc": "DEC",
    "dcpNumeroDoc": "60678",
    "docCruTipoDoc": "DEC",
    "docCruTipoDocDescripcion": "DEVOLUCIÓN DE INVENTARIO",
    "docCruNumeroDoc": "60678",
    "dcpNotas": "",
    "dcpNumeroDocReferencia": "60678",
    "valor": 451270,
    "factValorNeto": 537011,
    "factValorDifer": 0,
    "factDscto": 0,
    "factValorRetencion": 0,
    "docValorNeto": 0
  },
  {
    "factRowid": "17857073",
    "dcpRowid": "17841345",
    "docIdCoMov": "MAY",
    "docCoDescripcion": "EURO MAYORISTA",
    "factFecha": "2022-12-14T05:00:00.000Z",
    "dcpTipoDoc": "DEC",
    "dcpNumeroDoc": "60135",
    "docCruTipoDoc": "DEC",
    "docCruTipoDocDescripcion": "DEVOLUCIÓN DE INVENTARIO",
    "docCruNumeroDoc": "60135",
    "dcpNotas": "",
    "dcpNumeroDocReferencia": "60135",
    "valor": 90636,
    "factValorNeto": 107857,
    "factValorDifer": 0,
    "factDscto": 0,
    "factValorRetencion": 0,
    "docValorNeto": 0
  }
]`);

export const supplier = JSON.parse(`{
  "id": "999999999",
  "name": "PROVEEDOR DEMO",
  "tel": "9999999999",
  "email": "prueba@mail.com",
  "third": {
    "id": "999999999",
    "name": "PROVEEDOR DEMO"
  }
}`);