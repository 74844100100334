import React from 'react';
import CertificadosView from './CertificadosView';

import { connect } from 'react-redux';
import AppReducer from '../../redux/reducers/AppReducer';

const Certificados = ({ AppReducer }) => {

    return(
        <CertificadosView AppReducer={AppReducer} />
    );
}

const mapStateToProps = ({ AppReducer }) => {
    return {
        AppReducer
    };
};

export default connect(mapStateToProps)(Certificados);