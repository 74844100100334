import React, { useState, useEffect} from 'react';
import { httpGet } from '../../http';
import CausacionDetalleView from './CausacionDetalleView';

import { connect } from 'react-redux';
import AppReducer from '../../redux/reducers/AppReducer';

const CausacionDetalle = ({ history, AppReducer }) => {
    const { location: { state: { documento } } } = history;
    const [ retenciones, setRetenciones ] = useState([]);
    const [ causaciones, setCausaciones ] = useState([]);
    const [ totalRetenciones, setTotalRetenciones ] = useState(0);
    const [documentos, setDocumentos ] = useState(
        {
            data: [],
            loading: false,
            error: {}
        }
    );

    useEffect(() => {

        if(!history.location.state.documento){
            history.push('/portal');
            return 0;
        }
        
        getCausacionDetalle(history.location.state.documento);

    }, []);

    const getCausacionDetalle = async () => {
        setDocumentos({
            ...documentos,
            loading: true
        });
        
        const respCausacion = await httpGet('/api/v1/egresos/causacion/' + documento.coMov + '/' + documento.tipoDocCruce + '/' + documento.numDocCruce);
        const docs = await httpGet('/api/v1/egresos/causacion-docs/' + documento.coMov + '/' + documento.tipoDocCruce + '/' + documento.numDocCruce);
        const respRetenciones = await httpGet('/api/v1/egresos/causacion-retenciones/' + documento.coMov + '/' + documento.tipoDocCruce + '/' + documento.numDocCruce);

        setCausaciones(respCausacion.data);
        
        if((respRetenciones.data !== null) && (respRetenciones.data !== undefined)){
            let totalRetenciones = 0;
        
            for( const retencion of respRetenciones.data){
                totalRetenciones = totalRetenciones + retencion.valor;
            }

            setRetenciones(respRetenciones.data);
            setTotalRetenciones(totalRetenciones);
        }

        if((docs !== null) && (docs !== undefined)){
            setDocumentos({
                ...documentos,
                data: docs.data ,
                loading: false
            });
        }
        
    }

    return(
        <>{
        <CausacionDetalleView AppReducer={AppReducer} causaciones={causaciones} retenciones={retenciones} documentos={documentos} totalRetenciones={totalRetenciones} />
        }</>
    );
}

const mapStateToProps = ({ AppReducer }) => {
    return {
        AppReducer
    };
};

export default connect(mapStateToProps)(CausacionDetalle);
