import React, { useState, useEffect } from 'react';
import Title from '../../components/title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import SubTitle from '../../components/subTitle/SubTitle';
import './DocumentoDetalle.css';
import { Spinner } from 'react-bootstrap';
import { formatNumber, formatDateMin } from '../../misc/index';
import { PDFDownloadLink } from '@react-pdf/renderer';
import DocumentoFIDetallePDFView from '../DocumentoFIDetallePDF/DocumentoFIDetallePDFView';
import DocumentoCMDetallePDFView from '../DocumentoCMDetallePDF/DocumentoCMDetallePDFView';

const DocumentoDetalleView = ({ AppReducer, documento, generarPdf }) => {

    return(
        <div className='page-container'>
            <Title>Documento de proveedor - Detalle</Title>
            <div className='page-body'>
                { documento.loading ?
                    <div className='loading-spinner'>
                        <Spinner animation="border" variant="primary" />
                    </div>
                :
                <>
                    <div className='desktop-view'>
                        <SubTitle>Empresa: { AppReducer.company.name }</SubTitle>
                        <SubTitle>Proveedor:</SubTitle>
                        <div>
                            {/*<div><span className='info-title'>N.I.T: </span><span>{ AppReducer.supplier.id }</span></div>*/}
                            <div><span className='info-title'>N.I.T: </span><span>999999999</span></div>
                            {/*<div><span className='info-title'>Razón social: </span><span>{ AppReducer.supplier.third.name }</span></div>*/}
                            <div><span className='info-title'>Razón social: </span><span>PROVEEDOR DEMO</span></div>
                        </div>
                        <br />
                        <br />
                        <SubTitle>Detalle del documento</SubTitle>
                        
                            <table className='info-table doc-table'>
                                <thead>
                                    <tr>
                                        <th>CO</th>
                                        <th>FECHA</th>
                                        <th>DOC. CRUCE</th>
                                        <th>DETALLE</th>
                                        <th>DESCUENTOS</th>
                                        <th>RETENCIONES</th>
                                        { ((documento.encabezado.valorPago !== undefined) && (documento.encabezado.valorPago !== null)) && <th>VALOR PAGO</th> }
                                        <th>VALOR DOCUMENTO</th>
                                        <th>DESCARGAR</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{ documento.encabezado.docIdCoMov }</td>
                                        <td>{ formatDateMin(new Date(documento.encabezado.factFecha)) }</td>
                                        <td>{ documento.encabezado.docCruTipoDoc + ' ' + documento.encabezado.docCruNumeroDoc }</td>
                                        <td>{ documento.encabezado.notas }</td>
                                        <td>${ formatNumber(documento.encabezado.factDscto) }</td>
                                        <td>${ formatNumber(documento.encabezado.factValorRetencion) }</td>
                                        { ((documento.encabezado.valorPago !== undefined) && (documento.encabezado.valorPago !== null)) && <td>${formatNumber(documento.encabezado.valorPago)}</td> }
                                        <td>${ formatNumber(documento.encabezado.factValorNeto) }</td>
                                        <td><div className='pdf-icon-container' onClick={generarPdf}><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></div></td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <br />
                            <SubTitle>Detalle de saldo</SubTitle>
                            <table className='info-table doc-table'>
                                <thead>
                                    <tr>
                                        <th>REG.</th>
                                        <th>CO</th>
                                        <th>DOCUMENTO</th>
                                        <th>DEBITOS</th>
                                        <th>CREDITOS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        documento.detalleSaldo.map(
                                            (detalle, index) => {
                                                return(
                                                    <tr key={index}>
                                                        <td>{ index + 1 }</td>
                                                        <td>{ detalle.idCo }</td>
                                                        <td>{ detalle.idTipoDoc + '-' + detalle.consecutivoDoc }</td>
                                                        <td>${ formatNumber(detalle.valorDb) }</td>
                                                        <td>${ formatNumber(detalle.valorCr) }</td>
                                                    </tr>
                                                );
                                            }
                                        )
                                    }
                                </tbody>
                            </table>
                            <br />
                            <br />
                            { documento.retenciones.detalles.length > 0 &&
                            <>
                                    <SubTitle>Retenciones</SubTitle>
                                    <table className='info-table doc-table'>
                                    <thead>
                                        <tr>
                                            <th>REG.</th>
                                            <th>CODIGO CUENTA</th>
                                            <th>DESCRIPCION CUENTA</th>
                                            <th>VALOR RETENCIÓN</th>
                                            <th>BASE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            documento.retenciones.detalles.map(
                                                (detalle, index) => {
                                                    return(
                                                        <tr key={index}>
                                                            <td>{ (index + 1) }</td>
                                                            <td>{ detalle.cuenta }</td>
                                                            <td>{ detalle.cuentaDescripcion }</td>
                                                            <td>${ formatNumber(detalle.valorCr - detalle.valorDb) }</td>
                                                            <td>${ formatNumber(detalle.valorBase) }</td>
                                                        </tr>
                                                    );
                                                }
                                            )
                                        }
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td>TOTAL:</td>
                                            <td>${ formatNumber(documento.retenciones.totalCredito - documento.retenciones.totalDebito) }</td>
                                            <td>${ formatNumber(documento.retenciones.totalBase) }</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br />
                                <br />
                                    </> }
                            { documento.encabezado.docpro === 1 ?
                                <table className='info-table'>
                                    <thead>
                                        <tr>
                                            <th>ITEM</th>
                                            <th>DESCRIPCION</th>
                                            <th>CANTIDAD</th>
                                            <th>UNIDAD DE MEDIDA</th>
                                            <th>VALOR BRUTO</th>
                                            <th>DESCUENTO LINEA</th>
                                            <th>DESCUENTO GLOBAL</th>
                                            <th>IMPUESTOS</th>
                                            <th>TOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            documento.detalles.map(
                                                (detalle, index) => {
                                                    return(
                                                        <tr key={index}>
                                                            <td>{ detalle.itemId }</td>
                                                            <td>{ detalle.itemDescripcion }</td>
                                                            <td>{ detalle.invCant }</td>
                                                            <td>{ detalle.invIdUM }</td>
                                                            <td>${ formatNumber(detalle.invValorBruto) }</td>
                                                            <td>${ formatNumber(detalle.invDsctoLinea) }</td>
                                                            <td>${ formatNumber(detalle.invDsctoGlobal) }</td>
                                                            <td>${ formatNumber(detalle.invValorImpuestos) }</td>
                                                            <td>${ formatNumber(detalle.invValorNeto) }</td>
                                                        </tr>
                                                    );
                                                }
                                            )
                                        }
                                    </tbody>
                                </table>
                            :
                                <>
                                    <SubTitle>Movimiento contable</SubTitle>
                                    <table className='info-table doc-table'>
                                        <thead>
                                            <tr>
                                                <th>REG.</th>
                                                <th>CODIGO CUENTA</th>
                                                <th>DESCRIPCION CUENTA</th>
                                                <th>CO</th>
                                                <th>TERCERO</th>
                                                <th>RAZON SOCIAL</th>
                                                <th>DEBITOS</th>
                                                <th>CREDITOS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                documento.detalleFi.detalles.map(
                                                    (detalle, index) => {
                                                        return(
                                                            <tr key={index}>
                                                                <td>{ (index + 1) }</td>
                                                                <td>{ detalle.cuentaId }</td>
                                                                <td>{ detalle.cuentaDescripcion }</td>
                                                                <td>{ detalle.idCo }</td>
                                                                {/*<td>{ detalle.nit }</td>*/}
                                                                <td>999999999</td>
                                                                {/*<td>{ detalle.razonSocial }</td>*/}
                                                                <td>PROVEEDOR DEMO</td>
                                                                <td>${ formatNumber(detalle.valorDb) }</td>
                                                                <td>${ formatNumber(detalle.valorCr) }</td>
                                                            </tr>
                                                        );
                                                    }
                                                )
                                            }
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>SUMAS IGUALES</td>
                                                <td>${ formatNumber(documento.detalleFi.totalDebito) }</td>
                                                <td>${ formatNumber(documento.detalleFi.totalCredito) }</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </>
                            }
                    </div>
                    <div className='mobile-view'>
                        <br />
                        <SubTitle>Documento</SubTitle>
                        <div className='document-head-container'>
                            <div className='card-fields-container'>
                                <div className='card-field-container'>
                                    <div className='card-field-title'>{ AppReducer.company.name }</div>
                                </div>
                                <br />
                                <div className='card-field-container'>
                                    <div className='card-field-title'>PROVEEDOR:</div>
                                </div>
                                <div className='card-field-container'>
                                    <div className='card-field-title'>N.I.T:</div>
                                    {/*<div>{ AppReducer.supplier.id }</div>*/}
                                    <div>999999999</div>
                                </div>
                                <div className='card-field-container'>
                                    <div className='card-field-title'>RAZÓN SOCIAL:</div>
                                    {/*<div>{ AppReducer.supplier.third.name }</div>*/}
                                    <div>PROVEEDOR DEMO</div>
                                </div>
                                <br />
                                <div className='card-field-container'>
                                    <div className='card-field-title'>CENTRO DE OPERACIÓN:</div>
                                    <div>{ documento.encabezado.docIdCoMov }</div>
                                </div>
                                <div className='card-field-container'>
                                    <div className='card-field-title'>FECHA:</div>
                                    <div>{ formatDateMin(new Date(documento.encabezado.factFecha)) }</div>
                                </div>
                                <div className='card-field-container'>
                                    <div className='card-field-title'>DOC. CRUCE:</div>
                                    <div>{ documento.encabezado.docCruTipoDoc + ' ' + documento.encabezado.docCruNumeroDoc }</div>
                                </div>
                                <div className='card-field-container'>
                                    <div className='card-field-title'>DETALLE:</div>
                                    <div>{ documento.encabezado.notas }</div>
                                </div>
                                <div className='card-field-container'>
                                    <div className='card-field-title'>DESCUENTOS:</div>
                                    <div>${ formatNumber(documento.encabezado.factDscto) }</div>
                                </div>
                                <div className='card-field-container'>
                                    <div className='card-field-title'>RETENCIONES:</div>
                                    <div>${ formatNumber(documento.encabezado.factValorRetencion) }</div>
                                </div>
                                { ((documento.encabezado.valorPago !== undefined) && (documento.encabezado.valorPago !== null)) &&
                                    <div className='card-field-container'>
                                        <div className='card-field-title'>VALOR PAGO:</div>
                                        <div>${formatNumber(documento.encabezado.valorPago)}</div>
                                    </div>
                                }
                                <div className='card-field-container'>
                                    <div className='card-field-title'>VALOR DOCUMENTO:</div>
                                    <div>${ formatNumber(documento.encabezado.factValorNeto) }</div>
                                </div>
                            </div>
                        </div>
                        <br />
                        { documento.encabezado.docpro === 1 ? 
                            <PDFDownloadLink document={<DocumentoCMDetallePDFView documento={documento} />} fileName={ 'documento-' + documento.encabezado.docCruTipoDoc + ' ' + documento.encabezado.docCruNumeroDoc + '.pdf' }>
                                {
                                    ({ blob, url, loading, error }) => {
                                        return(loading ? 'Cargando informe...' : <div className='download-link pdf-icon-container'><FontAwesomeIcon className='view-icon' icon={faFilePdf} /> Descargar</div>);
                                    }
                                }
                            </PDFDownloadLink>
                        :
                            <PDFDownloadLink document={<DocumentoFIDetallePDFView documento={documento} />} fileName={ 'documento-' + documento.encabezado.docCruTipoDoc + ' ' + documento.encabezado.docCruNumeroDoc + '.pdf' }>
                                {
                                    ({ blob, url, loading, error }) => {
                                        return(loading ? 'Cargando informe...' : <div className='download-link pdf-icon-container'><FontAwesomeIcon className='view-icon' icon={faFilePdf} /> Descargar</div>);
                                    }
                                }
                            </PDFDownloadLink>
                        }
                    </div>
                </>
                }
            </div>
        </div>
    );
}

export default DocumentoDetalleView;
