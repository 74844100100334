import React, { useState, useEffect } from 'react';
import Welcome from '../Welcome';
import Signup from '../signup';
import Login from '../Login';
import AdminLogin from '../AdminLogin';
import PasswordRecovery from '../PasswordRecovery';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './WelcomeLayout.css';
import logo from '../../img/logo.png'
import visualLogo from '../../img/visual-logo.png';


import { connect } from 'react-redux';
import AppReducer from '../../redux/reducers/AppReducer';

const WelcomeLayoutView = ({ AppReducer, onVisualClick }) => {
    return(
        <div className='welcome-layout-main'>
            <div className='welcome-layout-header'>
                <img className='welcome-layout-logo' src={ logo } />
                <div className='welcome-layout-company-name'>{ AppReducer.company.name }</div>
            </div>
            <div className='welcome-layout-content'>
                <Switch>
                    <Route path="/signup" component={Signup} />
                    <Route path="/login" component={Login} />
                    <Route path="/recovery-password" component={PasswordRecovery} />
                    <Route path="/admin-login" component={AdminLogin} />
                    <Route path="/" component={Welcome} />
                </Switch>
            </div>
            <div className='welcome-layout-footer-container'>
                <div className='welcome-layout-footer'>
                <div className='welcome-layout-admin-access'><Link style={{ color: 'white' }} to='/admin-login'>Accesso administrador</Link></div>
                <div className='welcome-layout-sign'><Link to={{ path: '' }} onClick={onVisualClick}><img className='welcome-layout-visual-logo' src={ visualLogo } /></Link></div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({ AppReducer }) => {
    return {
        AppReducer
    };
};

export default connect(mapStateToProps)(WelcomeLayoutView);