import React, { useState, useEffect } from "react";
import { httpGet } from "../../http";
import { balanceDetails } from "../../demo";

import DetalleSaldoView from "./DetalleSaldoView";

const DetalleSaldo = ({ history }) => {

    const [ request, setRequest ] = useState({
        loading: false,
        error: false,
        errorMsg: ''
    });

    const [factura, setFactura ] = useState({
        idCoCruce: '',
        prefijoCruce: '',
        consecutivoDocCruce: '',
        fechaDocProveedor: '',
        fechaVcto: '',
        notas: '',
        valorDescuentoPP: 0,
        valorFactura: 0,
        valorPago: 0
    });

    const [detalles, setDetalles ] = useState([]);

    useEffect(() => {
        setFactura(history.location.state.documento);
        getDetalles(history.location.state.documento)
    }, []);

    const getDetalles = async (doc) => {
        setRequest({
            ...request,
            loading: true
        });
        //const resp = await httpGet('/api/v1/egresos/detalle-saldo/' + doc.rowidDocto);


        const detail = balanceDetails.find((element) => {
            return element.rowidDocto === doc.rowidDocto
        });

        //setDetalles(resp.data);
        setDetalles(detail.details);
        setRequest({
            ...request,
            loading: false
        });
    }

    const handleViewClick = async (e, documento) => {
        e.preventDefault();
        if (documento.isEgress === 1){
            const resp = await httpGet('/api/v1/egresos/rowid/' + documento.rowid);
            const egreso = resp.data;

            console.log(egreso);

            history.push({ pathname: '/portal/egreso-detalle', state: { egreso: egreso } });
        } else {
            history.push({ pathname: '/portal/documento-detalle', state: { documento: documento } });
        }
    }

    return(
        <>
        <DetalleSaldoView request={request} factura={factura} detalles={detalles} onViewClick={handleViewClick} />
        </>
    );
}

export default DetalleSaldo;