import React, { useState, useEffect } from 'react';
import { httpGet } from '../../http';
import NotasFinancieroView from './NotasFinancieroView';
import { discharges } from '../../demo';

import { connect } from 'react-redux';
import AppReducer from '../../redux/reducers/AppReducer';

const NotasFinanciero = ({ AppReducer, history }) => {

    const [notas, setNotas ] = useState(
        {
            data: [],
            loading: true,
            error: {}
        }
    );

    useEffect(() => {
        getEgresos(AppReducer.supplier.nit);
    }, []);

    const getEgresos = (tercero) => {
        setNotas({
            ...notas,
            loading: true
        });
        httpGet('/api/v1/notas/cg/nit/' + tercero ).then(
            resp => {
                setNotas({
                    ...notas,
                    data: resp.data,
                    loading: false
                });
            }
        ).catch(
            error => {
                console.log(error);
                setNotas({
                    ...notas,
                    loading: false
                });
            }
        );
    }

    return(
        <NotasFinancieroView AppReducer={AppReducer} notas={notas} />
    );
}

const mapStateToProps = ({ AppReducer }) => {
    return {
        AppReducer
    };
};

export default connect(mapStateToProps)(NotasFinanciero);
