const INITIAL_STATE = {
    admin: {
        _id: '',
        email: '',
        nombre: '',
        createAt: '',
        state: false
    },
    loading: false,
    error: {
        state: false,
        msg: ''
    }
    
};

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case "SET_ADMIN_LOADING_ON":
            return {...state, loading: true}
        case "SET_ADMIN_LOADING_OFF":
            return {...state, loading: false}
        case "SET_ADMIN":
            return {...state, admin: action.payload}
        case "CLEAN_ADMIN":
            return {...INITIAL_STATE}
        case "SET_ADMIN_ERROR":
            return {...state, loading: false, error: action.payload}
                                
            default: return state;
    }
}