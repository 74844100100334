import React, { useState, useEffect } from 'react';
import { PDFViewer } from '@react-pdf/renderer'
import Button from '../../components/button/Button';
import DocumentoCMDetallePDFView from './DocumentoCMDetallePDFView';

const DocumentoCMDetallePDF = ({ history }) => {

    useEffect(() => {

        if(!history.location.state.documento){
            history.push('/portal');
        }

    }, []);

    return(
        <>
            <div>
                <Button onClick={ () => { history.goBack() } } >Volver</Button>
            </div>
            <br />
            <PDFViewer style={{ width: '100%', height: '100vh' }}>
                <DocumentoCMDetallePDFView documento={history.location.state.documento} />
            </PDFViewer>
        </>
    );
}

export default DocumentoCMDetallePDF;