const INITIAL_STATE = {
    menuActive: false
};

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case "SET_MENU_ACTIVE":
            return {...state, menuActive: action.payload}
                                
            default: return state;
    }
}