import React, { useState, useEffect } from 'react';
import Title from '../../components/title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { httpGet } from '../../http';
import { Link } from 'react-router-dom';
import './DocumentosCruzados.css';
import { Spinner } from 'react-bootstrap';
import { formatNumber, formatDateMin } from '../../misc/index';


const DocumentosCruzados = (props) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        proveedor: {
            nit: '',
            razonSocial: '',
            tel: '',
            email: ''
        },
        loading: true,
        error: {}
    });

    useEffect(() => {

        console.log(JSON.parse(sessionStorage.getItem('estado')));

        let newEstado = JSON.parse(sessionStorage.getItem('estado'));
        newEstado.proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        console.log(newEstado);
        
        setEstado(newEstado);
        getFacturas(newEstado.proveedor.nit);

    }, []);

    const getFacturas = (tercero) => {
        setFacturas({
            ...facturas,
            loading: true
        });
        httpGet('/api/v1/facturas/nit/' + tercero).then(
            resp => {
                setFacturas({
                    ...facturas,
                    data: resp.data,
                    loading: false
                });
            }
        ).catch(
            error => {
                console.log(error);
                setFacturas({
                    ...facturas,
                    loading: false
                });
            }
        );
    }

    const [facturas, setFacturas ] = useState(
        {
            data: [],
            loading: true,
            error: {}
        }
    );

    const generarEgresoDetallePdf = async (e, egreso2) => {
        e.preventDefault();

        console.log(egreso2);
        
        const encabezado = await httpGet('/ProveedoresWSNEW/api/Egresos/encabezado?co=' + egreso2.co + '&tipoDocumento=' + egreso2.tipoDoc + '&numeroDocumento=' + egreso2.numeroDoc);
        const detalles = await httpGet('/ProveedoresWSNEW/api/Egresos/detalle?co=' + egreso2.co + '&tipoDocumento=' + egreso2.tipoDoc + '&numeroDocumento=' + egreso2.numeroDoc);

        if((encabezado !== null) && (encabezado !== undefined)){
            let egreso = {
                proveedor: estado.proveedor,
                encabezado: encabezado.data,
                detalles: detalles.data
            }

            console.log(egreso);

            props.history.push({ pathname: '/portal/factura-cruzada-pdf', state: { egreso: egreso } });
        }
        
    }

    return(
        <div className='page-container'>
            <Title>Documentos cruzados</Title>
            {facturas.loading ?
                <div className='loading-spinner'>
                    <Spinner animation="border" variant="primary" />
                </div>
            :
                <div className='page-body'>
                    <table className='info-table'>
                        <thead>
                            <tr>
                                <th>DOCUMENTO</th>
                                <th>FECHA DEL DOCUMENTO</th>
                                <th>DETALLE</th>
                                <th>VALOR DEL DOCUMENTO</th>
                                <th>VER</th>
                                {/*<th>DESCARGAR</th>*/}
                            </tr>
                        </thead>
                        <tbody>
                            { 
                                facturas.data.map(
                                    factura => {
                                        return(
                                            <tr key={factura.rowid}>
                                                <td>{ factura.tipoDoc + '-' + factura.numeroDoc }</td>
                                                <td>{ formatDateMin(new Date(factura.fechaDoc)) }</td>
                                                <td>{ factura.notas }</td>
                                                <td>${ formatNumber(factura.debito) }</td>
                                                <td><div className='view-icon-container'><Link to={{ pathname: "/portal/documento-cruzado-detalle", state: { factura: factura } }}><FontAwesomeIcon className='view-icon' icon={faEye} /></Link></div></td>
                                                {/*<td><div className='pdf-icon-container' ><Link style={{ color: 'red' }} onClick={(e) => { generarEgresoDetallePdf(e, egreso) }}><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></Link></div></td>*/}
                                            </tr>
                                        );
                                    }
                                )
                            }
                        </tbody>
                    </table>
                </div>
            }
        </div>
    );
}

export default DocumentosCruzados;
