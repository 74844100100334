import React from 'react';
import Title from '../../components/title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import SubTitle from '../../components/subTitle/SubTitle';
import './CausacionDetalle.css';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { formatNumber, formatDateMin } from '../../misc/index';
import Card from '../../components/Card';

const DocumentCard = ({ document }) => {
    return(
        <Card>
            <div className='card-fields-container egreso-detalle-card-fields-container'>
                <div className='card-field-container'>
                    <div className='card-field-title'>DOCUMENTO:</div>
                    <div className='card-field-data'>{ document.co + '-' + document.tipoDoc + '-' + document.numDoc }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>DOCUMENTO CRUCE:</div>
                    <div>{ document.numDocProv }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>FECHA:</div>
                    <div>{ formatDateMin( new Date(document.fechaDoc) ) }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>TOTAL:</div>
                    <div>${ formatNumber(document.totalNeto) }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>DETALLE:</div>
                    <div>{ document.detalle  }</div>
                </div>
            </div>
        </Card>
    );
}
 

const CausacionDetalleView = ({ AppReducer, causaciones, retenciones, documentos, totalRetenciones }) => {

    return(
        <div className='page-container'>
            <Title>Detalle de causación</Title>
            <div className='page-body'>
                {documentos.loading ?
                <div className='loading-spinner'>
                    <Spinner animation="border" variant="primary" />
                </div>
                :
                <>
                <div className='desktop-view'>
                    <SubTitle>Empresa: { AppReducer.company.name }</SubTitle>
                    <SubTitle>Proveedor:</SubTitle>
                    <div>
                        <div><span className='info-title'>N.I.T: </span><span>{ AppReducer.supplier.nit }</span></div>
                        <div><span className='info-title'>Razón social: </span><span>{ AppReducer.supplier.razonSocial }</span></div>
                    </div>
                    <br />
                    <br />
                    <SubTitle>Documento de causación</SubTitle>
                    <table className='info-table'>
                        <thead>
                            <tr>
                                <th>EMPRESA</th>
                                <th>CENTRO DE OPERACIÓN</th>
                                <th>FECHA</th>
                                <th>DOCUMENTO</th>                                                        
                                <th>DOCUMENTO PROVEEDOR</th>                            
                                <th>BRUTO</th>
                                <th>NETO</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                causaciones.map((causacion, index) => {
                                    return(
                                    <tr key={index}>
                                        <td>{ causacion.empresa }</td>
                                        <td>{ causacion.co }</td>
                                        <td>{ formatDateMin( new Date(causacion.fechaDoc) )}</td>
                                        <td>{ causacion.tipoDoc + '-' + causacion.numDoc }</td>
                                        <td>{ causacion.docProv }</td>
                                        <td>${ formatNumber(causacion.totalBruto) }</td>
                                        <td>${ formatNumber(causacion.totalNeto) }</td>
                                    </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                    <br />
                    <br />
                    { retenciones.length > 0 &&
                    <>
                    <SubTitle>Retenciones</SubTitle>
                    <table className='info-table'>
                        <thead>
                            <tr>
                                <th>CUENTA</th>
                                <th>DESCRIPCION</th>
                                <th>TASA</th>
                                <th>BASE</th>                                                        
                                <th>VALOR</th>                            
                            </tr>
                        </thead>
                        <tbody>
                            {
                                retenciones.map(retencion => {
                                    return(
                                    <tr>
                                        <td>{ retencion.cuenta }</td>
                                        <td>{ retencion.cuentaDescripcion }</td>
                                        <td>{ formatNumber(retencion.tasa) }%</td>
                                        <td>${ formatNumber(retencion.valorBase) }</td>
                                        <td>${ formatNumber(retencion.valor) }</td>
                                    </tr>
                                    );
                                })
                            }
                            <tr>
                                <td>TOTAL:</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>${ formatNumber(totalRetenciones) }</td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <br />
                    </>
                    }
                    <SubTitle>Detalle del documento</SubTitle>
                    <table className='info-table'>
                        <thead>
                            <tr>
                                <th>DOCUMENTO</th>
                                <th>DOCUMENTO CRUCE</th>
                                <th>FECHA</th>
                                <th>TOTAL</th>
                                <th>DETALLE</th>
                                <th>VER</th>
                                {/*<th>DESCARGAR</th>*/}
                            </tr>
                        </thead>
                        <tbody>
                            { 
                                documentos.data.map(
                                    documento => {
                                        return(
                                            <tr key={documento.id}>
                                                <td>{ documento.co + '-' + documento.tipoDoc + '-' + documento.numDoc }</td>
                                                <td>{ documento.numDocProv }</td>
                                                <td>{ formatDateMin( new Date(documento.fechaDoc) ) }</td>
                                                <td>${ formatNumber(documento.totalNeto) }</td>
                                                <td>{ documento.detalle }</td>
                                                <td><div className='view-icon-container'><Link to={{ pathname: '/portal/documento-comercial-detalle', state: { documento: documento } }}><FontAwesomeIcon className='view-icon' icon={faEye} /></Link></div></td>
                                                {/*<td><div className='pdf-icon-container'><Link style={{ color: 'red' }} onClick={(e) => { generarPdf(e, documento) }}><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></Link></div></td>*/}
                                            </tr>
                                        );
                                    }
                                )
                            }
                        </tbody>
                    </table>
                </div>
                <div className='mobile-view'>
                    { causaciones.length > 0 &&
                    <>
                    <SubTitle>Documento</SubTitle>
                    <div className='document-head-container'>
                        <div className='card-fields-container'>
                            <div className='card-field-container'>
                                <div className='card-field-title'>{ AppReducer.company.name }</div>
                            </div>
                            <br />
                            <div className='card-field-container'>
                                <div className='card-field-title'>PROVEEDOR:</div>
                            </div>
                            <div className='card-field-container'>
                                <div className='card-field-title'>N.I.T:</div>
                                <div>{ AppReducer.supplier.nit }</div>
                            </div>
                            <div className='card-field-container'>
                                <div className='card-field-title'>RAZÓN SOCIAL:</div>
                                <div>{ AppReducer.supplier.razonSocial }</div>
                            </div>
                            <br />
                            <div className='card-field-container'>
                                <div className='card-field-title'>CENTRO DE OPERACIÓN:</div>
                                <div>{ causaciones[0].co }</div>
                            </div>
                            <div className='card-field-container'>
                                <div className='card-field-title'>FECHA DEL DOCUMENTO:</div>
                                <div>{ formatDateMin( new Date(causaciones[0].fechaDoc) ) }</div>
                            </div>
                            <div className='card-field-container'>
                                <div className='card-field-title'>DOCUMENTO:</div>
                                <div>{ causaciones[0].tipoDoc + '-' + causaciones[0].numDoc }</div>
                            </div>
                            <div className='card-field-container'>
                                <div className='card-field-title'>DOCUMENTO PROVEEDOR:</div>
                                <div>{ causaciones[0].docProv }</div>
                            </div>
                            <div className='card-field-container'>
                                <div className='card-field-title'>VALOR BRUTO:</div>
                                <div>${ formatNumber(causaciones[0].totalBruto) }</div>
                            </div>
                            <div className='card-field-container'>
                                <div className='card-field-title'>VELOR NETO:</div>
                                <div>${ formatNumber(causaciones[0].totalNeto) }</div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <SubTitle>Detalle</SubTitle>
                    <div className='cards-container'>
                        {
                            documentos.data.map(
                                (documento, index) => {
                                    return(
                                        <Link to={{ pathname: '/portal/documento-comercial-detalle', state: { documento: documento } }}><DocumentCard key={index} document={documento} /></Link>
                                    );
                                }
                            )
                        }
                    </div>
                    </>
                    }
                </div>
                </>
                }
            </div>
        </div>
    );
}

export default CausacionDetalleView
