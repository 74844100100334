import React, { useState, useEffect } from 'react';
import Title from '../../components/title/Title';
import SubTitle from '../../components/subTitle/SubTitle';
import Card from '../../components/Card';
import { formatNumber } from '../../misc/index';
import './Reports.css';

const InventoryReportDetailCard = ({ AppReducer, inventory, detail }) => {
    return(
        <Card>
            <div className='card-fields-container egresos-cards-container'>
                <div className='card-field-container'>
                    <div className='card-field-title'>NIT:</div>
                    <div className='card-field-data'>{ AppReducer.company.id }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>AÑO:</div>
                    <div>{ inventory.year }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>MES</div>
                    <div>{ inventory.month }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>CODIGO DE BARRAS</div>
                    <div>{ detail.codBar }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>CODIGO PRODUCTO</div>
                    <div>{ detail.idItem }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>UNIDAD DE MEDIDA</div>
                    <div>{ detail.um }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>INVENTARIO CANTIDAD</div>
                    <div>${ formatNumber(detail.stockQty) }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>INVENTARIO PESOS</div>
                    <div>${ formatNumber(detail.stockCost) }</div>
                </div>
            </div>
        </Card>
    );
}

const SaleReportDetailCard = ({ AppReducer, sale, detail }) => {
    return(
        <Card>
            <div className='card-fields-container egresos-cards-container'>
                <div className='card-field-container'>
                    <div className='card-field-title'>NIT:</div>
                    <div className='card-field-data'>{ AppReducer.company.id }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>AÑO:</div>
                    <div>{ sale.year }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>MES</div>
                    <div>{ sale.month }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>CENTRO DE OPERACIÓN</div>
                    <div>{ detail.co }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>CODIGO DE BARRAS</div>
                    <div>{ detail.codBar }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>CODIGO PRODUCTO</div>
                    <div>{ detail.idItem }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>UNIDAD DE MEDIDA</div>
                    <div>{ detail.um }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>VENTA CANTIDAD</div>
                    <div>${ formatNumber(detail.saleQty) }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>VENTA PESOS</div>
                    <div>${ formatNumber(detail.saleCost) }</div>
                </div>
            </div>
        </Card>
    );
}

const ReportsButton = ({ className, children, onClick, name }) => 
    <button className={className} onClick={() => { onClick(name) }} >{children}</button>

const ReportsView = ({ AppReducer, reportState, filters, inventory, sale, onTypeReportClick, onSelectYearChange, onSelectMonthChange, onSubmit, onDownloadClick }) => {
    console.log('lenght', inventory.data.length);
    return(
        <div className='page-container'>
            <Title>Reportes</Title>
            <br />
            <div className='reports-type-container'>
                <ReportsButton name='INVENTARIOS' className={ reportState.inventarios ? 'reports-active-button' : 'reports-button' } onClick={onTypeReportClick} >Inventarios</ReportsButton>
                <ReportsButton name='VENTAS' className={ reportState.ventas ? 'reports-active-button' : 'reports-button' } onClick={onTypeReportClick} >Ventas</ReportsButton>
            </div>
            <br />
            <br />
            <div>
                <SubTitle>{reportState.inventarios ? 'Reporte de inventarios' : 'Reporte de ventas'}</SubTitle>
            </div>
            <form className='reports-form' onSubmit={onSubmit}>
                <div className='reports-select-container'>
                    <label>Año:</label>
                    <select className='reports-select' value={filters.year} onChange={onSelectYearChange}>
                        <option value='2023'>2023</option>
                    </select>
                </div>
                <div className='reports-select-container'>
                    <label htmlFor='mes'>Mes:</label>
                    <select name='mes' id='mes' className='reports-select' value={filters.month} onChange={onSelectMonthChange}>
                        <option value='1'>ENERO</option>
                        <option value='2'>FEBRERO</option>
                        <option value='3'>MARZO</option>
                        <option value='4'>ABRIL</option>
                        <option value='5'>MAYO</option>
                        <option value='6'>JUNIO</option>
                        <option value='7'>JULIO</option>
                        <option value='8'>AGOSTO</option>
                        <option value='9'>SEPTIEMBRE</option>
                        <option value='10'>OCTUBRE</option>
                        <option value='11'>NOVIEMBRE</option>
                        <option value='12'>DICIEMBRE</option>
                    </select>
                </div>
                <input type='submit' value='Consultar' />
            </form>
            <br />
            <div className='desktop-view'>
                { (inventory.data.length > 0 || sale.data.length > 0) &&
                <div>
                    <button onClick={onDownloadClick}>Descargar xlsx</button>
                </div>
                }
                { (reportState.inventarios && inventory.data.length > 0) &&
                <div className='reports-inventario'>
                    <table className='info-table'>
                        <thead>
                            <tr>
                                <th>NIT</th>
                                <th>AÑO</th>
                                <th>MES</th>
                                <th>CODIGO DE BARRAS</th>
                                <th>CODIGO PRODUCTO</th>
                                <th>UNIDAD DE MEDIDA</th>
                                <th>INVENTARIO CANTIDAD</th>
                                <th>INVENTARIO PESOS</th>
                            </tr>
                        </thead>
                        <tbody>
                            { 
                                inventory.data.map(
                                    (reg, index) => {
                                        return(
                                            <tr key={index}>
                                                <td>{ AppReducer.company.id }</td>
                                                <td>{ inventory.year }</td>
                                                <td>{ inventory.month }</td>
                                                <td>{ reg.codBar }</td>
                                                <td>{ reg.idItem }</td>
                                                <td>{ reg.um }</td>
                                                <td>{ formatNumber(reg.stockQty) }</td>
                                                <td>${ formatNumber(reg.stockCost) }</td>
                                            </tr>
                                        );
                                    }
                                )
                            }
                        </tbody>
                    </table>
                </div>
                }
                { reportState.ventas &&
                <div className='reports-ventas'>
                    <table className='info-table'>
                        <thead>
                            <tr>
                                <th>NIT</th>
                                <th>AÑO</th>
                                <th>MES</th>
                                <th>CENTRO DE OPERACION</th>
                                <th>CODIGO DE BARRAS</th>
                                <th>CODIGO PRODUCTO</th>
                                <th>UNIDAD DE MEDIDA</th>
                                <th>VENTA CANTIDAD</th>
                                <th>VENTA PESOS</th>
                            </tr>
                        </thead>
                        <tbody>
                            { 
                                sale.data.map(
                                    (reg, index) => {
                                        return(
                                            <tr key={index}>
                                                <td>{ AppReducer.company.id }</td>
                                                <td>{ sale.year }</td>
                                                <td>{ sale.month }</td>
                                                <td>{ reg.co }</td>
                                                <td>{ reg.codBar }</td>
                                                <td>{ reg.idItem }</td>
                                                <td>{ reg.um }</td>
                                                <td>{ formatNumber(reg.saleQty) }</td>
                                                <td>${ formatNumber(reg.saleCost) }</td>
                                            </tr>
                                        );
                                    }
                                )
                            }
                        </tbody>
                    </table>
                </div>
                }
            </div>
            <div className='mobile-view'>
                { ((reportState.inventarios && inventory.data.length > 0) || (reportState.ventas && sale.data.length > 0)) &&
                <div>
                    <button onClick={onDownloadClick}>Descargar xlsx</button>
                </div>
                }
                <div className='cards-container'>
                { reportState.inventarios &&
                    inventory.data.map(
                        (reg, index) => {
                            return(
                                <InventoryReportDetailCard key={index} AppReducer={AppReducer} inventory={inventory} detail={reg} />
                            );
                        }
                    )
                }
                { reportState.ventas &&
                    sale.data.map(
                        (reg, index) => {
                            return(
                                <SaleReportDetailCard key={index} AppReducer={AppReducer} sale={sale} detail={reg} />
                            );
                        }
                    )
                }
                </div>
            </div>
        </div>
    );
}

export default ReportsView;