import React, { useState, useEffect } from 'react';
import CertificadoDetalleView from './CertificadoDetalleView';
import { httpGet } from '../../http';
import { getYear } from '../../misc';

import { connect } from 'react-redux';
import AppReducer from '../../redux/reducers/AppReducer';

const CertificadoDetalle = ({ AppReducer, history }) => {

    const [retencion, setRetencion ] = useState(
        {
            empresa: AppReducer.company,
            proveedor: {
                codigo: '',
                deigitoVerificacion: '',
                descripcion: '',
                direccion: '',
                ciudad: ''
            },
            data: [],
            total: {
                base: 0,
                retencion: 0
            },
            tipo: {
                codigo: '',
                titulo: '',
                periodoGravable: '',
                ciudad: '',
                fechaDesde: '',
                fechaHasta: '',
                visible: false
            },
            loading: false,
            showNoDataMsg: false,
            error: {}
        }
    );

    useEffect(() => {

        const type = history.location.state.type;
        const tipo = setType();
        
        setRetencion({
            ...retencion,
            tipo: tipo,
            reteIva: false,
        });
        
        if(type !== 'RETEIVA'){
        } else {
            //getReteIVA();
        }
        
    }, []);

    const getReteFuente = async () => {
        setRetencion({
            ...retencion,
            loading: true,
            tipo: {
                ...retencion.tipo,
                visible: true,
            }
        });

        console.log("URL:");
        console.log(retencion.tipo.url);

        const certificado = await httpGet(retencion.tipo.url  + '&fecha1=' + retencion.tipo.fechaDesde.replace(/-/g, '') + "&fecha2=" + retencion.tipo.fechaHasta.replace(/-/g, ''));
        const tercero = await httpGet('/api/v1/terceros/nit/' + AppReducer.supplier.nit);

        console.log('CERTIFICADO');
        console.log(certificado);
        console.log('TERCERO', tercero);
        
        let totalBase = 0;
        let totalRetencion = 0;

        if(certificado.data.length > 0){
            const pr = {
                nit: tercero.data.id,
                razonSocial: tercero.data.razonSocial,
                direccion: tercero.data.direccion,
                ciudad: tercero.data.ciudad
            }

            console.log('PROVEEDOR', pr);
    
            for( const reg of certificado.data){
                totalBase = totalBase + reg.base;
                totalRetencion = totalRetencion + reg.retencion;
            }
    
            let total = {
                base: totalBase,
                retencion: totalRetencion
            }
    
            if((certificado !== null) && (certificado !== undefined)){
                const type = history.location.state.type;
                setRetencion({
                    ...retencion,
                    proveedor: pr,
                    data: certificado.data,
                    total: total,
                    loading: false,                
                    tipo: {
                        ...retencion.tipo,
                        periodoGravable: 'Periodo gravable del: ' + retencion.tipo.fechaDesde + ' al: ' + retencion.tipo.fechaHasta,
                        visible: true,
                    }
                });
            }
        } else {
            setRetencion({
                ...retencion,
                loading: false,                
                showNoDataMsg: true,
                tipo: {
                    ...retencion.tipo,
                    visible: true
                }
            });
        }
        
    }

    

    const setType = () => {
        const type = history.location.state.type;
        let tipo = {};

        switch(type){
            case 'RETEFUENTE':
                tipo = {
                    codigo: type,
                    titulo: 'Certificado de retención en la fuente',
                    periodoGravable: 'Año gravable: ' + getYear(1),
                    url: '/api/v1/retenciones?nit=' + AppReducer.supplier.nit + '&cuenta=2365%',
                    ciudad: AppReducer.company.city,
                    fechaDesde: '',
                    fechaHasta: '',
                    visible: false
                }
                break;
            case 'RETEICA':
                tipo = {
                    codigo: type,
                    titulo: 'Certificado de retención Industria, Comercio y Avisos (I.C.A.)',
                    periodoGravable: 'Año gravable: ' + getYear(1) ,
                    url: '/api/v1/retenciones?nit=' +  AppReducer.supplier.nit + '&cuenta=2368%',
                    ciudad: AppReducer.company.city,
                    fechaDesde: '',
                    fechaHasta: '',
                    visible: false
                }
                break;
            case 'RETEIVA':
                tipo = {
                    codigo: type,
                    titulo: 'Certificado de retención I.V.A.',
                    periodoGravable: 'Periodo gravable del: ' + retencion.tipo.fechaDesde + ' al: ' + retencion.tipo.fechaHasta,
                    url: '/api/v1/retenciones?nit=' +  AppReducer.supplier.nit + '&cuenta=2367%',
                    ciudad:  AppReducer.company.city,
                    visible: false
                }
                break;
        }

        return tipo;
    }

    const handleChange = (e) => {
        setRetencion({
            ...retencion,
            tipo: {
                ...retencion.tipo,
                [e.target.name]: e.target.value
            }
        });
    }

    const handleClick = () => {
        console.log("FECHA DESDE:")
        console.log(retencion.tipo.fechaDesde.replace(/-/g, ''));
        console.log("FECHA HASTA:")
        console.log(retencion.tipo.fechaHasta.replace(/-/g, ''));

        if((retencion.tipo.fechaDesde.length > 0) && (retencion.tipo.fechaHasta.length > 0)){
            getReteFuente();
        } else {
            alert("Por favor ingrese las fechas correctamente.")
        }
    }

    return(
        <>
        <CertificadoDetalleView AppReducer={AppReducer} retencion={retencion} onClick={handleClick} onChange={handleChange} />
        </>
    );
}

const mapStateToProps = ({ AppReducer }) => {
    return {
        AppReducer
    };
};

export default connect(mapStateToProps)(CertificadoDetalle);
