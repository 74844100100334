import { combineReducers } from 'redux';
import AppReducer from './AppReducer';
import InvoicesReducer from './InvoicesReducer';
import MenuReducer from './MenuReducer';
import AdminReducer from './AdminReducer';

export default combineReducers({
    AppReducer,
    InvoicesReducer,
    MenuReducer,
    AdminReducer
});