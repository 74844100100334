import React, { useState, useEffect } from 'react';
import { httpGet } from '../../http';
import NotasComercialView from './NotasComercialView';
import { devs } from '../../demo';

import { connect } from 'react-redux';
import AppReducer from '../../redux/reducers/AppReducer';

const NotasComercial = ({ AppReducer, history }) => {

    const { co } = history.location.state;

    const [notas, setNotas ] = useState(
        {
            co: {
                codigo: '',
                nombre: ''
            },
            data: [],
            loading: false,
            error: {}
        }
    );

    useEffect(() => {

        getNotas(AppReducer.supplier.nit);

    }, []);

    const getNotas = async (tercero) => {
        setNotas({
            ...notas,
            loading: true
        });
        
        const np = await httpGet('/api/v1/notas/nit/' + co.codigo + '/' + tercero);

        if((np !== null) && (np !== undefined)){
            setNotas({
                ...notas,
                co: co,
                data: np.data,
                loading: false
            });
        }
    }

    return(
        <NotasComercialView AppReducer={AppReducer} notas={notas} />
    );
}

const mapStateToProps = ({ AppReducer }) => {
    return {
        AppReducer
    };
};

export default connect(mapStateToProps)(NotasComercial);
