import React, { useState, useEffect} from 'react';
import { httpGet } from '../../http';
import EgresoDetalleView from './EgresoDetalleView';
import EgresoPDFView from '../EgresoPDF/EgresoPDFView';
import { pdf } from '@react-pdf/renderer';
import PDFMerger from 'pdf-merger-js';

import { connect } from 'react-redux';
import AppReducer from '../../redux/reducers/AppReducer';

const EgresoDetalle = ({ history, AppReducer }) => {

    const [egreso, setEgreso ] = useState(
        {
            empresa: AppReducer.company,
            proveedor: AppReducer.supplier,
            encabezado: {
                tipoDoc: '',
                numeroDoc: '',
                fechaDoc: new Date(),
                fechaVcto: '',
                valor: '',
                debitos: '',
                creditos: '',
                detalle: '',
            },
            detalles: [],
            retenciones: [],
            totalRetenciones: 0,
            loading: false,
            error: {},
            reg: 0
        }
    );

    useEffect(() => {

        if(!history.location.state.egreso){
            history.push('/portal');
            return 0;
        }
        
        getEgresoDetalle(history.location.state.egreso);

    }, []);

    const getEgresoDetalle = async (egreso2) => {
        setEgreso({
            ...egreso,
            loading: true
        });
        
        const encabezado = await httpGet('/api/v1/egresos/encabezado/' + egreso2.idCo + '/' + egreso2.tipoDoc + '/' + egreso2.numDoc);
        const retenciones = await httpGet('/api/v1/egresos/retenciones/' + egreso2.idCo + '/' + egreso2.tipoDoc + '/' + egreso2.numDoc);
        const detalles = await httpGet('/api/v1/egresos/documentos/' + egreso2.idCo + '/' + egreso2.tipoDoc + '/' + egreso2.numDoc);
        /*console.log(encabezado);
        console.log(detalles);
        console.log(retenciones);*/

        let totalRetenciones = 0;
        
        for( const retencion of retenciones.data){
            totalRetenciones = totalRetenciones + retencion.valor;
        }

        setEgreso({
            ...egreso,
            encabezado: encabezado.data[0],
            retenciones: retenciones.data,
            totalRetenciones: totalRetenciones,
            detalles: detalles.data,
            loading: false
        });
    }

    const getFile = async () => {
        const res = await fetch('/soporte.pdf');
        const blob = res.blob();
        return blob;
    }

    const mergePDF = async () => {
        const docPDF = <EgresoPDFView egreso={ egreso } />;
        const asPdf = pdf();
        asPdf.updateContainer(docPDF);

        let appendixBlob = await getFile();
        let initialBlob = await asPdf.toBlob();

        let merger = new PDFMerger();

        await merger.add(initialBlob);
        await merger.add(appendixBlob);

        const mergedPdf = await merger.saveAsBlob();
        console.log('merge', mergedPdf);

        //setPDFDocument(URL.createObjectURL(mergedPdf));
        await merger.save('egreso-' + egreso.encabezado.tipoDoc + '-' + egreso.encabezado.numeroDoc + '.pdf');
    }

    const generarEgresoDetallePdf = async () => {
        let newEgreso = {
            empresa: AppReducer.company,
            proveedor: AppReducer.supplier,
            encabezado: egreso.encabezado,
            retenciones: egreso.retenciones,
            detalles: egreso.detalles
        }

        console.log(newEgreso);

        history.push({ pathname: '/portal/egreso-pdf', state: { egreso: newEgreso } });
    }

    return(
        <>
        <EgresoDetalleView AppReducer={AppReducer} egreso={egreso} generarEgresoDetallePdf={generarEgresoDetallePdf} downloadPDFFile={mergePDF} />
        </>
    );
}

const mapStateToProps = ({ AppReducer }) => {
    return {
        AppReducer
    };
};

export default connect(mapStateToProps)(EgresoDetalle);
