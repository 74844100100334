import React from 'react';
import './Card.css';

const CardView = ({ children }) => {
    return(
        <div className="card-container">
            { children }
        </div>
    );
}

export default CardView;