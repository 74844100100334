import React, { useState, useEffect } from 'react';
import WelcomeView from './WelcomeView';
import './Welcome.css';

const Welcome = ({ history }) => {
    
    useEffect(() => {
        
    }, []);

    const goTo = (opt) => {
        if (opt === 1) {
            history.push('/login');
        } else {
            history.push('/signup');
        }
    }

    return(
        <WelcomeView onButtonClick={goTo} />
    );
}

export default Welcome;