import React, { useState, useEffect } from 'react';
import UploadDocsView from './UploadDocsView';
import { documents } from '../../../demo';

const UploadDocs = () => {

    const [ form, setForm ] = useState({
        idCo: '',
        tipoDoc: '',
        numeroDoc: ''
    });
    
    const [ document, setDocument ] = useState({
        idCo: '',
        tipoDoc: '',
        numeroDoc: '',
        valor: 0
    });

    const [ loading, setLoading ] = useState(false);

    const [ showFiles, setShowFiles ] = useState(false);

    const handleClick = async () => {

        setLoading(true);

        setDocument({
            idCo: '',
            tipoDoc: '',
            numeroDoc: '',
            valor: 0
        });

        const doc = documents.find((element) => {
            return element.idCo === form.idCo && element.tipoDoc === form.tipoDoc && element.numeroDoc === form.numeroDoc
        });
        console.log(documents);
        console.log('DOC', doc);

        if (doc) {
            setDocument({
                ...document,
                idCo: doc.idCo,
                tipoDoc: doc.tipoDoc,
                numeroDoc: doc.numeroDoc,
                valor: doc.valor
            });

            setForm({
                ...form,
                idCo: '',
                tipoDoc: '',
                numeroDoc: ''
            });
        }

        setLoading(false);
    }

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    }

    const handleAddClick = () => {
        setShowFiles(true);
    }

    return(
        <UploadDocsView loading={loading} form={form} document={document} showFiles={showFiles} onClick={handleClick} onChange={handleChange} onAddClick={handleAddClick} />
    );
}

export default UploadDocs;