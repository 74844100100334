import React, { useState, useEffect } from 'react';
import Title from '../../components/title/Title';
import './Home.css';
import HomeView from './HomeView';

import { connect } from 'react-redux';
import AppReducer from '../../redux/reducers/AppReducer';


const Home = ({ AppReducer }) => {
   
    useEffect(() => {

    }, []);

    return(
        <HomeView
            AppReducer={AppReducer}
        />
    );
}

const mapStateToProps = ({ AppReducer }) => {
    return {
        AppReducer
    };
};

export default connect(mapStateToProps)(Home);