const INITIAL_STATE = {
    date1: '',
    date2: '',
    invoices: [],
    loading: false,
    error: {
        state: false,
        msg: ''
    }
};

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case "SET_INVOICES_LOADING_ON":
            return {...state, loading: true}
        case "SET_INVOICES_LOADING_OFF":
            return {...state, loading: false}
        case "SET_INVOICES_DATE1":
            return {...state, date1: action.payload}
        case "SET_INVOICES_DATE2":
            return {...state, date2: action.payload}
        case "SET_INVOICES_DATA":
            return {...state, invoices: action.payload}
        case "SET_INVOICES_ERROR":
            return {...state, loading: false, error: action.payload}
                                
            default: return state;
    }
}