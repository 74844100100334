import React, { useState, useEffect } from "react";
import Title from '../../components/title/Title';
import StateIcon from "../../components/stateIcon/StateIcon";
import SubTitle from "../../components/subTitle/SubTitle";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatNumber, formatDateMin } from '../../misc/index';
import Card from "../../components/Card";

import './DetalleSaldo.css';

const DocumentCard = ({ document }) => {
    return(
        <Card>
            <div className='card-fields-container'>
                <div className='card-field-container'>
                    <div className='card-field-title'>CENTRO DE OPERACIÓN:</div>
                    <div className='card-field-data'>{ document.idCo }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>FECHA:</div>
                    <div>{ formatDateMin(new Date(document.fechaDoctoCruce)) }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>DOCUMENTO:</div>
                    <div>{ document.idTipoDoc + '-' + document.consecutivoDoc }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>DETALLE:</div>
                    <div>{ document.notas }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>DEBITOS:</div>
                    <div>${ formatNumber(document.valorDb) }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>CREDITOS:</div>
                    <div>${ formatNumber(document.valorCr) }</div>
                </div>
            </div>
        </Card>
    );
}

const DetalleSaldoView = ({ factura, detalles, request, onViewClick }) => {
    return (
        <div className='page-container'>
            <Title>Detalle de saldo</Title>
            {request.loading ?
                <div className='loading-spinner'>
                    <Spinner animation="border" variant="primary" />
                </div>
            :
                <div className='page-body'>
                    <div className="desktop-view">
                        <table className='info-table'>
                            <thead>
                                <tr>
                                    <th>CENTRO DE OPERACION</th>
                                    <th>DOCUMENTO</th>
                                    <th>FECHA PROVEEDOR</th>
                                    <th>FECHA DE VENCIMIENTO</th>
                                    <th>VALOR DESCUENTO P. PAGO</th>
                                    <th>VALOR SALDO</th>
                                    <th>VALOR PAGO</th>
                                    <th>ESTADO</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{ factura.idCoCruce }</td>
                                    <td>{ factura.prefijoCruce + '-' + factura.consecutivoDocCruce }</td>
                                    <td>{ formatDateMin(new Date(factura.fechaDocProveedor)) }</td>
                                    <td>{ formatDateMin(new Date(factura.fechaVcto)) }</td>
                                    <td>${ formatNumber(factura.valorDescuentoPP) }</td>
                                    <td>${ formatNumber(factura.valorFactura) }</td>
                                    <td>${ formatNumber(factura.valorPago) }</td>
                                    <td><StateIcon estado={factura} /></td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <br />
                        <SubTitle>Detalle de saldo</SubTitle>
                        <table className='info-table doc-table'>
                            <thead>
                                <tr>
                                    <th>REG.</th>
                                    <th>CO</th>
                                    <th>FECHA</th>
                                    <th>DOCUMENTO</th>
                                    <th>DETALLE</th>
                                    <th>DEBITOS</th>
                                    <th>CREDITOS</th>
                                    <th>VER</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    detalles.map(
                                        (detalle, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td>{ index + 1 }</td>
                                                    <td>{ detalle.idCo }</td>
                                                    <td>{ formatDateMin(new Date(detalle.fechaDoctoCruce)) }</td>
                                                    <td>{ detalle.idTipoDoc + '-' + detalle.consecutivoDoc }</td>
                                                    <td>{ detalle.notas }</td>
                                                    <td>${ formatNumber(detalle.valorDb) }</td>
                                                    <td>${ formatNumber(detalle.valorCr) }</td>
                                                    <td>{ (detalle.valorDb > 0 && detalle.isEgress === 1) && <div className='view-icon-container'><Link to={{ pathname: "" }} onClick={(e) => { onViewClick(e, detalle) }}><FontAwesomeIcon className='view-icon' icon={faEye} /></Link></div>}</td>
                                                </tr>
                                            );
                                        }
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="mobile-view">
                        <SubTitle>Documento</SubTitle>
                        <div className='document-head-container'>
                            <div className='card-fields-container'>
                                <div className='card-field-container'>
                                    <div className='card-field-title'>CENTRO DE OPERACIÓN:</div>
                                    <div>{ factura.idCoCruce }</div>
                                </div>
                                <div className='card-field-container'>
                                    <div className='card-field-title'>DOCUMENTO:</div>
                                    <div>{ factura.prefijoCruce + '-' + factura.consecutivoDocCruce }</div>
                                </div>
                                <div className='card-field-container'>
                                    <div className='card-field-title'>FECHA PROVEEDOR:</div>
                                    <div>{ formatDateMin(new Date(factura.fechaDocProveedor)) }</div>
                                </div>
                                <div className='card-field-container'>
                                    <div className='card-field-title'>FECHA DE VENCIMIENTO:</div>
                                    <div>{ formatDateMin(new Date(factura.fechaVcto)) }</div>
                                </div>
                                <div className='card-field-container'>
                                    <div className='card-field-title'>VALOR DESCUENTO P. PAGO:</div>
                                    <div>${ formatNumber(factura.valorDescuentoPP) }</div>
                                </div>
                                <div className='card-field-container'>
                                    <div className='card-field-title'>VALOR SALDO:</div>
                                    <div>${ formatNumber(factura.valorFactura) }</div>
                                </div>
                                <div className='card-field-container'>
                                    <div className='card-field-title'>VALOR PAGO:</div>
                                    <div>${ formatNumber(factura.valorPago) }</div>
                                </div>
                            </div>
                            <div className='card-icon-container'>
                                <StateIcon estado={factura} />
                            </div>
                        </div>
                        <br />
                        <SubTitle>Detalle</SubTitle>
                        <div className='cards-container'>
                            {
                                detalles.map(
                                    (detalle, index) => {
                                        return(
                                            detalle.valorDb > 0 && <Link to={{ pathname: "" }} onClick={(e) => { onViewClick(e, detalle) }}><DocumentCard key={index} document={detalle} /></Link>
                                        );
                                    }
                                )
                            }
                        </div>
                    </div>
                    <br />
                    <br />
                </div>
            }
        </div>
    );
}

export default DetalleSaldoView;