import React, { useState } from 'react';
import ReportsView from './ReportsView';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { inventories, sales } from '../../demo';

import { connect } from 'react-redux';
import AppReducer from '../../redux/reducers/AppReducer';

const Reports = ({ AppReducer }) => {

    const [ reportState, setReportState ] = useState({
        inventarios: true,
        ventas: false
    });

    const [ filters, setFilters ] = useState({
        year: '2023',
        month: '1'
    });

    const [ inventory, setInventory ] = useState({
        year: '',
        month: '',
        data: []
    });

    const [ sale, setSale ] = useState({
        year: '',
        month: '',
        data: []
    });

    const handleTypeReportClick = (buttonName) => {
        if (buttonName === 'INVENTARIOS') {
            setReportState({
                inventarios: true,
                ventas: false
            })
        } else {
            setReportState({
                inventarios: false,
                ventas: true
            })
        }
    }

    const handleSelectYearChange = (e) => {
        setFilters({
            ...filters,
            year: e.target.value
        });
    }

    const handleSelectMonthChange = (e) => {
        setFilters({
            ...filters,
            month: e.target.value
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (reportState.inventarios) {
            const data = inventories.find((element) => {
                return element.year === filters.year && element.month === filters.month
            });

            setInventory(data);
        } else if (reportState.ventas) {
            const data = sales.find((element) => {
                return element.year === filters.year && element.month === filters.month
            });

            setSale(data);
        }
    }

    const formatData = (data) => {
        let newData = [];
        if (reportState.inventarios){
            
            for (const reg of data.data){
        
                let newReg = {
                    periodo: data.year,
                    mes: data.month,
                    codigo_barras: reg.codBar,
                    codigo_producto: reg.idItem,
                    unidad_medida: reg.um,
                    inventario_cantidad: reg.stockQty,
                    inventario_pesos: reg.stockCost
                }
                newData.push(newReg)
            }
        } else if (reportState.ventas){
            for (const reg of data.data){
        
                let newReg = {
                    periodo: data.year,
                    mes: data.month,
                    centro_operacion: reg.co,
                    codigo_barras: reg.codBar,
                    codigo_producto: reg.idItem,
                    unidad_medida: reg.um,
                    venta_cantidad: reg.saleQty,
                    venta_pesos: reg.saleCost
                }
                newData.push(newReg)
            }
        }
        return newData;
    }
    
    const exportToExcel = () => {

        const sourceData = reportState.inventarios ? inventory : sale;

        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(formatData(sourceData));
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, (reportState.inventarios ? 'inventario' : 'ventas') + filters.year + filters.month + fileExtension);
    };

    const handleDownloadClick = () => {
        exportToExcel();
    }
   
    return(
        <ReportsView 
            AppReducer={AppReducer} 
            reportState={reportState} 
            filters={filters}
            inventory={inventory}
            sale={sale}
            onTypeReportClick={handleTypeReportClick} 
            onSelectYearChange={handleSelectYearChange} 
            onSelectMonthChange={handleSelectMonthChange}
            onSubmit={handleSubmit}
            onDownloadClick={handleDownloadClick}
        />
    );
}

const mapStateToProps = ({ AppReducer }) => {
    return {
        AppReducer
    };
};

export default connect(mapStateToProps)(Reports);