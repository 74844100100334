import React from 'react';
import Title from '../../components/title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import SubTitle from '../../components/subTitle/SubTitle';
import './EgresoDetalle.css';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { formatNumber, formatDateMin } from '../../misc/index';
import { PDFDownloadLink } from '@react-pdf/renderer';
import EgresoPDFView from '../EgresoPDF/EgresoPDFView';
import Card from '../../components/Card';
import PDFMerger from 'pdf-merger-js';

const DocumentCard = ({ document }) => {
    return(
        <Card>
            <div className='card-fields-container egreso-detalle-card-fields-container'>
                <div className='card-field-container'>
                    <div className='card-field-title'>CENTRO DE OPERACIÓN:</div>
                    <div className='card-field-data'>{ document.coDoc }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>CUENTA:</div>
                    <div>{ document.idCuenta }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>DOCUMENTO:</div>
                    <div>{ document.idCuenta.startsWith('13') ? document.tipoDocCruce + '-' + document.numDocCruce : document.tipoDocProv + '-' + document.numDocProv }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>DEBITOS:</div>
                    <div>${ formatNumber(document.debitos) }</div>
                </div>
                <div className='card-field-container'>
                    <div className='card-field-title'>CREDITOS:</div>
                    <div>${ formatNumber(document.creditos) }</div>
                </div>
            </div>
            <div className='card-icon-container'>
                <div>{ document.idCuenta.substring(0, 2) === '22' && <div className='view-icon-container'><FontAwesomeIcon className='view-icon' icon={faEye} /></div>}</div>
            </div>

        </Card>
    );
}
 

const EgresoDetalleView = ({ AppReducer, egreso, generarEgresoDetallePdf, downloadPDFFile }) => {

    return(
        <div className='page-container'>
            <Title>Detalle de egreso</Title>
            <div className='page-body'>
                {egreso.loading ?
                <div className='loading-spinner'>
                    <Spinner animation="border" variant="primary" />
                </div>
                :
                <>
                <div className='desktop-view'>
                    <SubTitle>Empresa: { AppReducer.company.name }</SubTitle>
                    <SubTitle>Proveedor:</SubTitle>
                    <div>
                        <div><span className='info-title'>N.I.T: </span><span>{ AppReducer.supplier.nit }</span></div>
                        <div><span className='info-title'>Razón social: </span><span>{ AppReducer.supplier.razonSocial }</span></div>
                    </div>
                    <br />
                    <br />
                    <SubTitle>Detalle del egreso</SubTitle>
                    <table className='info-table'>
                        <thead>
                            <tr>
                                <th>DOCUMENTO</th>
                                <th>FECHA DEL DOCUMENTO</th>
                                {/*<th>FECHA DE VENCIMIENTO</th>*/}
                                <th>VALOR DEL DOCUMENTO</th>                                                        
                                <th>DÉBITOS</th>
                                <th>CRÉDITOS</th>
                                <th>DETALLE</th>
                                <th>DESCARGAR</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{ egreso.encabezado.idCo + '-' + egreso.encabezado.tipoDoc + '-' + egreso.encabezado.numDoc }</td>
                                <td>{ formatDateMin(new Date(egreso.encabezado.fechaDoc)) }</td>
                                {/*<td>{ egreso.encabezado.fechaVcto }</td>*/}
                                <td>${ formatNumber(egreso.encabezado.valor) }</td>
                                <td>${ formatNumber(egreso.encabezado.debitos) }</td>
                                <td>${ formatNumber(egreso.encabezado.creditos) }</td>
                                <td>{ egreso.encabezado.notas }</td>
                                <td><div className='pdf-icon-container' onClick={generarEgresoDetallePdf}><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></div></td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <br />
                    { egreso.retenciones.length > 0 &&
                        <>
                            <SubTitle>Retenciones</SubTitle>
                            <table className='info-table'>
                                <thead>
                                    <tr>
                                        <th>CUENTA</th>
                                        <th>DESCRIPCIÓN</th>
                                        <th>TASA</th>
                                        <th>BASE</th>                                                        
                                        <th>VALOR</th>                            
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        egreso.retenciones.map(retencion => {
                                            return(
                                            <tr>
                                                <td>{ retencion.cuenta }</td>
                                                <td>{ retencion.cuentaDescripcion }</td>
                                                <td>{ formatNumber(retencion.tasa) }%</td>
                                                <td>${ formatNumber(retencion.valorBase) }</td>
                                                <td>${ formatNumber(retencion.valor) }</td>
                                            </tr>
                                            );
                                        })
                                    }
                                    <tr>
                                        <td>TOTAL:</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>${ formatNumber(egreso.totalRetenciones) }</td>
                                    </tr>
                                </tbody>
                            </table>
                            
                            <br />
                            <br />
                        </>
                    }
                    <table className='info-table doc-table'>
                        <thead>
                            <tr>
                                <th>REG.</th>
                                <th>CENTRO DE OPERACIÓN</th>
                                <th>CUENTA</th>
                                <th>DOCUMENTO CRUCE</th>
                                <th>DÉBITOS</th>
                                <th>CRÉDITOS</th>
                                <th>VER</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                egreso.detalles.map(
                                    detalle => {
                                        return(
                                            <tr key={detalle.id}>
                                                <td>{detalle.id}</td>
                                                <td>{ detalle.coDoc }</td>
                                                <td>{ detalle.idCuenta }</td>
                                                <td>{ detalle.idCuenta.startsWith('13') ? detalle.tipoDocCruce + '-' + detalle.numDocCruce : detalle.tipoDocProv + '-' + detalle.numDocProv }</td>
                                                <td>${ formatNumber(detalle.debitos) }</td>
                                                <td>${ formatNumber(detalle.creditos) }</td>
                                                <td>{ detalle.idCuenta.substring(0, 2) === '22' && <div className='view-icon-container'><Link to={{ pathname: detalle.docCM ? '/portal/causacion-detalle' : '/portal/nota-contable-detalle', state: { documento: detalle } }}><FontAwesomeIcon className='view-icon' icon={faEye} /></Link></div>}</td>
                                            </tr>
                                        );
                                    }
                                )
                            }
                        </tbody>
                    </table>
                </div>
                <div className='mobile-view'>
                    <SubTitle>Documento</SubTitle>
                    <div className='document-head-container'>
                        <div className='card-fields-container'>
                            <div className='card-field-container'>
                                <div className='card-field-title'>{ AppReducer.company.name }</div>
                            </div>
                            <br />
                            <div className='card-field-container'>
                                <div className='card-field-title'>PROVEEDOR:</div>
                            </div>
                            <div className='card-field-container'>
                                <div className='card-field-title'>N.I.T:</div>
                                <div>{ AppReducer.supplier.nit }</div>
                            </div>
                            <div className='card-field-container'>
                                <div className='card-field-title'>RAZÓN SOCIAL:</div>
                                <div>{ AppReducer.supplier.razonSocial }</div>
                            </div>
                            <br />
                            <div className='card-field-container'>
                                <div className='card-field-title'>DOCUMENTO:</div>
                                <div>{ egreso.encabezado.idCo + '-' + egreso.encabezado.tipoDoc + '-' + egreso.encabezado.numDoc }</div>
                            </div>
                            <div className='card-field-container'>
                                <div className='card-field-title'>FECHA DEL DOCUMENTO:</div>
                                <div>{ formatDateMin(new Date(egreso.encabezado.fechaDoc)) }</div>
                            </div>
                            <div className='card-field-container'>
                                <div className='card-field-title'>VALOR DEL DOCUMENTO:</div>
                                <div>${ formatNumber(egreso.encabezado.valor) }</div>
                            </div>
                        </div>
                    </div>
                    <br />
                    {/*<div className='download-link pdf-icon-container' onClick={generarEgresoDetallePdf}><FontAwesomeIcon className='view-icon' icon={faFilePdf} /> Descargar</div>*/}
                    
                    <PDFDownloadLink document={<EgresoPDFView egreso={egreso} />} fileName={ 'egreso-' + egreso.encabezado.tipoDoc + '-' + egreso.encabezado.numDoc + '.pdf' }>
                        {
                            ({ blob, url, loading, error }) => {
                                return(loading ? 'Cargando informe...' : <div className='download-link pdf-icon-container'><FontAwesomeIcon className='view-icon' icon={faFilePdf} /> Descargar</div>);
                            }
                        }
                    </PDFDownloadLink>
                    {/*<Link to={{ path: '' }} onClick={downloadPDFFile} ><div className='download-link pdf-icon-container'><FontAwesomeIcon className='view-icon' icon={faFilePdf} /> Descargar</div></Link>*/}
                    <br />
                    <SubTitle>Detalle</SubTitle>
                    <div className='cards-container'>
                        {
                            egreso.detalles.map(
                                (documento, index) => {
                                    return(
                                        <Link to={{ pathname: '/portal/causacion-detalle', state: { documento: documento } }}><DocumentCard key={index} document={documento} /></Link>
                                    );
                                }
                            )
                        }
                    </div>
                </div>
                </>
                }
            </div>
        </div>
    );
}

export default EgresoDetalleView
