import React, { useState, useEffect } from 'react';
import './Login.css';
import Button from '../../components/button/Button';
import { Alert, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const TextBox = (props) => {
    return(
        <input className='login-text-box' type={props.type} name={props.name} value={props.value} onChange={props.onChange} required />
    );
}

const LoginView = ({ reg = false, recovery = false, login, onSubmit, onChange }) => {

    return(
        <div>
            <div className='login-content'>
                <div className='login-body'>
                    <div className='login-notification-container'>
                        { reg &&
                            <Alert variant='success'>Se ha registrado correctamente, por favor ingrese.</Alert>
                        }
                        { recovery &&
                            <Alert variant='success'>Se ha enviado la nueva contraseña, por favor revise su correo</Alert>
                        }
                        { login.error &&
                            
                                <Alert variant='danger'>{ login.errorMsg }</Alert>        
                        }
                    </div>
                    <div>
                        <h2 className='login-h2'>Ingreso a la plataforma</h2>
                    </div>
                    <div>
                        <p className='login-p'>Ingrese su N.I.T. y su contraseña para acceder</p>
                    </div>
                    <form className='login-form' onSubmit={ onSubmit }>
                        <div className='login-field'>
                            <div className='login-field-name'>NIT:</div>
                            <TextBox type='text' name='nit' value={login.nit} onChange={ onChange } />
                        </div>
                        <div className='login-field'>
                            <div className='login-field-name'>Contraseña:</div>
                            <TextBox type='password' name='password' value={login.password} onChange={ onChange } />
                        </div>
                        <br />
                        <Button type='submit'>Ingresar</Button>
                    </form>
                </div>
                <div className='login-password-recovery'>
                        <Link style={{ color: 'blue' }} to='/recovery-password'>¿Olvido su contraseña?</Link>
                </div>
            </div>
        </div>
    );
}



export default LoginView;