import React, { useState, useEffect } from 'react';
import Title from '../../../components/title/Title';
import Button from '../../../components/button/Button';
import { httpGet, httpPut, httpPost } from '../../../http';
import { Alert, Spinner } from 'react-bootstrap';
import './Profile.css';

import { connect } from 'react-redux';
import AdminReducer from '../../../redux/reducers/AdminReducer';

const TextBox = (props) => {
    return(
        <input className='profile-text-box' type={props.type} name={props.name} value={props.value} onChange={props.onChange} required={ props.required } disabled={props.disabled} />
    );
}

const Field = (props) => {
    return(
        <div className='profile-field'>
            <div className='profile-field-name'>{ props.label }</div>
            <div><TextBox type={props.type} name={props.name} value={props.value} onChange={props.onChange} required={ props.required } disabled={ props.disabled } /></div>            <div className='profile-field-hint'>{ props.hint }</div>
        </div>
    );
}

const Profile = (props) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        admin: {
            email: '',
            nombre: '',
        },
        loading: true,
        error: {}
    });

    const [ form, setForm ] = useState({
        razonSocial: '',
        nit: '',
        email: '',
        email2: '',
        cel: '',
        password1: '',
        password2: '',
        error: false,
        errorMsg: '',
        loading: false
    });

    useEffect(() => {
        setForm({
            ...form,
            loading: true
        });

        setForm({
            _id: props.AdminReducer.admin._id,
            nombre: props.AdminReducer.admin.nombre,
            email: props.AdminReducer.admin.email,
            password1: '',
            password2: '',
            loading: false,
            success: false
        });

    }, []);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    }

    const valNit = async (nit) => {
        const val = await httpGet('/api/v1/admin-user/val/nit/' + nit);
        return val;
    }

    const valEmail = async (email) => {
        const val = await httpGet('/api/v1/admin-user/email/' + email);
        return val;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const oldAdmin = props.AdminReducer.admin;
        console.log(oldAdmin);
        try{

            setForm({
                ...form,
                error: false,
                errorMsg: '',
                loading: true,
                success: false
            });
    
            if(form.email != oldAdmin.email){
                let vEmail = await valEmail(form.email);
                if (vEmail.data) {
                    setForm({
                        ...form,
                        error: true,
                        errorMsg: 'El Correo electrónico ya se encuentra registrado.',
                        loading: false
                    });
                    return 0;
                }
            }
    
            if (form.password1 !== form.password2){
                setForm({
                    ...form,
                    error: true,
                    errorMsg: 'Las contraseñas no coinciden.',
                    loading: false
                });
                return 0;
            }
    
            const admin = {
                _id: oldAdmin._id,
                nombre: form.nombre,
                email: form.email,
                password: form.password1
            }
    
            const newProveedor = await httpPost('/api/v1/admin-user', admin);
            if(newProveedor.data){
                console.log('PROVEEDOR CREADO');
                sessionStorage.setItem('adminUser', JSON.stringify(newProveedor.data));
                setForm({
                    ...form,
                    error: false,
                    errorMsg: '',
                    loading: false,
                    success: true
                });

                return 0;
            }
    
            
            
            console.log('FORMULARIO VALIDADO');
            setForm({
                ...form,
                error: false,
                errorMsg: '',
                loading: false
            });
        } catch(error){
            console.log(error);
            setForm({
                ...form,
                error: true,
                errorMsg: 'Ocurrio un error al registrarse.'
            });
        };

    }

    return(
        <div className='page-container'>
            <Title>Perfil de usuario</Title>
            { form.loading ?
            <div className='loading-spinner'>
                    <Spinner animation="border" variant="primary" />
            </div>
            :
            <div className='page-body'>
                { form.error &&
                    <>
                        <br />
                        <Alert variant='danger'>{ form.errorMsg }</Alert>
                    </>
                }
                { form.success &&
                    <>
                        <br />
                        <Alert variant='success'>El perfil fue modificado correctamente.</Alert>
                    </>
                }
                <form className='signup-form' onSubmit={handleSubmit} >
                    <Field type='text' label='Nombre(*)' hint='Nombre completo del usuario' name='nombre' value={form.nombre} onChange={handleChange} required={ true } />
                    <Field type='email' label='Correo electrónico(*)' hint='Dijite su correo electrónico' name='email' value={form.email} onChange={handleChange} required={ true } />
                    <Field type='password' label='Contraseña(*)' hint='Dijite una contraseña' name='password1' value={form.password1} onChange={handleChange} />
                    <Field type='password' label='Repita la contraseña(*)' hint='Dijite de nuevo la contraseña' name='password2' value={form.password2} onChange={handleChange} />
                    <Button type='submit'>Aceptar</Button>
                </form>
            </div>
            }
        </div>
    );
}

const mapStateToProps = ({ AdminReducer }) => {
    return {
        AdminReducer
    };
};

export default connect(mapStateToProps)(Profile);