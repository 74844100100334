import React, { useState, useEffect} from 'react';
import Title from '../../components/title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import SubTitle from '../../components/subTitle/SubTitle';
import './DocumentoCruzadoDetalle.css';
import { httpGet } from '../../http';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { formatNumber, formatDateMin } from '../../misc/index';
 

const DocumentoCruzadoDetalle = (props) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        proveedor: {
            nit: '',
            razonSocial: '',
            tel: '',
            email: '',
            tercero: {
                razonSocial: ''
            }
        },
        loading: true,
        error: {}
    });

    const [facturaDetalle, setFacturaDetalle ] = useState(
        {
            encabezado: {
                rowid: '',
                idCo: '',
                tipoDoc: '',
                numeroDoc: '',
                fechaDoc: new Date(),
                valor: 0
            },
            movimiento: {
                detalles: [],
                totalDebito: 0,
                totalCredito: 0
            },
            loading: false,
            error: {},
            reg: 0
        }
    );

    useEffect(() => {

        console.log("RENDER!");
        console.log(JSON.parse(sessionStorage.getItem('estado')));

        let newEstado = JSON.parse(sessionStorage.getItem('estado'));
        newEstado.proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        console.log(newEstado);
        
        setEstado(newEstado);

        if(!props.history.location.state.factura){
            props.history.push('/portal');
            return 0;
        }
        
        getFacturaDetalle(props.history.location.state.factura);

    }, []);

    const { factura } = props.history.location.state;

    useEffect(() => {
        console.log("FACTURA DETALLE:");
        console.log(facturaDetalle);
    }, [facturaDetalle]);

    
    const getFacturaDetalle = async (factura) => {
        setFacturaDetalle({
            ...facturaDetalle,
            loading: true
        });
        
        const movimiento = await httpGet('/api/v1/facturas/detalle/' + factura.rowid);
        console.log("MOVIMIENTO:");
        console.log(movimiento);

        if((movimiento !== null) && (movimiento !== undefined)){
            setFacturaDetalle({
                ...facturaDetalle,
                encabezado: factura,
                movimiento: movimiento.data,
                loading: false
            });
        }
        
    }
    
    const generarFacturaDetallePdf = async () => {

        let data = {
            empresa: estado.empresa,
            proveedor: estado.proveedor,
            encabezado: facturaDetalle.encabezado,
            movimiento: facturaDetalle.movimiento
        }

        console.log(data);

        props.history.push({ pathname: '/portal/documento-cruzado-pdf', state: { factura: data } });
    }

    return(
        <div className='page-container'>
            
            <Title>Detalle de egreso</Title>
            <div className='page-body'>
                <SubTitle>Empresa: { estado.empresa.razonSocial }</SubTitle>
                <SubTitle>Proveedor:</SubTitle>
                <div>
                    <div><span className='info-title'>N.I.T: </span><span>{ estado.proveedor.nit }</span></div>
                    <div><span className='info-title'>Razón social: </span><span>{ estado.proveedor.tercero.razonSocial }</span></div>
                </div>
                <br />
                <br />
                <SubTitle>Detalle del documento cruzado</SubTitle>
                {facturaDetalle.loading ?
                <div className='loading-spinner'>
                    <Spinner animation="border" variant="primary" />
                </div>
                :
                <>
                {
                <table className='info-table'>
                    <thead>
                        <tr>
                            <th>CO</th>
                            <th>DOCUMENTO</th>
                            <th>FECHA DEL DOCUMENTO</th>
                            <th>FECHA DE CANCELACION</th>
                            <th>FECHA DE VENCIMIENTO</th>
                            <th>DETALLE</th>
                            <th>VALOR DEL DOCUMENTO</th>                                                        
                            <th>DESCARGAR</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{ factura.idCo }</td>
                            <td>{ factura.tipoDoc + '-' + factura.numeroDoc }</td>
                            <td>{ formatDateMin(new Date(factura.fechaDoc)) }</td>
                            <td>{ factura.fechaCancelacion.length > 0 ? formatDateMin(new Date(factura.fechaCancelacion)) : '' }</td>
                            <td>{ factura.fechaVcto.length > 0 ? formatDateMin(new Date(factura.fechaVcto)) : '' }</td>
                            <td>{ factura.notas }</td>
                            <td>${ formatNumber(factura.debito) }</td>
                            <td><div className='pdf-icon-container' onClick={generarFacturaDetallePdf}><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></div></td>
                        </tr>
                    </tbody>
                </table>
                }
                <br />
                <br />
                <SubTitle>Movimiento contable</SubTitle>
                <table className='info-table doc-table'>
                    <thead>
                        <tr>
                            <th>REG.</th>
                            <th>CODIGO CUENTA</th>
                            <th>DESCRIPCION CUENTA</th>
                            <th>CO</th>
                            <th>DEBITOS</th>
                            <th>CREDITOS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            facturaDetalle.movimiento.detalles.map(
                                (detalle, index) => {
                                    return(
                                        <tr key={index}>
                                            <td>{ (index + 1) }</td>
                                            <td>{ detalle.cuentaId }</td>
                                            <td>{ detalle.cuentaDescripcion }</td>
                                            <td>{ detalle.idCoMov }</td>
                                            <td>${ formatNumber(detalle.valorDb) }</td>
                                            <td>${ formatNumber(detalle.valorCr) }</td>
                                        </tr>
                                    );
                                }
                            )
                        }
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>SUMAS IGUALES</td>
                            <td>${ formatNumber(facturaDetalle.movimiento.totalDebito) }</td>
                            <td>${ formatNumber(facturaDetalle.movimiento.totalCredito) }</td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <br />
                </>
                }
            </div>
            
        </div>
    );
}

export default DocumentoCruzadoDetalle;
