import React, { useState, useEffect } from 'react';
import { httpGet } from '../../http';
import DocumentoDetalleView from './DocumentoDetalleView';

import { connect } from 'react-redux';
import AppReducer from '../../redux/reducers/AppReducer';

const DocumentoDetalle = ({ history, AppReducer }) => {

    useEffect(() => {
        if(!history.location.state.documento){
            history.push('/portal');
            return 0;
        }
        
        getDocumentoDetalle(history.location.state.documento);
    }, []);

    const getDocumentoDetalle = async (doc) => {
        if ( doc.docpro === 1 ) {
            setDocumento({
                ...documento,
                loading: true
            });
            
            const detalles = await httpGet('/api/v1/egresos/documento/detalle/' + doc.factRowid);
            const detalleSaldo = await httpGet('/api/v1/egresos/detalle-saldo/' + doc.factRowid);
            const retenciones = await httpGet('/api/v1/egresos/documento/retenciones/' + doc.factRowid);

            if((detalles.data !== null) && (detalles.data !== undefined)){
                setDocumento({
                    ...documento,
                    encabezado: doc,
                    detalles: detalles.data,
                    detalleSaldo: detalleSaldo.data ,
                    retenciones: retenciones.data ? retenciones.data : { detalles: [] },
                    loading: false
                });
            }
        } else {
            const encabezado = await httpGet('/api/v1/egresos/documento-fi/' + doc.docCruRowid);
            const detalles = await httpGet('/api/v1/egresos/documento-fi-detalle/' + doc.docCruRowid);
            const detalleSaldo = await httpGet('/api/v1/egresos/detalle-saldo/' + doc.docCruRowid);
            const retenciones = await httpGet('/api/v1/egresos/documento/retenciones/' + doc.docCruRowid);

            console.log(encabezado);
            
            if((detalles.data !== null) && (detalles.data !== undefined)){
                setDocumento({
                    ...documento,
                    encabezado: doc,
                    detalleFi: detalles.data,
                    detalleSaldo: detalleSaldo.data,
                    retenciones: retenciones.data,
                    loading: false
                });
            }
        }
    }

    const generarPdf = async (e, doc) => {
        e.preventDefault();

        
        const data = {
            empresa: AppReducer.company,
            proveedor: AppReducer.supplier,
            encabezado: history.location.state.documento,
            documento: documento
        }
        
        if (history.location.state.documento.docpro === 1) {
            history.push({ pathname: '/portal/documento-cm-detalle-pdf', state: {documento: documento} });
        } else {
            history.push({ pathname: '/portal/documento-fi-detalle-pdf', state: {documento: documento} });
        }
    }

    const [documento, setDocumento ] = useState(
        {
            empresa: AppReducer.company,
            proveedor: AppReducer.supplier,
            encabezado: history.location.state.documento,
            detalles: [],
            detalleSaldo: [],
            retenciones: {
                detalles: [],
                totalDebito: 0,
                totalCredito: 0,
                totalBase: 0
            },
            detalleFi: {
                detalles: [],
                totalDebito: 0,
                totalCredito: 0
            },
            loading: false,
            error: {},
            reg: 0
        }
    );

    return(
        <>
            <DocumentoDetalleView AppReducer={AppReducer} documento={documento} generarPdf={generarPdf} />
        </>
    );
}

const mapStateToProps = ({ AppReducer }) => {
    return {
        AppReducer
    };
};

export default connect(mapStateToProps)(DocumentoDetalle);
