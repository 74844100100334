
import { httpPost } from "../../http";

export const setAdminLoadingAction = () => (dispatch) => {
    dispatch({
        type: "SET_ADMIN_LOADING_ON"
    });
}

export const doAdminLoginAction = (data) => async (dispatch) => {
    
    
    let res = false;
    await dispatch({
        type: "SET_ADMIN_ERROR",
        payload: {
            state: false,
            msg: ''
        }
    });
    
    await dispatch({
        type: "SET_ADMIN_LOADING_ON"
    });

    try{
        const adminUser = await httpPost('/api/v1/admin-user/login', data);
        if(adminUser.data){
            await dispatch({
                type: "SET_ADMIN",
                payload: adminUser.data
            });
            res = true;
        }
    } catch (error) {
        console.log(error);        
        
        if(error.response.status){
            if(error.response.status === 403 ){
                await dispatch({
                    type: "SET_ADMIN_ERROR",
                    payload: {
                        state: true,
                        msg: 'Correo electrónico o contraseña incorrectos.'
                    }
                });
            }
        } else {
            await dispatch({
                type: "SET_ADMIN_ERROR",
                payload: {
                    state: true,
                    msg: 'Error.'
                }
            });
        }

    } finally {
        await dispatch({
            type: "SET_LOADING_OFF"
        });
    }

    return res;
}

export const cleanAdminDataAction = () => async (dispatch) => {

    await dispatch({
        type: "CLEAN_ADMIN"
    });
}